import { Helmet } from "react-helmet";
import Blog from "../../components/blogs";
const BlogPage = () => (
  <>
    <Helmet />
    <Blog />
  </>
);

export default BlogPage;
BlogPage.displayName = "Blog Page";
