import { Helmet } from "react-helmet";
import Privacy from "../../components/privacy";
const PrivacyPage = () => (
  <>
    <Helmet />
    <Privacy />
  </>
);

export default PrivacyPage;
PrivacyPage.displayName = "Privacy Policy";
