import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    app: {
      primary: {
        main: "#0689F2",
      },
      grey: {
        one: "#8E989B",
        two: "#E0DDDD",
        three: "#F5F5F5",
        four: "#999999",
      },
      black: {
        one: "#323232",
      },
      white: {
        one: "#FFFFFF",
      },
    },
  },
  breakpoints: {
    app: {
      keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
      values: {
        xs: 480,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
  },
  typography: {
    app: {
      inter: {
        black: ['"Inter, Black"'],
        "extra-bold": ['"Inter, Extra-Bold"'],
        bold: ['"Inter, Bold"'],
        "semi-bold": ['"Inter, Semi-Bold"'],
        medium: ['"Inter, Medium"'],
        regular: ['"Inter, Regular"'],
        light: ['"Inter, Light"'],
        "extra-light": ['"Inter, Extra-Light"'],
        thin: ['"Inter, Thin"'],
      },
      gelasio: {
        bold: ['"Gelasio, Bold"'],
        "semi-bold": ['"Gelasio, Semi-Bold"'],
        medium: ['"Gelasio, Medium"'],
        regular: ['"Gelasio, Regular"'],
      },
      montserrat: {
        black: ['"Montserrat, Black"'],
        "extra-bold": ['"Montserrat, Extra-Bold"'],
        bold: ['"Montserrat, Bold"'],
        "semi-bold": ['"Montserrat, Semi-Bold"'],
        medium: ['"Montserrat, Medium"'],
        regular: ['"Montserrat, Regular"'],
        light: ['"Montserrat, Light"'],
        "extra-light": ['"Montserrat, Extra-Light"'],
        thin: ['"Montserrat, Thin"'],
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            display: "flex",
            flexDirection: "row",
            fontFamily: theme.typography.app.inter.medium,
            textTransform: "none",
            padding: "14px 20px",
            fontSize: 16,
            lineHeight: "16px",
            ...(ownerState.variant === "contained" &&
              ownerState.color === "primary" && {
                backgroundColor: theme.palette.app.primary.main,
                ":hover": {
                  backgroundColor: theme.palette.app.primary.main,
                },
                ":disabled": {
                  backgroundColor: theme.palette.app.grey.four,
                  color: theme.palette.app.white.one,
                },
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                boxSizing: "border-box",
                padding: "11px 20px",
                borderColor: theme.palette.app.primary.main,
                color: theme.palette.app.primary.main,
              }),
          }),
        }),
      },
    },
    //  ATTENTION:  to remove hover border css for input box
    // currently in progress
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       ":hover .MuiOutlinedInput-notchedOutline": {
    //         borderColor: theme.palette.app.grey.two,
    //       },
    //     }),
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            "& label": {
              fontFamily: "Inter",
              fontSize: 14,
              color: theme.palette.app.grey.one,
              marginLeft: -8,
              marginTop: -4,
            },
            "& label.MuiInputLabel-shrink": {
              marginLeft: 0,
              marginTop: 1,
            },
            "& label.Mui-focused": {
              marginLeft: 1,
              marginTop: 1,
            },
            "& .MuiOutlinedInput-root": {
              lineHeight: 1.286,
              "& input": {
                fontFamily: "Inter",
                fontSize: 14,
                height: "inherit",
                padding: "13px 8px",
                // zIndex: 1,
              },
              "& fieldset": {
                // backgroundColor: theme.palette.app.white.one,
                borderColor: theme.palette.app.grey.two,
              },
              "&.Mui-focused:not(.Mui-error) fieldset": {
                borderColor: theme.palette.app.primary.main,
              },
            },
            "& .Mui-disabled": {
              backgroundColor: theme.palette.app.grey.three,
              color: theme.palette.app.black.one,
            },
            "& label.Mui-disabled": {
              backgroundColor: theme.palette.app.white.one,
            },
          }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            fontFamily: "Inter",
            fontSize: 12,
          }),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            "& .MuiFormControlLabel-label": {
              fontFamily: "Inter",
              fontSize: 14,
              color: ownerState.checked ? "#0689F2" : "#000000",
            },
            "& .Mui-checked": {
              color: "#0689F2",
            },
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            minHeight: "30px",
            borderBottom: "1px solid #AEAEAE",
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.app.primary.main,
              height: 1,
            },
            [theme.breakpoints.up(theme.breakpoints.app.values.md)]: {
              minHeight: "35px",
            },
          }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            padding: 0,
            minWidth: "fit-content",
            color: "#000000",
            marginRight: 20,
            "&.Mui-selected": {
              color: "#0689F2",
            },
            fontFamily: theme.typography.app.inter["semi-bold"],
            fontSize: 14,
            lineHeight: "16.94px",
            minHeight: "fit-content",
            textTransform: "none",
            [theme.breakpoints.up(theme.breakpoints.app.values.md)]: {
              paddingBottom: 14,
              fontSize: 16,
              lineHeight: "19.36px",
            },
          }),
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.app && {
            "& .MuiStepLabel-label": {
              fontFamily: theme.typography.app.inter.regular,
              fontSize: 14,
            },
          }),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiSelect-select": {
            padding: "10.5px 8px",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ".MuiPopover-paper ": {
            boxShadow: "#00000012 0px 5px 15px",
          },
        },
      },
    },
  },
});

export default Theme;
