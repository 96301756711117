import React from "react";
import MarketGPTIcon from "../../assets/image/icons/marketgpt.svg";
import MarketGPTIllustration from "../../assets/image/illustrations/marketgptimg.svg";
// import PortGPTIcon from '../../assets/image/icons/portfoliogpt.svg'
// import PortGPTIllustration from '../../assets/image/illustrations/portfoliogptimg.svg'

export default function MarketGPT() {
  return (
    <div className="flex items-center md:items-start flex-col w-full md:flex-row md:px-20 bg-primarybg-300">
      <div className="flex flex-col items-start w-full">
        <img src={MarketGPTIcon} alt="err" className="mt-8" />
        <p className="font-montserrat font-medium md:text-lg md:leading-[30px] text-[#B1B5C4] pt-10 z-30 whitespace-break-spaces">
          Instantly address wealth client queries on market and economic trends
          with insights, providing valuable perspectives for informed
          decision-making. 
        </p>
      </div>
      <div className="relative w-full h-full bg-[url('../image/illustrations/mobile_mesh.svg')] bg-cover bg-center">
        <img
          src={MarketGPTIllustration}
          className="object-cover m-auto"
          alt="err"
        />
      </div>
    </div>
  );
}
