import React from "react";
import Header from "../header/Header";
import Footer from "../footer";

const informationMap = [
  "information supplied by Users",
  "information automatically tracked while navigation; and,",
  "information collected from any other source (collectively referred to as 'Information').",
];

const informationByUsers = [
  "Registering or setting up an account on the BeyondIRR Platform and/or the Sites",
  "While filling up forms",
  "Browsing or accessing the BeyondIRR Platform and/or the Sites",
  "Name",
  "PAN",
  "Email id",
  "Mobile Number",
  "Marital Status",
  "Bank Account Number",
  "Address",
  "Medical Records and History",
  "Nominee details",
  "Risk profile related aspects",
  "Date of Birth",
  "Signature",
  "Income Details",
  "Documents like Address proof or Bank Statements) and other related information. The Company shall also collect non-personal information pertaining to the behaviour of the User (behavioural information), while using or browsing through the BeyondIRR Platform and/or the Sites, messages posted by the User on the BeyondIRR Platform and/or the Sites, etc. When You register using Your other accounts like on Facebook, Twitter, Google, etc. we shall retrieve information from such accounts to continue to interact with You and to continue to provide the Services. Any personally identifiable information provided by You will not be considered as sensitive if it is freely available and/or accessible in the public domain like any comments, messages, blogs, scribbles available on social platforms like Facebook, Twitter, etc. Further, anything posted, uploaded, conveyed, or communicated by users on the public sections of the Sites becomes published content and is not considered personally identifiable information subject to this Privacy Policy. The Company, and its partners, vendors, service providers, etc. may also contact You from time to time through any mode of communication about updating Your personal information in order to provide the Users with such features that We believe may benefit or interest You. The Information is stored by the company on servers owned by its or its group companies or by third parties in India.",
];

const informationUseByCompany = [
  "Provide a smooth, safe and customized experience to the User on the BeyondIRR Platform and/or the Sites",
  "Provide seamless investment transaction experience with respect to third party products and services offered on the BeyondIRR Platform and/or the Sites",
  "Provide and promote its products and services on the BeyondIRR Platform and/or the Sites, general as well as customized, and improve the same from time to time",
  "Provide You the most user-friendly experience and for developing new services",
  "Analyse flow, track user trends, measure promotional effectiveness",
  "Provide additional features on the BeyondIRR Platform and/or the Sites through Cookies",
  "Protect the safety and integrity of the BeyondIRR Platform and/or the Sites, information and maintain the confidentiality of the User and their data",
  "Ensure smooth execution of transaction and payments",
  "Maintain, improve and provide Service (including personalization of the BeyondIRR Platform and/or the Sites)",
  "Inform the User about new or change in products, services, offers, updates, reminders, billing information, customer service(s), etc.",
  "To detect and/or prevent any fraudulent, criminal, and/or prohibited activity on the BeyondIRR Platform and/or the Sites",
  "To process Your request or respond to Your queries, grievances, and comments",
  "Send customized messages and notifications to You",
  "Communicate any change in Our products, services, Terms and Conditions, Privacy Policy",
  "Marketing and commercial communications regarding products and services",
  "Analyse Your behaviour on the BeyondIRR Platform and/or the Sites",
  "Facilitate Company's marketing, advertising and educational activities and initiatives",
  "Audit and investigate",
  "Update regarding third party services and products and services offered on other Sites",
  "Data analysis or analytics",
  "Pre-fill various forms",
  "Maintain User accounts",
  "Provide general information or updates",
  "For internal training and quality assurance purposes",
];

const informationSharing = [
  "To provide a seamless experience",
  "To provide various products and services to the User of the Company as well as of the third parties",
  "To provide customize offers, products and services based on the User needs or behavioural pattern",
  "To ensure smooth execution of transactions and payments",
  "To protect the rights and properties of the Company",
  "To initiate and investigate any complaints, claims or disputes",
  "To assist our auditors or advisors for performing their services",
  "To fulfil legal obligations or compliance requirements",
];

const dataFromGogl = [
  "The Company will be accessing your data, from Google, for tracking of User's financial transactions to provide the requisite service offered from time to time",
  "The Company shall only read relevant emails of the User sent by financial service providers by identifying the Sender of emails such as @camsonline.com, @kfintech.com, @franklintempleton.com",
  "The Company does not access any other email of the User",
  "The Company does not open, read, or access any personal mail including User's personal information",
];

const extraInfo = [
  "To provide seamless, smooth, safe, and customized experience to the User",
  "When it is requested or required by law",
  "To initiate any investigate any complaints, claims or disputes",
  "To customize products or introduce new products",
  "To improve the user facing features",
];

const rights = [
  "Right to Review - You can review the data provided by you and can request us to correct or amend such data (to the extent feasible, as determined by us). That said, we will not be responsible for the authenticity of the data or information provided by you.",
  "Withdrawal of your Consent - You can choose not to provide your data, at any time while availing our products or services or otherwise withdraw your consent provided to us earlier, in writing to our email ID: connect@beyondirr.tech.",
];

export default function Privacy() {
  return (
    <div>
      <Header />
      <div className="bg-primarybg-300 px-5 py-20 md:px-20 md:py-24">
        <h2 className="font-montserrat text-[32px] leading-[48px] font-medium text-[#FCFCFD]">
          Privacy Policy
        </h2>
        <p style={{ color: "gray", fontWeight: "lighter" }}>
          Last updated on 09-04-2024 10:10:31
        </p>
        <div className="font-montserrat font-light text-lg leading-[27px] text-[#B1B5C4] mt-8">
          <p className="mt-4">
            This privacy policy (“Policy”) explains Our policy regarding the
            collection, use, disclosure and transfer of Your information
            (provided by You on Our website(s) https://www.beyondirr.tech/,
            https://www.beyondirr.com/, https://investedge.tech/,
            https://investlane.tech/, or through another URL of the Company
            and/or mobile applications like ‘BeyondIRR’ , ‘InvestEdge’ ,
            ‘InvestLane’, and ‘BeyondIRR Wealth Partner’ or through another
            mobile application of the Company (collectively referred as the
            BeyondIRR Platform)) to “Valiant Fintech Private Limited”. and/or
            its parent entity, subsidiary(ies) and/or affiliate(s) (collectively
            referred to as the "the Company"), which operates various websites,
            including sub-sites, platforms, applications, m-web platforms and
            other platforms, including the BeyondIRR Platform, (collectively
            referred to as "Sites") for delivery of information, products,
            offerings and content via any mobile or internet connected device or
            otherwise (collectively the "Services"). The Company is the licensed
            owner of the BeyondIRR Platform and/or the Sites and develops,
            manages, and operates the BeyondIRR Platform and other Sites and the
            Company has permitted its group entities to use the BeyondIRR
            Platform and/or the Sites for their respective products and
            services. This Policy forms part and parcel of the Terms of Use and
            other terms on the BeyondIRR Platform and/or the Sites ("Terms of
            Use"). This Policy should always be read along with Terms of Use and
            specific products and services related Terms & Conditions and
            Agreements (hereinafter referred to as “Terms and Conditions”).
            Capitalized terms which have been used here but are undefined shall
            have the same meaning as attributed to them in the Terms of Use and
            Terms and Conditions.
          </p>
          <p className="mt-4">
            This Privacy Policy is also applicable to Company's subsidiaries,
            affiliates and group companies which provide their products and
            services on the BeyondIRR Platform and/or the Sites.
          </p>
          <p className="mt-4">
            This Privacy Policy is applicable to a User who avails the Services.
            The term "User" for the purposes of this Privacy Policy shall mean
            You, in the capacity of an individual, a guest user, browser and/or
            representative of an entity, who visits, accesses, uses, downloads,
            deals with, avails Services and/or transacts through the Sites. For
            the purpose of this Policy, wherever the context so requires "You"
            or "Your" shall mean the User and the term "We", "Us", "Our" shall
            mean the Company.
          </p>
          <p className="mt-4">
            The Sites and related Services are primarily available for use and
            download within India only. We strictly adhere to the requirements
            of the data protection law established in India and this Privacy
            Policy is published in accordance with:
          </p>
          <ul
            style={{
              listStyleType: "number",
              listStylePosition: "outside",
              paddingLeft: "30px",
            }}
          >
            <li>
              Section 43A of the Information Technology Act, 2000 (“IT Act”);
            </li>
            <li>
              Rule 4 of the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Data or
              Information) Rules, 2011 (the “SDPI Rules”); and
            </li>
            <li>
              Rule 3 of the Information Technology (Intermediary Guidelines)
              Rules, 2011 (as may be amended)
            </li>
          </ul>
          <p className="mt-4">
            In case you do not wish to provide any information, including
            personal or personally identifiable information, or in the event You
            do not agree to any or all the clauses of the Terms of Use, Privacy
            Policy and Terms and Conditions, then the Company may not be in a
            position to offer You all or some of the products and services,
            including without limitation, certain features of the BeyondIRR
            Platform and/or the Sites and may even restrict (partially or fully)
            the User from accessing the BeyondIRR Platform and/or the Sites.
            Further, if You do not agree to this Privacy Policy, Terms of Use
            and/ or product specific Terms and Conditions, You may also not
            register on BeyondIRR Platform and/or the Sites, not avail Our
            Services and/or deregister from the BeyondIRR Platform and/or the
            Sites, and stop using Our products and services. We shall not be
            held responsible and/or liable for any denial of Service due to
            Users not providing the required information.
          </p>
          <p className="mt-4">
            You must be a natural person who is at least 18 (eighteen) years of
            age. You must not misrepresent Your identity. The right to use the
            BeyondIRR Platform and/or the Sites is personal to the User and is
            not transferable to any other person or entity. The Company and/or
            its Group Companies presume adequate and lawful parental consent in
            case the Personal Information is shared by a User under the age of
            18 years.
          </p>
          <p className="mt-4">
            As We update, improve, and expand the Services, this Policy may
            change, so please refer back to it periodically.
          </p>
          <p className="mt-4">
            By using BeyondIRR Platform and/or the Sites and related services by
            Us, You agree to accept all the terms contained in this Policy,
            acknowledge and agree with the practices described herein and
            consent to our use, storage, disclosure, and transfer of your
            information or data in the manner described in this Policy.
          </p>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              The Company respects the privacy of the Users of the Services and
              is committed to protect it in all respects. <br />
              The information about the User as collected by the Company is:
            </strong>
          </h3>
          <ol>
            <ol
              type="1"
              style={{ listStyle: "string", listStylePosition: "inside" }}
            >
              {informationMap.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ol>
          </ol>

          <h6 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              1. Information received, collected, and stored by the Company
            </strong>
          </h6>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              While registering on or accessing the BeyondIRR Platform and/or
              the Sites or while using Our Services, You may be required to
              provide, some information about yourself, on a voluntary basis. We
              may also ask You to provide certain additional information on from
              time to time or retrieve information about You from third parties.
              All information disclosed by You shall be disclosed willingly on a
              voluntary basis and without any coercion. Also, in some instances
              You might have to provide certain information on a mandatory basis
              (such as KYC information).
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                A. Information supplied by users
              </strong>
            </h6>
            <p className="mt-4">
              The Company shall collect Personal Information about the User,
              including, without limitation, at the time of:
            </p>
            <ol
              type="i"
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {informationByUsers.slice(0, 2).map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ol>
            <p className="mt-4" style={{ color: "#FFF" }}>
              While availing any products or services Users may be required to
              provide certain personal information for the registration process
              that may include but not limited to:
            </p>
            <ol
              type="i"
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {informationByUsers.slice(3).map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ol>
            <p className="mt-4">
              The Company shall also collect non-personal information pertaining
              to the behaviour of the User (behavioural information), while
              using or browsing through the BeyondIRR Platform, messages posted
              by the User on the BeyondIRR Platform, etc.
            </p>
            <p className="mt-4">
              When You register using Your other accounts like on Facebook,
              Twitter, Google, etc. we shall retrieve information from such
              accounts to continue to interact with You and to continue to
              provide the Services.
            </p>
            <p className="mt-4">
              Any personally identifiable information provided by You will not
              be considered as sensitive if it is freely available and/or
              accessible in the public domain like any comments, messages,
              blogs, scribbles available on social platforms like Facebook,
              Twitter, etc. Further, anything posted, uploaded, conveyed, or
              communicated by users on the public sections of the Sites becomes
              published content and is not considered personally identifiable
              information subject to this Privacy Policy. The Company, and its
              partners, vendors, service providers, etc. may also contact You
              from time to time through any mode of communication about updating
              Your personal information in order to provide the Users with such
              features that We believe may benefit or interest You.
            </p>
            <p className="mt-4">
              The Personal Information, any non-personal information and
              behavioural information (collectively called as Information) is
              stored by the company on servers owned by its or its group
              companies or by third parties in India.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Voluntary Information
              </strong>
            </h6>
            <p className="mt-4">
              We may collect additional information at other times, including
              but not limited to, when You provide feedback, comments, change
              Your content or email preferences, respond to a survey, or any
              communications with Us. However, in the event You wish to provide
              Us with Your Aadhaar information, You hereby agree and acknowledge
              that You are providing the same to Us purely on a voluntary basis
              only.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                B. Information automatically collected or tracked during
                navigation Cookies
              </strong>
            </h6>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">Cookies</strong>
            </h6>
            <p className="mt-4">
              To improve the responsiveness of the Sites for Our Users, We may
              use "Cookies", or similar electronic tools to collect information
              to assign each visitor a unique, random number as a User
              Identification (User ID) to understand the User's individual
              interests using the identified computer or device. Unless You
              voluntarily identify Yourself (through registration, for example),
              We will have no way of knowing who You are, even if We assign a
              cookie to Your computer or device. The only personal information a
              cookie can contain is information You supply. A cookie cannot read
              data off Your hard drive or device. Our advertisers may also
              assign their own cookies to Your browser (if You click on their
              ads, etc.), a process that We do not control. We receive and store
              certain types of Information whenever You interact with Us via Our
              Site or Service though Your computer, laptop, netbook, mobile
              phone, tablet, or any other handheld device, etc.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Log File Information
              </strong>
            </h6>
            <p className="mt-4">
              We automatically collect limited information about Your computer's
              connection to the Internet, mobile number, including Your IP
              address, when You visit Our site, application, or service. Your IP
              address is a number that lets computers attached to the Internet
              know where to send Your data, such as the pages You view. We
              automatically receive and log information from Your browser,
              including but not limited to Your IP address, Your computer's
              name, Your operating system, browser type and version, CPU speed,
              and connection speed. We may also collect log information from
              Your device, including Your location, IP address, Your device's
              name, device's serial number or unique identification number (e.g.
              UDID on Your iOS device).
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">Clear GIFs</strong>
            </h6>
            <p className="mt-4">
              We may use "clear GIFs" (Web Beacons) to track the online usage
              patterns of Our Users in an anonymous manner, without personally
              identifying the User. We may also use clear GIFs in HTML-based
              emails sent to Our Users to track which emails are opened by
              recipients. We use this information to inter-alia deliver Our web
              pages to You upon request, to tailor Our Sites or Service to the
              interests of Our users, to measure traffic within Our Sites to
              improve the quality, functionality and interactivity of Our Sites
              and let advertisers know the geographic locations from where Our
              Users come without personally identifying the Users.
            </p>
          </div>

          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              2. Information from other Sources:
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              We may receive information about You from other sources,
              including, without limitation, such as KRA, CKYCR, Credit Bureaus,
              Account Aggregator, MF Central, e-CAS, CAS, and add it to Our
              account information and treat it in accordance with this Policy.
              If You provide information to any of Our vendors, service
              providers, partners (such as payment gateway providers), agencies
              etc., who provide services to Us, Your account information and
              order information may be passed on to Us.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Demographic and other information:
              </strong>
            </h6>
            <p className="mt-4">
              We may reference other sources of demographic and other
              information to provide You with more targeted communications and
              promotions. We use Google Analytics, among others, to track the
              user behaviour on Our Sites. Google Analytics specifically has
              been enabled to support display advertising towards helping Us
              gain understanding of Our users' demographics and interests. The
              reports are anonymous and cannot be associated with any individual
              personally identifiable information that You may have shared with
              Us. You can opt-out of Google Analytics for display advertising
              and customize Google Display Network ads using the Ads Settings
              options provided by Google.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Links to third party sites or ad-servers
              </strong>
            </h6>
            <p className="mt-4">
              The Sites may include links to other websites or applications.
              Such websites or applications are governed by their respective
              privacy policies, which are beyond Our control. Once You leave Our
              servers (You can tell where You are by checking the URL in the
              location bar on Your browser), use of any information You provide
              is governed by the privacy policy of the operator of the
              application, you are visiting. That privacy policy may differ from
              Ours. If You can't find the privacy policy of any of these sites
              via a link from the application's homepage, You should contact the
              application owners directly for more information. We do not
              provide any personally identifiable Information to third party
              websites, applications, advertisers, or ad-servers without Your
              consent except in the circumstances mentioned in this Policy or
              Terms of Use or in Terms and Conditions.
            </p>
            <p className="mt-4">
              When We present Information to Our advertisers – to help them
              understand Our audience and confirm the value of advertising on
              Our Sites – it is usually in the form of aggregated statistics on
              traffic to various pages or content within Our Sites. We use
              third-party advertising companies to serve ads when You visit Our
              Sites. These companies may use Information (excluding Your name,
              address, email address or telephone number or other personally
              identifiable information) about Your visits to this and other
              websites or application, in order to provide advertisements about
              goods and services of interest to You.
            </p>
            <p className="mt-4">
              We do not provide any personally identifiable information to third
              party websites, advertisers, or ad-servers without Your consent.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Information use by the company
              </strong>
            </h6>
            <p className="mt-4">
              The Personal Information provided by the User is used by the
              Company for the purposes, including, without limitation:
            </p>
            <ul
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {informationUseByCompany.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ul>
            <p className="mt-4">
              We may also use Your email address or other personally
              identifiable information to send commercial or marketing messages
              about Our Services and/or such additional updates and features
              about third parties' products and services with an option to
              subscribe or unsubscribe. We may, however, use Your email address
              for non-marketing or administrative purposes (such as notifying
              You of major changes, for customer service purposes, billing,
              etc.).
            </p>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              3. Information Sharing
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              The Company may share Your Information with authorised third
              parties without obtaining the prior consent of the User in the
              following circumstances but not limited to:
            </p>
            <ul
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {informationSharing.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ul>
            <p style={{ color: "#FFF" }}>
              <br />
              These disclosures are made in good faith and belief that such
              disclosure is reasonably necessary for enforcing Terms or for
              complying with the applicable laws and regulations.
            </p>

            <ol
              type="a"
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              <br />
              <li>
                The Company may present Information with third parties – to help
                them understand Our audience and confirm the value of
                advertising on BeyondIRR Platform and/or the Sites – however it
                is usually in the form of aggregated statistics on traffic to
                various pages within Our site. We use third-party advertising
                companies to serve ads when You visit BeyondIRR Platform and/or
                the Sites. These companies may use Information (excluding Your
                name, address, email address or telephone number or other
                personally identifiable information) about Your visits to this
                and other websites or application, in order to provide
                advertisements about goods and services of interest to You.
              </li>
              <li>
                We also share User Personal Information with Our vendors and
                service providers – to help Us understand about Our User
                Behaviour and/or to serve ads when You visit BeyondIRR Platform
                and/or the Sites. These companies may use Information (excluding
                Your name, address, email address or telephone number or other
                personally identifiable information) about Your visits to this
                and other websites or application.
              </li>
              <li>
                The Company may share Your Information regarding Your activities
                on Sites with third party social websites to populate Your
                social wall that is visible to other people however You will
                have an option to set Your privacy settings, where You can
                decide what You would like to share or not to share with others.
              </li>
              <li>
                We may share Your Information to enforce or protect Our rights
                or any or all of its affiliates, associates, employees,
                directors or officers or when We have reason to believe that
                disclosing Information of User(s) is necessary to identify,
                contact or bring legal action against someone who may be causing
                interference with Our rights or Our Sites, whether intentionally
                or otherwise, or when anyone else could be harmed by such
                activities
              </li>
              <li>
                We may share information with consultants, third parties,
                business partners and vendors for providing Our and/or their
                various products and services. The handling of Your Information
                by such consultants, third parties, business partners and
                vendors shall be as per the privacy policies of those
                consultants, third parties, business partners and vendors. The
                Company shall not be responsible or liable for any action or
                omission of these consultants, third parties, business partners
                and vendors with respect to Your Information.
              </li>
            </ol>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              4. Data accessed from Google
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              Whenever we access your personal information or data automatically
              from Google, with your consent, the policy governing the access
              and use of the data will be as follows:
            </p>
            <ul
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {dataFromGogl.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ul>
            <p className="mt-4">
              The Company may share your Data (accessed from Google) with our
              affiliates, service providers, or regulators:
            </p>
            <ul
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {extraInfo.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ul>
            <p className="mt-4">
              For the sake of clarity, the Company does not sell, transfer, or
              lease such information for serving ads.
            </p>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              5. Accessing and updating personal information
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              When You use BeyondIRR Platform and/or the Sites, We make good
              faith efforts to provide You, as and when requested by You, with
              access to Your personal information and shall further ensure that
              any personal information or sensitive personal data or information
              found to be inaccurate or deficient shall be corrected or amended
              as feasible, subject to any requirement for such personal
              information or sensitive personal data or information to be
              retained by law or for legitimate business purposes. We ask
              individual users to identify themselves and the information
              requested to be accessed, corrected or removed before processing
              such requests, and We may decline to process requests that are
              unreasonably repetitive or systematic, require disproportionate
              technical effort, jeopardize the privacy of others, or would be
              extremely impractical (for instance, requests concerning
              information residing on backup tapes), or for which access is not
              otherwise required. In any case, where We provide information
              access and correction, We perform this service free of charge,
              except if doing so would require a disproportionate effort.
              Because of the way We maintain certain services, after You delete
              Your information, residual copies may take a period of time before
              they are deleted from Our active servers and may remain in Our
              backup systems. We encourage You to review, update and correct the
              personal information that We maintain about You, and You may
              request that We delete personal information about You that is
              inaccurate, incomplete or irrelevant for legitimate purposes, or
              are being processed in a way which infringes any applicable legal
              requirement. Your right to review, update, correct etc. Your
              information is subject to Our records retention policies and
              applicable law, including any statutory retention requirements.
            </p>
            <h6 className="mt-4">
              <strong className="font-semibold text-[#FFF]">
                Retention of Data
              </strong>
            </h6>
            <p className="mt-4">
              We may retain your information or data
              <ul>
                <li>
                  <br />
                  1. for as long as we are providing products and services to
                  you; and
                </li>
                <li>
                  <br />
                  2. as permitted under applicable law, we may also retain your
                  data or information even after you terminate the business
                  relationship with us.
                </li>
              </ul>
              However, we will process such information or data in accordance
              with applicable laws and this Policy.
            </p>
            <p className="mt-4">
              We shall store the data, information, and records on a server
              owned by Us or Our group companies in India. We may keep Our
              records of the electronic instructions or transactions in any form
              as permitted under applicable Laws. In this regard, all records,
              whether in electronic form, magnetic form, documents or any other
              form with respect to electronic instructions or online
              transactions shall be conclusive evidence of such instructions or
              transactions and shall be binding on the User.
            </p>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              6. Your rights relating to Your data / Information
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <ul
              style={{
                listStyleType: "number",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              {rights.map((definition, index) => (
                <li key={index} className="mt-4">
                  {definition}
                </li>
              ))}
            </ul>

            <p className="mt-4">
              In the event you choose to not provide or later withdraw your
              consent, we may not be able to provide you our products or
              services. Please note that these rights are subject to our
              compliance with applicable laws.
            </p>
          </div>

          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              7. Information Security
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              We take appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. These include internal reviews of Our data
              collection, storage and processing practices and security
              measures, including appropriate encryption and physical security
              measures to guard against unauthorized access to systems where We
              store personal data. All information gathered is securely stored
              within the Company controlled database. The database is stored on
              servers secured behind a firewall; access to the servers is
              password-protected and is strictly limited. However, as effective
              as Our security measures are, no security system is impenetrable.
              We cannot guarantee the security of Our database, nor can We
              guarantee that information You supply will not be intercepted
              while being transmitted to Us over the Internet. And, of course,
              any information You include in a posting to the discussion areas
              is available to anyone with Internet access.
            </p>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              8. Update and Changes
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              The internet is an ever-evolving medium. We may alter Our Policy
              from time to time to incorporate necessary changes in technology,
              applicable law or any other variant. In any case, We reserve the
              right to change (at any point of time) the terms of this Policy or
              the Terms of Use. Any changes We make will be effective
              immediately on notice, which We may give by posting the new policy
              on the Sites. Your use of the BeyondIRR Platform and/or the Sites
              after such notice will be deemed acceptance of such changes. We
              may also make reasonable efforts to inform You via electronic
              mail. In any case, You are advised to review this Policy
              periodically on the BeyondIRR Platform and/or the Sites to ensure
              that You are aware of the latest version.
            </p>
          </div>
          <h3 className="mt-4">
            <strong className="font-semibold text-[#FFF]">
              9. Questions and Grievance Redressal
            </strong>
          </h3>
          <div style={{ paddingLeft: "20px" }}>
            <p className="mt-4">
              Redressal Mechanism: Any complaints, abuse or concerns with
              regards to the use, processing and disclosure of Information
              provided by You or breach of these terms should immediately be
              informed to the designated Concerned Officers, the list of
              respective grievance officers is being provided in Terms of Use
              via in writing or through email to:
            </p>
            <ul
              style={{
                listStyleType: "square",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              <li>Mr. Deepesh Bhargava (Grievance Officer)</li>
              <li>
                Website:{" "}
                <a href="https://www.beyondirr.tech/">
                  https://www.beyondirr.tech/
                </a>
              </li>
              <li>
                Email:{" "}
                <a href="mailto:connect@beyondirr.tech">
                  connect@beyondirr.tech
                </a>
              </li>
            </ul>
            <p className="mt-4">
              We request the User to provide the following information in their
              complaint:
            </p>
            <ul
              style={{
                listStyleType: "square",
                listStylePosition: "outside",
                paddingLeft: "30px",
              }}
            >
              <li>
                Clear statement as to whether the information is Personal
                Information or Sensitive Personal Information
              </li>
              <li>User address, telephone number or e-mail address</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
