import { Helmet } from "react-helmet";
import Terms from "../../components/terms";
const TermsofPolicy = () => (
  <>
    <Helmet />
    <Terms />
  </>
);

export default TermsofPolicy;
TermsofPolicy.displayName = "Terms of Policy";
