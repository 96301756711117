import React from "react";
import NavigatorImg from "../../assets/image/illustrations/Navigator.svg";
import VisibilitySensor from "react-visibility-sensor";
import Navigation from "../../assets/image/icons/Navigation.svg";
import ActiveNavigation from "../../assets/image/icons/active_navigation.svg";

// import ActiveStoryteller from "../../assets/image/icons/active_storyteller.svg";
export default function HotLeadGeneration({ setBg, bg = [] }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8"
              style={{
                // background: bg[3],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img
                src={bg[3]?.length ? ActiveNavigation : Navigation}
                alt="err"
              />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[3]?.length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Hot Lead Generation
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Do you know that financial advisors globally are able to convert
              less than 3% of their cold leads from a database into hot leads. 
              Convert your cold leads and database into hot leads – provide your
              prospects a platform using which they can track and review their
              overall mutual fund portfolio. 
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <div
          className="w-[200px] flex pb-[16px] ml-[16px]"
          style={{
            // background: bg[3],
            transitionProperty: "width,background",
            transitionDuration: "0.2s,0.2s",
            transitionTimingFunction: "linear",
          }}
        >
          <img src={bg[3]?.length ? ActiveNavigation : Navigation} alt="err" />
          <p
            className="font-montserrat mb-0 ml-2"
            style={
              bg[3]?.length
                ? { color: "#FCFCFD", fontWeight: 600 }
                : { color: "7777E91" }
            }
          ></p>
        </div>

        <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
          Hot Lead Generation
        </h2>
        <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
          Do you know that financial advisors globally are able to convert less
          than 3% of their cold leads from a database into hot leads.  Convert
          your cold leads and database into hot leads – provide your prospects a
          platform using which they can track and review their overall mutual
          fund portfolio. 
        </p>
      </div>
      <div className="bg-[url('../image/illustrations/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <img src={NavigatorImg} className="mt-20" alt="err" />
      </div>
    </div>
  );
}
