import React from 'react';

export default function Investmenticon({ color }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.7999 3.6001C3.47442 3.6001 2.3999 4.67461 2.3999 6.0001C2.3999 7.32558 3.47442 8.4001 4.7999 8.4001H19.1999C20.5254 8.4001 21.5999 7.32558 21.5999 6.0001C21.5999 4.67461 20.5254 3.6001 19.1999 3.6001H4.7999Z" fill={color ? "url(#paint0_linear_205_9163)" : "#6A6A76"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5999 9.6001H20.3999V18.0001C20.3999 19.3256 19.3254 20.4001 17.9999 20.4001H5.9999C4.67442 20.4001 3.5999 19.3256 3.5999 18.0001V9.6001ZM9.5999 13.2001C9.5999 12.5374 10.1372 12.0001 10.7999 12.0001H13.1999C13.8626 12.0001 14.3999 12.5374 14.3999 13.2001C14.3999 13.8628 13.8626 14.4001 13.1999 14.4001H10.7999C10.1372 14.4001 9.5999 13.8628 9.5999 13.2001Z" fill={color ? "url(#paint1_linear_205_9163)" : "#6A6A76"} />
            <defs>
                <linearGradient id="paint0_linear_205_9163" x1="21.5999" y1="3.6001" x2="4.28944" y2="24.2436" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#09C3E7" />
                    <stop offset="1" stopColor="#7669F0" />
                </linearGradient>
                <linearGradient id="paint1_linear_205_9163" x1="21.5999" y1="3.6001" x2="4.28944" y2="24.2436" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#09C3E7" />
                    <stop offset="1" stopColor="#7669F0" />
                </linearGradient>
            </defs>
        </svg>

    );
}