import React from "react";
import Balance from "../../assets/image/logo/Balance.png";
import Breifcase from "../../assets/image/logo/briefcase.png";

export default function Features({ setBackground }) {
  const featureData = [
    {
      image: Balance,
      heading: "Client Portfolio Rebalancing",
      content:
        "Rebalance your client portfolio back to their target allocation or as per your strategic / tactical asset allocation framework – personalized to client specific needs. This ensure that client portfolios are always in line with client risk profile – leading to happy and satisfied client – further enhancing your NPS score.",
    },
    {
      image: Breifcase,
      heading: "Client Portfolio Review",
      content:
        "Regular portfolio review to track client objectives in terms of returns, risk as well as goals lead to clients and wealth managers being in control of portfolios and the change required to take corrective action. Retain your client base by being on top of the mind recall for delivering exceptional wealth services – and get ahead of your wealth competition.",
    },
  ];

  return (
    <div className="bg-primarybg-300 pt-[300px] md:pt-16">
      <h2 className="text-center font-montserrat font-semibold text-[20px] leading-[30px] md:text-[32px] md:leading-[48px] text-[#FCFCFD]">
        Features
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center items-center">
        {featureData.map(({ image, heading, content }, index) => (
          <div
            key={index}
            className="bg-primarybg-300 w-full pt-20 pb-12 flex justify-center z-70"
          >
            <div className="bg-primarybg-100 z-100 px-6 md:px-5 py-6 md:py-10 w-full md:w-3/4 rounded-2xl border border-[#353945] mx-5 md:mx-0">
              <img src={image} alt="err" />
              <div className="text-white mt-4">
                <h3 className="text-lg font-montserrat font-semibold mb-4">
                  {heading}
                </h3>
                <p className="font-montserrat text-sm text-[#B1B5C3]">
                  {content}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
