import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === "#featuredon") {
      const element = document.getElementById("featuredon");
      window.scrollTo({
        top: element.offsetTop - 320,
        behavior: "smooth",
      });
    } else if (hash === "#insights") {
      const element = document.getElementById("insights");
      window.scrollTo({
        top: element.offsetTop - 120,
        behavior: "smooth",
      });
    } else if (hash === "#investgpt") {
      const element = document.getElementById("investgpt");
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}
