import { Helmet } from "react-helmet";
import Investedge from "../../components/investedge/index";
const InvesedgePage = () => (
  <>
    <Helmet />
    <Investedge />
  </>
);

export default InvesedgePage;
InvesedgePage.displayName = "Investedge";
