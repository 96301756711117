import React from "react";
// import Arrow from "../../assets/image/icons/Line.svg";
import image_1 from "../../pages/about/images/image_1.png";
import image_2 from "../../pages/about/images/image_2.png";
// import image_3 from "../../pages/about/images/image_3.png";
// import image_4 from "../../pages/about/images/image_4.png";
// import image_5 from "../../pages/about/images/image_5.png";
// import image_6 from "../../pages/about/images/image_6.png";
import Investor from "./Investor";

function Investment() {
  const teamData = [
    {
      name: "Zerodha's Rainmatter",
      position: "Initiative by Zerodha",
      image: image_1,
    },
    {
      name: "Sujeet Kumar",
      position: "Co-founder, Udaan",
      image: image_2,
    },

    // {
    //   name: "Prasanna Singaraji",
    //   position: "CEO, Qentelli",
    //   image: image_3,
    // },

    // {
    //   name: "Mohit Mutreja",
    //   position: "Chairman and MD, AlphaGrep",
    //   image: image_4,
    // },

    // {
    //   name: "Sandeep Nayak",
    //   position: "Senior Partner, Bain & Co",
    //   image: image_5,
    // },

    // {
    //   name: "Vani Venkatesh",
    //   position: "CEO-Global Buisness, Airtel Buisness",
    //   image: image_6,
    // },
  ];
  return (
    <div className="py-24 px-5 md:px-20 bg-primarybg-100 z-[59] relative">
      <h2 className="font-montserrat text-xl md:text-[32px] font-semibold text-center text-[#FCFCFD]">
        Our Investors and Supporters
      </h2>
      <p className="mt-6 ont-montserrat font-normal text-sm md:text-xl text-center text-[#B1B5C4]">
        Our visionary investors and supporters believe in our mission to
        transform the wealth industry with innovative
        <br />
        technology, fostering growth for wealth buisneses.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8 pt-2 md:pt-4">
        {teamData.map((member, index) => (
          <Investor member={member} ind={index} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Investment;
