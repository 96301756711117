import React from "react";
import Cross from "../../assets/image/icons/modal_circle_over_cross.svg";
import Grow from "@mui/material/Grow";
import SuccessIllustration from "../../assets/image/illustrations/success_modal_illustration.svg";
export default function RequestModal({
  open,
  setOpen,
  onClose = () => setOpen(false),
  text = "Thanks for filling the form. We will reach out to you.",
}) {
  return (
    <Grow in={open}>
      <div className="fixed left-0 right-0 top-0 bottom-0  z-50 w-[100vw] h-[100vh] flex justify-center items-center">
        <div
          className="w-[90%] sm:w-[75%] md:w-[550px] rounded-xl bg-primarybg-300  pt-10 px-10 sm:px-20 pb-10 relative"
          style={{ boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.05)" }}
        >
          <img
            src={Cross}
            alt="err"
            className="absolute top-4 right-6"
            onClick={onClose}
          />
          <img src={SuccessIllustration} alt="err" className="m-auto mt-14" />
          <p className="text-[#FCFCFD] text-center mt-6">{text}</p>
          <button
            className="bg-primaryblue-200 flex items-center py-2 px-6 rounded-[90px] mt-6 m-auto"
            onClick={onClose}
          >
            <p className="font-dmsans text-[#FCFCFD] font-bold">Close</p>
          </button>
        </div>
      </div>
    </Grow>
  );
}
