import React, { useState, useEffect } from "react";
import Logo from "../../assets/image/logo/BeyondIRR_ WhiteIcon.png";
import MobileLogo from "../../assets/image/logo/beyondirr_header_logo.png";
import WhiteLogo from "../../assets/image/logo/mobile_header_menu_logo.svg";
import { usePopper } from "react-popper";

import ClickAwayListener from "react-click-away-listener";

import Arrow from "../../assets/image/icons/header_down_arrow.svg";
import Menu from "../../assets/image/icons/menu_icon.svg";
import MenuCross from "../../assets/image/icons/header_menu_cross.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import WhiteArrow from "../../assets/image/icons/header_blue_menu_arrow.svg";
import Fade from "@mui/material/Fade";
import ArrowIcon from "../../assets/image/icons/ArrowIcon";

export default function Header({ bgSolutions, loginBtn }) {
  const location = useLocation();
  const [bg, setBg] = useState("transparent");
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [investedgebg, setInvestedgebg] = useState("");
  const [investlanebg, setInvestlanebg] = useState("");
  const [investalphabg, setInvestalphabg] = useState("");
  const [clientRetentionbg, setClientRetentionbg] = useState("");

  // const [productsreferenceRef, setproductsReferenceRef] = useState(null);
  // const [productspopperRef, setproductsPopperRef] = useState(null);

  const [ourSolutionsreferenceRef, setOurSolutionsReferenceRef] =
    useState(null);
  const [ourSolutionspopperRef, setOurSolutionsPopperRef] = useState(null);

  const [sidenavbar, setSidenavbar] = useState(false);
  // const [productsSubmenu, setProductsSubmenu] = useState(false);
  const [solutonsSubmenu, setSolutionsSubmenu] = useState(false);

  const OurSolutionspopper = usePopper(
    ourSolutionsreferenceRef,
    ourSolutionspopperRef,
    {
      placement: "bottom-end",
      modifiers: [
        {
          name: "offset",
          enabled: true,
        },
      ],
      options: {
        offset: [0, 32],
      },
    }
  );
  const listenScrollEvent = () => {
    if (window.scrollY > 50) setBg("rgba(12, 4, 24, 0.85)");
    else setBg("transparent");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  const handleArrowClick = () => {
    var elmnt = document.getElementById("mobile-header-menu-arrow");
    elmnt.style.transform = solutonsSubmenu
      ? "rotate(360deg)"
      : "rotate(-180deg)";
    setSolutionsSubmenu(!solutonsSubmenu);
  };

  // const getLoginUrl = () => {
  //   if (location.pathname === "/investedge") {
  //     return process.env.REACT_APP_investedge + "login";
  //   }
  //   if (location.pathname === "/investlane") {
  //     return process.env.REACT_APP_investlane + "auth/login";
  //   }
  //   return "#";
  // };
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -80;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };
  return (
    <>
      {/* <div
        className={`hidden md:block md:fixed md:top-0 md:z-100 md:w-full md:px-20 md:pt-7 md:pb-5 z-[60] relative`}
        style={{ background: bg }}
      >
        <div className="flex justify-between items-center">
          <Link to="/">
            <img src={Logo} alt="err" className="h-[35px]" />
          </Link>
          <div className="flex">
            <div
              ref={setproductsReferenceRef}
              onClick={() => setProductsOpen(!productsOpen)}
              className="flex cursor-pointer pr-6 relative"
            >
              <p className="font-montserrat cursor-pointer text-[#FCFCFD] leading-[24px] pr-3">
                Products
              </p>
              <img src={Arrow} alt="err" />
            </div>

            <Link to="/about" className="relative">
              <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer">
                About Us
              </p>
              {location.pathname === "/about" && (
                <div
                  className="absolute w-[20%] h-[4px] rounded-[4px] bottom-[-8px] z-20"
                  style={{
                    left: "28%",
                    background:
                      "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                  }}
                />
              )}
            </Link>
            <a
              href={getLoginUrl()}
              // ref={setReferenceRef}
              target="_blank"
              rel="noreferrer"
              className={`flex cursor-pointer ${loginBtn ? "" : "hidden"}`}
            >
              <p className="font-montserrat text-[#FCFCFD] leading-[24px] pr-4">
                Login
              </p>
            </a>
          </div>
        </div>
      </div> */}
      <div
        className={`hidden lg:block md:fixed md:top-0 md:z-100 md:w-full md:px-20 md:pt-7 md:pb-5 z-[60] relative`}
        style={{ background: bg }}
      >
        <div className="flex justify-between items-center">
          <Link to="/">
            <img src={Logo} alt="err" className="h-[35px]" />
          </Link>
          <div className="flex">
            <div
              ref={setOurSolutionsReferenceRef}
              onClick={() => setSolutionsOpen(!solutionsOpen)}
              className="flex cursor-pointer pr-6 relative"
            >
              <p className="font-montserrat cursor-pointer text-[#FCFCFD] leading-[24px] pr-3">
                Our Solutions
              </p>
              <img src={Arrow} alt="err" />
            </div>

            <Link to="/about" className="relative">
              <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer">
                About Us
              </p>
              {location.pathname === "/about" && (
                <div
                  className="absolute w-[20%] h-[4px] rounded-[4px] bottom-[-8px] z-20"
                  style={{
                    left: "28%",
                    background:
                      "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                  }}
                />
              )}
            </Link>
            <Link to="/pricing" className="relative">
              <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer">
                Pricing
              </p>
              {location.pathname === "/pricing" && (
                <div
                  className="absolute w-[20%] h-[4px] rounded-[4px] bottom-[-8px] z-20"
                  style={{
                    left: "28%",
                    background:
                      "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                  }}
                />
              )}
            </Link>
            <Link to="/resources" className="relative">
              <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer">
                Resources
              </p>
              {location.pathname === "/resources" && (
                <div
                  className="absolute w-[20%] h-[4px] rounded-[4px] bottom-[-8px] z-20"
                  style={{
                    left: "28%",
                    background:
                      "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                  }}
                />
              )}
            </Link>
            <a href={process.env.REACT_APP_investedgeDomain + "login"}>
              <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer font-bold">
                Login
              </p>
            </a>
          </div>

          {solutionsOpen && (
            <ClickAwayListener onClickAway={() => setSolutionsOpen(false)}>
              <Fade in={solutionsOpen} unmountOnExit>
                <div
                  className="bg-primarybg-300 rounded-2xl z-[251] border border-[#353945] shadow-[0px_12px_64px_#06071B] p-4 pb-0 w-[430px] mt-4 transition-all"
                  ref={setOurSolutionsPopperRef}
                  style={OurSolutionspopper.styles.popper}
                  {...OurSolutionspopper.attributes.popper}
                >
                  <Link to="/client-aquisition">
                    <div
                      className="p-4 w-full"
                      style={{
                        boxShadow: "inset 0px -1px 0px #23262F",
                        background: investlanebg,
                      }}
                      onMouseEnter={() =>
                        setInvestlanebg(
                          "radial-gradient(52.58% 58.42% at 50% 100%, #FF873D 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setInvestlanebg("")}
                    >
                      <div className="flex justify-between items-center">
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium">
                          Client Acquisition
                        </p>
                        <ArrowIcon
                          color={
                            investlanebg && "url(#paint0_linear_1250_26105)"
                          }
                        />
                      </div>

                      {/* <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ">
                        For Individual MFDs/ Financial Professionals and Wealth
                        Enterprises 
                      </p> */}
                    </div>
                  </Link>
                  <Link to="/client-engagement">
                    <div
                      className="p-4 w-full"
                      style={{
                        boxShadow: "inset 0px -1px 0px #23262F",
                        background: investalphabg,
                      }}
                      onClick={() => setSolutionsOpen(false)}
                      onMouseEnter={() =>
                        setInvestalphabg(
                          "radial-gradient(50% 46.32% at 50% 100%, #0ECAE9 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setInvestalphabg("")}
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium ">
                          Client Engagement
                        </p>
                        <ArrowIcon color={investalphabg && "skyblue"} />
                      </div>
                      {/* <div className="flex justify-end pb-2">
                        
                      </div>

                      <p className="font-montserrat font-normal text-sm eading-[21px] text-white">
                        For Wealth Enterprises 
                      </p> */}
                    </div>
                  </Link>
                  <Link to="/client-retention">
                    <div
                      className="p-4 w-full"
                      style={{
                        boxShadow: "inset 0px -1px 0px #23262F",
                        background: clientRetentionbg,
                      }}
                      onClick={() => setSolutionsOpen(false)}
                      onMouseEnter={() =>
                        setClientRetentionbg(
                          "radial-gradient(59.18% 36.32% at 50% 95.26%, rgba(216, 79, 119, 0.64) 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setClientRetentionbg("")}
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium ">
                          Client Retention
                        </p>
                        <ArrowIcon
                          color={
                            clientRetentionbg &&
                            "url(#paint0_linear_1250_48326)"
                          }
                        />
                      </div>
                      {/* <div className="flex justify-end pb-2">
                        
                      </div>

                      <p className="font-montserrat font-normal text-sm eading-[21px] text-white">
                        For Wealth Enterprises
                      </p> */}
                    </div>
                  </Link>
                  <Link to="/investment-opportunities">
                    <div
                      className="p-4 w-full"
                      style={{
                        boxShadow: "inset 0px -1px 0px #23262F",
                        background: investedgebg,
                      }}
                      onMouseEnter={() =>
                       setInvestedgebg(
                          "radial-gradient(52.58% 58.42% at 50% 100%, #FF873D 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setInvestedgebg("")}
                    >
                      <div className="flex justify-between items-center">
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium">
                          Investment Opportunities Access
                        </p>
                        <ArrowIcon
                          color={
                            investedgebg && "url(#paint0_linear_1250_48326)"
                          }
                        />
                      </div>
                      {/* <div className="flex justify-end pb-2">
                        
                      </div> */}
                      {/* <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC]">
                        For Individual MFDs/ Financial Professionals and Wealth
                        Enterprises
                      </p> */}
                    </div>
                  </Link>
                </div>
              </Fade>
            </ClickAwayListener>
          )}
        </div>
      </div>

      {/* {loginOpen && (
            <ClickAwayListener onClickAway={() => setLoginOpen(false)}>
              <Fade in={loginOpen}>
                <div
                  className="bg-primarybg-300 rounded-2xl border border-[#353945] shadow-[0px_12px_64px_#06071B] px-4 w-[250px]"
                  ref={setPopperRef}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <Link to="/investedge/login">
                    <div
                      className="p-4 flex justify-between items-center w-full transition-all duration-1000"
                      style={{
                        boxShadow: "inset 0px -1px 0px #23262F",
                        background: investedgebg,
                      }}
                      onMouseEnter={() =>
                        setInvestedgebg(
                          "radial-gradient(59.18% 36.32% at 50% 95.26%, rgba(216, 79, 119, 0.64) 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setInvestedgebg("")}
                    >
                      <div className="flex">
                        <img
                          src={Investedge}
                          alt="err"
                          style={
                            investedgebg
                              ? {
                                  filter:
                                    "drop-shadow(0px 0px 15px #F02763) drop-shadow(0px 0px 15px #D74E77)",
                                }
                              : {}
                          }
                          className="transition-all duration-500"
                        />
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3">
                          InvestEdge
                        </p>
                      </div>
                      <img
                        src={investedgebg ? InvestedgeMenuArrow : MenuArrow}
                        alt="err"
                      />
                    </div>
                  </Link>
                  <Link to="/investlane/login">
                    <div
                      className="p-4 flex justify-between items-center w-full duration-500 transition-all"
                      style={{
                        background: investlanebg,
                      }}
                      onMouseEnter={() =>
                        setInvestlanebg(
                          "radial-gradient(52.58% 58.42% at 50% 100%, #FF873D 0%, rgba(6, 7, 27, 0) 100%)"
                        )
                      }
                      onMouseLeave={() => setInvestlanebg("")}
                    >
                      <div className="flex">
                        <img
                          src={Investlane}
                          alt="err"
                          style={
                            investlanebg
                              ? {
                                  filter:
                                    "drop-shadow(0px 0px 15px #FF3830) drop-shadow(0px 0px 15px #FF3332)",
                                }
                              : {}
                          }
                          className="transition-all duration-500"
                        />
                        <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3">
                          InvestLane
                        </p>
                      </div>
                      <img
                        src={investlanebg ? InvestlaneMenuArrow : MenuArrow}
                        alt="err"
                      />
                    </div>
                  </Link>
                </div>
              </Fade>
            </ClickAwayListener>
          )} */}
      {/* </div>
      </div> */}

      <div
        className="lg:hidden bg-primarybg-300 px-5 flex justify-between fixed top-0 w-full"
        style={{ zIndex: 80, background: "rgba(12, 4, 24, 0.85)" }}
      >
        <Link to="/">
          <img src={MobileLogo} alt="err" className="h-[28px] my-5" />
        </Link>

        <div className="flex items-center justify-end">
          <a
            href={process.env.REACT_APP_investedgeDomain + "login"}
            className={`font-montserrat font-bold text-[#FCFCFD]`}
          >
            Login
          </a>
          <img
            src={Menu}
            alt="err"
            className="ml-5"
            onClick={() => setSidenavbar(true)}
          />
        </div>
      </div>

      <div
        className={
          sidenavbar
            ? "lg:hidden duration-300 bg-primarybg-100 w-[100vw] h-[100%] fixed top-0 z-[100] right-0 p-8 overflow-hidden"
            : "lg:hidden bg-primarybg-100 w-[100vw] h-[100%] fixed top-0 right-[-100%] p-8 duration-700 z-[100] overflow-hidden"
        }
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div
              className="py-5 flex justify-between items-center"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.12)",
              }}
            >
              <p className="font-poppins font-bold text-medium text-[#FCFCFD]">
                Menu
              </p>
              <img
                src={MenuCross}
                alt="err"
                onClick={() => setSidenavbar(false)}
              />
            </div>

            <div className="mt-5 flex justify-between items-center">
              <p className="font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD]">
                Our Solutions
              </p>
              <img
                src={WhiteArrow}
                alt="err"
                id="mobile-header-menu-arrow"
                className="duration-200"
                onClick={handleArrowClick}
              />
            </div>
            <Fade in={solutonsSubmenu} unmountOnExit>
              <div className="bg-primarybg-300 rounded-2xl w-full mt-4">
                
                <Link to="/client-aquisition">
                  <div
                    className="p-4 w-full"
                    style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                  >
                    <div className="flex">
                      {/* <img src={Investlane} alt="err" /> */}
                      <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                        Client Acquisition
                      </p>
                    </div>
                    {/* <div className="flex justify-between items-start pt-1">
                      <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-6">
                        For Individual MFDs/ Financial Professionals and Wealth
                        Enterprises
                      </p>
                      <ArrowIcon />
                    </div> */}
                  </div>
                </Link>
                <Link to="/client-engagement">
                  <div className="p-4 w-full">
                    <div className="flex items-center">
                      {/* <img src={InvestGPT} alt="err" /> */}
                      <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                        Client Engagement
                      </p>
                    </div>
                    {/* <div className="flex justify-between items-start pt-1">
                      <p className="font-montserrat font-normal text-sm leading-[21px] text-white ml-6">
                        For Wealth Enterprises
                      </p>
                      <ArrowIcon />
                    </div> */}
                  </div>
                </Link>
                <Link to="/client-retention">
                  <div className="p-4 w-full">
                    <div className="flex items-center">
                      {/* <img src={InvestGPT} alt="err" /> */}
                      <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                        Client Retention
                      </p>
                    </div>
                    {/* <div className="flex justify-between items-start pt-1">
                      <p className="font-montserrat font-normal text-sm leading-[21px] text-white ml-6">
                        For Wealth Enterprises
                      </p>
                      <ArrowIcon />
                    </div> */}
                  </div>
                </Link>
                <Link to="/investment-opportunities">
                  <div
                    className="p-4 w-full"
                    style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                  >
                    <div className="flex">
                      {/* <img src={Investedge} alt="err" /> */}
                      <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                        Investment Opportunities Access
                      </p>
                    </div>
                    {/* <div className="flex justify-between items-start pt-1">
                      <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-6">
                        For Individual MFDs/ Financial Professionals and Wealth
                        Enterprises
                      </p>
                      <ArrowIcon />
                    </div> */}
                  </div>
                </Link>

              </div>
            </Fade>

            <Link to="/about">
              <p className="mt-5 font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD]">
                About Us
              </p>
            </Link>
            <Link to="/pricing">
              <p className="mt-5 font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD]">
                Pricing
              </p>
            </Link>
            <Link to="/resources">
              <p className="mt-5 font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD]">
                Resources
              </p>
            </Link>
          </div>
          <div className="flex justify-center">
            <img src={WhiteLogo} alt="err" />
          </div>
        </div>
      </div>
    </>
  );
}
