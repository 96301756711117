import React from "react";
import Header from "../header/Header";
import Footer from "../footer";

const contactInfo = {
  "Legal Entity Name":
    "Valiant Fintech Private Limited (referred to as “BeyondIRR”)",
  Address:
    "HD-085, Plot C-20, GBlock, Wework Enam Sambhav, BKC Road, Bandra Kurla Complex, Bandra(East), Mumbai, Maharashtra, India – 400051",
  Email: "connect@beyondirr.tech",
  Telephone: "+918069510193",
};

export default function ContactUs() {
  return (
    <>
      <Header />
      <div className="min-h-[100vh] flex flex-col">
        <div className="bg-primarybg-300 px-5 py-20 md:px-20 md:py-24 flex-1">
          <h2 className="font-montserrat text-[32px] leading-[48px] font-medium text-[#FCFCFD]">
            Contact Us
          </h2>
          <div className="font-light text-base leading-relaxed text-white">
            <ul className="mt-4">
              {Object.entries(contactInfo).map(([key, value]) => (
                <li key={key} className="mb-2 last:mb-0">
                  <span className="font-bold">{key}: </span>
                  <span className="font-light">{value}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
