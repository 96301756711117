import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "./Card";
export default function MeetTheTeam() {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_meetTeamURL)
      .then(function (response) {
        setTeam(
          response.data.sort(
            (a, b) =>
              b.priority_rank - a.priority_rank || a.name.localeCompare(b.name)
          )
        );
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);
  return (
    <div className="py-18 px-5 md:px-20 bg-primarybg-300">
      <h2 className="font-montserrat text-xl md:text-[32px] font-semibold text-center text-[#FCFCFD]">
        Meet The Team
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-16 pt-2 md:pt-6">
        {team.map((member, index) => (
          <Card member={member} key={index} ind={index} />
        ))}
      </div>
    </div>
  );
}
