import React, { useEffect } from "react";
import Footer from "../footer";
import Header from "../header/Header";
import Insights from "./Insights";
import HeroSection from "./HeroSection";

export default function Resource() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <HeroSection/>
      <Insights />
      <Footer />
    </>
  );
}
