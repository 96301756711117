import React ,{useState}from "react";
import Arrow from "../../assets/image/icons/right_arrow.svg";
import RequestModal from "../modal/RequestModal";


function Buttons() {
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-primarybg-300 w-full py-10 flex justify-center z-100 ">
      <div className="flex flex-col space-y-8 sm:space-y-0 sm:flex-row sm:justify-center sm:space-x-4 mt-20 ">
        <button
          className="py-2 sm:py-4 px-6 rounded-[90px] cursor-pointer border border-white font-dmsans text-[#FCFCFD] font-bold text-center"
          onClick={() => setOpen(true)}
        >
          Connect With Us
        </button>
        <a href={process.env.REACT_APP_investedgeDomain + "signup"}>
          <button className="bg-primaryblue-200 flex items-center py-2 sm:py-4 px-6 rounded-[90px] cursor-pointer font-dmsans text-[#FCFCFD] font-bold text-center">
            Become a Member
            <img src={Arrow} alt="err" className="ml-4" />
          </button>
        </a>
      </div>
      <RequestModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default Buttons;
