import React, { useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import Header from "../header/Header";
import Offer from "./offer";
import Footer from "../footer";
import Carousel from "./Carousel";
import HeroSection2 from "./HeroSection2";

export default function InvestGPT() {
    const [background, setBackground] = useState("transparent");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header background={background} />
            <div className="bg-primarybg-300 px-5 md:px-20 pb-32 md:pb-20">
                <HeroSection setBackground={setBackground} />
                <HeroSection2/>
                <Offer />
                <Carousel/>
            </div>
            <Footer />
        </>
    )
}