// COMPONENTS PARTS
import React from "react";
import Banner from "./banner";
import Advantages from "./advantages";
// import Faqs from "./faqs";
// import Integration from "./integration";
// import Structure from "./structure";
import Header from "../header/Header";
import Footer from "../footer";
// import ProductProviders from "./productProviders";

const Home = () => {
  const background = "transparent";
  // const [background, setBackground] = useState("transparent");
  return (
    <>
      <Header background={background} loginBtn={true} />
      <div className="bg-[#06071B] pt-16 overflow-hidden">
        <Banner />
        {/* <Faqs />
        <Integration />
        <Structure />
        <div className="xl:max-w-[1480px] px-[20px] md:px-[30px] xl:px-[80px] mx-auto">
          <div className="hidden md:block bg-[#51515f40] h-[1px]" />
        </div> */}
        <Advantages />
        {/* <ProductProviders /> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
