import React, { useEffect } from "react";
import Footer from "../footer";
import HeroSection from "./HeroSection";
import Header from "../header/Header";
import { Banner2, Banner3 } from "./Banners";
import { EssentialPlan } from "./EssentialPlan";
// import Features from "./Features";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <HeroSection />
      <Banner2 />
      <EssentialPlan />
      <Banner3 />
      {/* <Features /> */}
      <Footer />
    </>
  );
}
