import React from "react";
export default function Offer() {
  return (
    <>
      <div className=" md:pt-20 mt-20  bg-primarybg-300 z-30 md:text-center">
        <h2 className="font-montserrat text-xl md:text-[32px] font-semibold  text-[#FCFCFD]">
          What we offer?
        </h2>
        <p className="font-montserrat font-medium text-lg  md:text-sm leading-[30px] text-[#B1B5C4] pt-10 z-30 hidden sm:block">
          Our Client engagement tool has been tailored to the financial
          industry's diverse needs. It delivers real-time insights for
          <br /> informed decision-making, enhances sales processes with
          in-depth product insights, and offers actionable portfolio guidance,
          <br /> integrating market trends and investment strategies of the
          enterprise. This versatile tool empowers financial professionals to
          <br /> address client queries, articulate product benefits
          effectively, and offer tailored guidance for individual client needs.
        </p>
        <p className="font-montserrat font-medium leading-[30px] text-[#B1B5C4] pt-4 z-30 block sm:hidden">
          InvestGPT provides a comprehensive suite of solutions tailored to the
          financial industry's diverse needs. MarketGPT delivers real-time
          insights for informed decision-making, ProductGPT enhances sales
          processes with in-depth product insights, and PortfolioGPT offers
          actionable portfolio guidance, integrating market trends and
          investment strategies. This versatile tool empowers financial
          professionals to address client queries, articulate product benefits
          effectively, and offer tailored guidance for individual client needs.
        </p>
      </div>
    </>
  );
}
