import React from "react";
import ProductGPTIcon from '../../assets/image/icons/productgpt.svg'
import ProductGPTIllustration from '../../assets/image/illustrations/productgptimg.svg'

export default function ProductGPT() {
    return (
        <div className="flex items-center md:items-start flex-col md:flex-row md:px-20 bg-primarybg-300">
            <div className="flex flex-col items-start w-full">
                <img src={ProductGPTIcon} alt="err" className="mt-8" />
                <p className="font-montserrat font-medium md:text-xl md:leading-[30px] text-[#B1B5C4] pt-10 z-30 whitespace-break-spaces">
                    Equip your team and clients with comprehensive product insights, articulate product benefits effectively and close sales faster.
                </p>
            </div>
            <div className="relative w-full h-full bg-[url('../image/illustrations/mobile_mesh.svg')] bg-cover bg-center">
                <img src={ProductGPTIllustration} className="object-cover m-auto" alt="err"/>
            </div>
            {/* <img src={ProductGPTIllustration} alt="err" className="w-full h-full mt-8 md:mt-0 md:scale-100 scale-[1.1]" /> */}
        </div>
    )
}