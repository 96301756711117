import React from "react";
import Arrow from "../../assets/image/icons/45_tilted_arrow.svg";
import { Link } from "react-router-dom";
export default function InsightsCard({ item }) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = item.pub_date.split("-");
  return (
    <Link
      className="bg-primarybg-100 w-full p-6 relative flex flex-col justify-between cursor-pointer hover:translate-y-[-12px] duration-500"
      to={{
        pathname: `/blog`,
        search: `?id=${item.id}`,
      }}
    >
      <div>
        <img src={item.image} alt="err" className="w-full h-[224px]" />
        <p className="font-montserrat font-semibold text-primaryblue-200 text-xs sm:text-sm mt-8">
          {item.domain}
        </p>
        <div className="flex justify-between w-full items-start mt-3">
          <h2 className="max-w-[80%] font-montserrat text-[#FCFCFD] font-semibold text-sm sm:text-xl">
            {item.title}
          </h2>
          <img src={Arrow} alt="err" className="sm:mt-2" />
        </div>
        <p className="mt-3 font-montserrat font-normal text-sm text-[#989CA4]">
          {item.brief_text.length > 350
            ? item.brief_text.slice(0, 350) + "..."
            : item.brief_text}
        </p>
      </div>
      <div className="mt-12 flex">
        <img
          src={item.author_image}
          alt="err"
          className="w-10 h-10 rounded-full"
        />
        <div className="ml-3">
          <p className="font-montserrat text-[#FCFCFD] text-sm font-medium">
            {item.author}
          </p>
          <p className="font-montserrat text-[#9B9CA4] text-sm font-medium">
            {`${date[2]} ${months[parseInt(date[1]) - 1]} ${date[0]}`}
          </p>
        </div>
      </div>
    </Link>
  );
}
