import React from "react";
import Arrow from "../../assets/image/icons/right_arrow.svg";
export function Banner2() {
  return (
    <div className="bg-primarybg-300 w-full pt-20 pb-16 flex justify-center z-100 ">
      <div className="bg-primarybg-100 z-[59] px-6 md:px-0 py-6 md:py-10 w-full md:w-3/4 rounded-2xl border border-[#353945] mx-5 md:mx-0">
        <h2 className="text-center text-[#FCFCFD] font-montserrat font-bold md:text-2xl mb-6 md:mb-5 leading-[24px] md:leading-[54px]">
          Essential Plan
        </h2>
        <p className="font-montserrat font-medium text-sm md:text-3xl text-center text-red-800 line-through">
          <span style={{ color: " #E65876", textDecoration: "line-through" }}>
            ₹ 10,000 per annum
          </span>
        </p>

        <h2 className="text-center mt-2 text-[#FCFCFD] font-montserrat font-bold md:text-4xl mb-3 md:mb-4 leading-[24px] md:leading-[54px]">
          &#8377;4995{" "}
        </h2>

        <p className="font-montserrat font-normal text-sm text-center text-[#B1B5C4]">
          per login, per annum (excl. of GST)
        </p>
        <div className="flex justify-center space-x-4 mt-8">
          <a href={process.env.REACT_APP_investedgeDomain + "signup"}>
            <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
              <p className="font-dmsans text-[#FCFCFD] font-bold">
                Subscribe Now
              </p>
              <img src={Arrow} alt="err" className="ml-4" />
            </button>
          </a>
        </div>
      </div>{" "}
    </div>
  );
}
export function Banner1() {
  return (
    <>
      <div>
        <div className="hidden md:block bg-primarybg-100 py-6 px-5 md:mx-10 md:py-18 md:px-18">
          <h2 className="text-center text-[#FCFCFD] font-montserrat font-bold md:text-4xl mb-6 md:mb-7 leading-[24px] md:leading-[54px]">
            1 User Login &amp; 75 MF Central Credits
          </h2>
          <p className="font-montserrat font-normal text-sm md:text-xl text-center text-[#B1B5C4]">
            &#8377;10,000 per annum
          </p>

          <h2 className="text-center mt-6 text-[#FCFCFD] font-montserrat font-bold md:text-4xl mb-3 md:mb-7 leading-[24px] md:leading-[54px]">
            &#8377;4995{" "}
          </h2>

          <p className="font-montserrat font-normal text-sm md:text-xl text-center text-[#B1B5C4]">
            per annum (excl. of GST)
          </p>
          <div className="flex justify-center space-x-4 mt-10">
            <a href={process.env.REACT_APP_investedgeDomain + "signup"}>
              <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
                <p className="font-dmsans text-[#FCFCFD] font-bold">
                  Subscribe Now
                </p>
                <img src={Arrow} alt="err" className="ml-4" />
              </button>
            </a>
          </div>
        </div>

        <div className="md:hidden bg-primarybg-300 w-full pb-10 flex justify-center">
          <div className="bg-primarybg-100 py-6 px-5 md:py-18 mt-6 md:px-18 text-center">
            <h2 className="text-[#FCFCFD] font-montserrat font-bold text-xl md:text-4xl mb-6 md:mb-7 leading-[24px] md:leading-[54px]">
              1 User Login &amp; 75 MF Central Credits
            </h2>
            <p className="font-montserrat font-normal text-sm md:text-xl text-[#B1B5C4]">
              ₹10,000 per annum
            </p>

            <h2 className="mt-6 text-[#FCFCFD] font-montserrat font-bold text-xl md:text-4xl mb-3 md:mb-7 leading-[24px] md:leading-[54px]">
              ₹4995{" "}
            </h2>

            <p className="font-montserrat font-normal text-sm md:text-xl text-[#B1B5C4]">
              per annum (excl. of GST)
            </p>
            <div className="flex justify-center space-x-4 mt-10">
              <a href={process.env.REACT_APP_investedgeDomain + "signup"}>
                <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
                  <p className="font-dmsans text-[#FCFCFD] font-bold">
                    Subscribe Now
                  </p>
                  <img src={Arrow} alt="err" className="ml-4" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function Banner3() {
  return (
    <div className="bg-primarybg-300 w-full pt-20 pb-16 flex justify-center">
      <div className="bg-primarybg-100 px-6 md:px-0 py-6 md:py-10 w-full md:w-3/4 rounded-2xl border border-[#353945] mx-5 md:mx-0">
        <h2 className="text-center mt-2 text-[#FCFCFD] font-montserrat font-bold md:text-4xl mb-3 md:mb-4 leading-[24px] md:leading-[54px]">
          Get Free Trial of Essential for 14 Days
        </h2>

        <p className="font-montserrat font-normal text-sm text-center text-[#B1B5C4]">
          Explore our Product for free with 14 days free trial of Essential Plan
          , You can upgrade after trial period ends.
        </p>
        <div className="flex justify-center space-x-4 mt-8">
          <a href={process.env.REACT_APP_investedgeDomain + "signup"}>
            <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
              <p className="font-dmsans text-[#FCFCFD] font-bold">
                Start 14 Day Free Trial
              </p>
              <img src={Arrow} alt="err" className="ml-4" />
            </button>
          </a>
        </div>
      </div>{" "}
    </div>
  );
}

export function Banner4() {
  const redirectToWhatsAppGroup = () => {
    window.open("https://chat.whatsapp.com/CpBAqXdRgHM0M4vLGmlDkF", "_blank");
  };
  return (
    <>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between bg-primarybg-100 py-6 px-5 md:py-18 md:px-18">
        <div className="text-left mb-6 md:mb-0 md:mr-auto">
          <p className="text-[#FCFCFD] mb-2">Join Our Community</p>
          <p className="text-[#FCFCFD]">
            Get the latest Insights, delivered to your inbox
          </p>
        </div>
        <div>
          <button
            className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px]"
            onClick={redirectToWhatsAppGroup}
          >
            <p className="font-dmsans text-[#FCFCFD] font-bold">
              Join the Community
            </p>
            <img src={Arrow} alt="err" className="ml-4" />
          </button>
        </div>
      </div>
    </>
  );
}
