/* eslint-disable no-unused-vars */
import { styled, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import InvestlaneLogo from "../../assets/image/logo/investlane_logo.png";
import Mesh from "../../assets/image/illustrations/investment-opportunities.svg";
import Arrow from "../../assets/image/icons/right_arrow.svg";

const redirectToWhatsAppGroup = () => {
  window.open("https://chat.whatsapp.com/CpBAqXdRgHM0M4vLGmlDkF", "_blank");
};

const ShadowType = styled("div")(({ type }) => ({
  padding: 1,
  background: "#06071B",
  ...(type === "blue" && { boxShadow: "0px 0px 32px rgba(0, 203, 249, 0.24)" }),
  ...(type === "orange" && {
    boxShadow: "0px 0px 32px rgba(253, 104, 30, 0.24)",
  }),
  ...(type === "pink" && {
    boxShadow: "0px 0px 32px rgba(255, 95, 109, 0.24)",
  }),
  ...(type === "blue" && {
    background: "-webkit-linear-gradient(#09C3E7, #7669F0)",
  }),
  ...(type === "orange" && {
    background: "-webkit-linear-gradient(#FF9F38, #FE5C44)",
  }),
  ...(type === "pink" && {
    background: "-webkit-linear-gradient(#9C2979, #F15E75)",
  }),
}));

const GradientBG = styled("div")({
  background:
    "conic-gradient(from -13.63deg at 62.8% 52.8%, rgb(44, 105, 254) 0deg, rgb(0, 203, 249) 105deg, rgb(168, 33, 232) 241.88deg, rgb(168, 33, 232) 356.25deg, rgb(44, 105, 254) 360deg)",
  opacity: 0.3,
  filter: "blur(100px)",
});

const AppSwitch = styled(Switch)({
  "& .MuiSwitch-track": {
    backgroundColor: "#a99999",
  },
});

const icons = {
  OffShore: (
    <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4217 9.20461C12.4382 9.47907 12.7248 9.6642 12.9577 9.51807C14.4046 8.61026 15.3666 7.00074 15.3666 5.16654C15.3666 2.33147 13.0684 0.0332031 10.2333 0.0332031C8.3991 0.0332031 6.78958 0.995193 5.88176 2.44216C5.73563 2.67507 5.92077 2.96163 6.19523 2.97813C9.47751 3.17545 12.2243 5.92517 12.4217 9.20461Z"
        fill="#FCFCFD"
      />
      <path
        d="M10.9667 9.56693C10.9667 12.402 8.66841 14.7003 5.83335 14.7003C2.99828 14.7003 0.700012 12.402 0.700012 9.56693C0.700012 6.73187 2.99828 4.43359 5.83335 4.43359C8.66841 4.43359 10.9667 6.73187 10.9667 9.56693Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  Startup: (
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96667 7.36706C8.96667 6.55704 9.62332 5.90039 10.4333 5.90039H13.3667C14.1767 5.90039 14.8333 6.55704 14.8333 7.36706V13.2337C14.8333 14.0437 14.1767 14.7004 13.3667 14.7004H10.4333C9.62332 14.7004 8.96667 14.0437 8.96667 13.2337V7.36706ZM11.9 8.83372C12.305 8.83372 12.6333 9.16205 12.6333 9.56706C12.6333 9.97207 12.305 10.3004 11.9 10.3004C11.495 10.3004 11.1667 9.97207 11.1667 9.56706C11.1667 9.16205 11.495 8.83372 11.9 8.83372ZM12.6333 11.7671C12.6333 11.362 12.305 11.0337 11.9 11.0337C11.495 11.0337 11.1667 11.362 11.1667 11.7671C11.1667 12.1721 11.495 12.5004 11.9 12.5004C12.305 12.5004 12.6333 12.1721 12.6333 11.7671Z"
        fill="#FCFCFD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.63335 0.0332031C0.823336 0.0332031 0.166687 0.689853 0.166687 1.49987V13.2332C0.166687 14.0432 0.823336 14.6999 1.63335 14.6999C2.03836 14.6999 2.36669 14.3715 2.36669 13.9665V12.4999C2.36669 12.0949 2.69501 11.7665 3.10002 11.7665H4.56669C4.9717 11.7665 5.30002 12.0949 5.30002 12.4999V13.9665C5.30002 14.3715 5.62835 14.6999 6.03335 14.6999C6.84337 14.6999 7.50002 14.0432 7.50002 13.2332V1.49987C7.50002 0.689852 6.84337 0.0332031 6.03335 0.0332031H1.63335ZM4.56669 2.96654C4.9717 2.96654 5.30002 3.29486 5.30002 3.69987C5.30002 4.10488 4.9717 4.4332 4.56669 4.4332H3.10002C2.69501 4.4332 2.36669 4.10488 2.36669 3.69987C2.36669 3.29486 2.69501 2.96654 3.10002 2.96654H4.56669ZM4.56669 5.89987C4.9717 5.89987 5.30002 6.22819 5.30002 6.6332C5.30002 7.03821 4.9717 7.36654 4.56669 7.36654H3.10002C2.69501 7.36654 2.36669 7.03821 2.36669 6.6332C2.36669 6.22819 2.69501 5.89987 3.10002 5.89987H4.56669ZM4.56669 8.8332C4.9717 8.8332 5.30002 9.16153 5.30002 9.56654C5.30002 9.97155 4.9717 10.2999 4.56669 10.2999H3.10002C2.69501 10.2999 2.36669 9.97155 2.36669 9.56654C2.36669 9.16153 2.69501 8.8332 3.10002 8.8332H4.56669Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  "AIF's": (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.13965C22 9.17721 19.5376 11.6396 16.5 11.6396C13.4624 11.6396 11 9.17721 11 6.13965C11 3.10208 13.4624 0.639648 16.5 0.639648C19.5376 0.639648 22 3.10208 22 6.13965Z"
        fill="#FCFCFD"
      />
      <path
        d="M7 6.13965C7 8.07265 5.433 9.63965 3.5 9.63965C1.567 9.63965 0 8.07265 0 6.13965C0 4.20665 1.567 2.63965 3.5 2.63965C5.433 2.63965 7 4.20665 7 6.13965Z"
        fill="#FCFCFD"
      />
      <path
        d="M12 14.6396C12 16.8488 10.2091 18.6396 8 18.6396C5.79086 18.6396 4 16.8488 4 14.6396C4 12.4305 5.79086 10.6396 8 10.6396C10.2091 10.6396 12 12.4305 12 14.6396Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  Bonds: (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.90001 0.5C1.68499 0.5 0.700012 1.48497 0.700012 2.7V12.9667C0.700012 14.1817 1.68499 15.1667 2.90001 15.1667H8.03335C8.43835 15.1667 8.76668 14.8383 8.76668 14.4333V10.7667C8.76668 9.55164 9.75165 8.56667 10.9667 8.56667H14.6333C15.0384 8.56667 15.3667 8.23834 15.3667 7.83333V2.7C15.3667 1.48497 14.3817 0.5 13.1667 0.5H2.90001Z"
        fill="#FCFCFD"
      />
      <path
        d="M15.1548 10.7972C15.3382 10.41 14.9926 10.0332 14.5642 10.0332H10.9667C10.5617 10.0332 10.2333 10.3615 10.2333 10.7665V14.3641C10.2333 14.7925 10.6102 15.1381 10.9973 14.9547C11.2231 14.8478 11.431 14.7022 11.611 14.5222L14.7223 11.4109C14.9023 11.2309 15.0479 11.0229 15.1548 10.7972Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  "Mutual Funds": (
    <svg
      width="19"
      height="16"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.639648C0.895431 0.639648 0 1.53508 0 2.63965V16.6396C0 17.7442 0.89543 18.6396 2 18.6396H20C21.1046 18.6396 22 17.7442 22 16.6396V2.63965C22 1.53508 21.1046 0.639648 20 0.639648H2ZM19.8682 4.13596C20.1422 3.65644 19.9756 3.04559 19.4961 2.77158C19.0166 2.49757 18.4058 2.66416 18.1317 3.14368L14.8834 8.82821C14.6612 9.21719 14.2073 9.41087 13.7727 9.30221L8.71238 8.03714C7.40847 7.71116 6.04688 8.2922 5.38005 9.45915L2.13174 15.1437C1.85773 15.6232 2.02433 16.2341 2.50385 16.5081C2.98337 16.7821 3.59422 16.6155 3.86823 16.136L7.11653 10.4514C7.33881 10.0624 7.79268 9.86877 8.22731 9.97743L13.2876 11.2425C14.5915 11.5685 15.9531 10.9874 16.6199 9.82049L19.8682 4.13596Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  PMS: (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.10003 8.56732C2.10003 8.16231 2.42835 7.83398 2.83336 7.83398H4.30003C4.70504 7.83398 5.03336 8.16231 5.03336 8.56732V11.5007H6.50003V8.56732C6.50003 8.16231 6.82835 7.83398 7.23336 7.83398H8.70003C9.10504 7.83398 9.43336 8.16231 9.43336 8.56732V11.5007H10.9V8.56732C10.9 8.16231 11.2284 7.83398 11.6334 7.83398H13.1C13.505 7.83398 13.8334 8.16231 13.8334 8.56732V11.5007C14.6434 11.5007 15.3 12.1573 15.3 12.9673V13.7007C15.3 14.5107 14.6434 15.1673 13.8334 15.1673H2.10003C1.29001 15.1673 0.633362 14.5107 0.633362 13.7007V12.9673C0.633362 12.1573 1.29001 11.5007 2.10003 11.5007V8.56732Z"
        fill="#FCFCFD"
      />
      <path
        d="M0.633362 4.42661C0.633362 3.82689 0.99849 3.28758 1.55532 3.06485L7.42199 0.718182C7.77166 0.578314 8.16173 0.578314 8.5114 0.718182L14.3781 3.06485C14.9349 3.28758 15.3 3.82689 15.3 4.42661V4.9003C15.3 5.71032 14.6434 6.36697 13.8334 6.36697H2.10003C1.29001 6.36697 0.633362 5.71032 0.633362 4.9003V4.42661Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  "Pre-IPO": (
    <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.166687 6.36602C0.166687 5.15099 1.15166 4.16602 2.36669 4.16602H12.6334C13.8484 4.16602 14.8334 5.15099 14.8334 6.36602V12.966C14.8334 14.181 13.8484 15.166 12.6334 15.166H2.36669C1.15166 15.166 0.166687 14.181 0.166687 12.966V6.36602Z"
        fill="#FCFCFD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.30004 1.96667C4.89503 1.96667 4.5667 2.29499 4.5667 2.7V4.9C4.5667 5.30501 4.23838 5.63333 3.83337 5.63333C3.42836 5.63333 3.10004 5.30501 3.10004 4.9V2.7C3.10004 1.48497 4.08501 0.5 5.30004 0.5H9.70004C10.9151 0.5 11.9 1.48497 11.9 2.7V4.9C11.9 5.30501 11.5717 5.63333 11.1667 5.63333C10.7617 5.63333 10.4334 5.30501 10.4334 4.9V2.7C10.4334 2.29499 10.105 1.96667 9.70004 1.96667H5.30004Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  MLDs: (
    <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.36669 1.70013C2.36669 1.29512 2.03837 0.966797 1.63336 0.966797C1.22835 0.966797 0.900024 1.29512 0.900024 1.70013V3.1668C0.900024 3.57181 1.22835 3.90013 1.63336 3.90013C2.03837 3.90013 2.36669 3.57181 2.36669 3.1668V1.70013Z"
        fill="#FCFCFD"
      />
      <path
        d="M2.36669 11.9668C2.36669 11.5618 2.03837 11.2335 1.63336 11.2335C1.22835 11.2335 0.900024 11.5618 0.900024 11.9668V13.4335C0.900024 13.8385 1.22835 14.1668 1.63336 14.1668C2.03837 14.1668 2.36669 13.8385 2.36669 13.4335V11.9668Z"
        fill="#FCFCFD"
      />
      <path
        d="M8.23336 3.90013C8.23336 3.49512 7.90503 3.1668 7.50002 3.1668C7.09502 3.1668 6.76669 3.49512 6.76669 3.90013V5.3668C6.76669 5.77181 7.09502 6.10013 7.50002 6.10013C7.90503 6.10013 8.23336 5.77181 8.23336 5.3668V3.90013Z"
        fill="#FCFCFD"
      />
      <path
        d="M8.23336 13.4335C8.23336 13.0285 7.90503 12.7001 7.50002 12.7001C7.09502 12.7001 6.76669 13.0285 6.76669 13.4335V14.9001C6.76669 15.3051 7.09502 15.6335 7.50002 15.6335C7.90503 15.6335 8.23336 15.3051 8.23336 14.9001V13.4335Z"
        fill="#FCFCFD"
      />
      <path
        d="M13.3667 0.966797C13.7717 0.966797 14.1 1.29512 14.1 1.70013V3.1668C14.1 3.57181 13.7717 3.90013 13.3667 3.90013C12.9617 3.90013 12.6334 3.57181 12.6334 3.1668V1.70013C12.6334 1.29512 12.9617 0.966797 13.3667 0.966797Z"
        fill="#FCFCFD"
      />
      <path
        d="M13.3667 11.2335C13.7717 11.2335 14.1 11.5618 14.1 11.9668V13.4335C14.1 13.8385 13.7717 14.1668 13.3667 14.1668C12.9617 14.1668 12.6334 13.8385 12.6334 13.4335V11.9668C12.6334 11.5618 12.9617 11.2335 13.3667 11.2335Z"
        fill="#FCFCFD"
      />
      <path
        d="M0.166687 6.10052C0.166687 5.69551 0.495012 5.36719 0.90002 5.36719H2.36669C2.7717 5.36719 3.10002 5.69551 3.10002 6.10052V9.03385C3.10002 9.43886 2.7717 9.76719 2.36669 9.76719H0.90002C0.495012 9.76719 0.166687 9.43886 0.166687 9.03385V6.10052Z"
        fill="#FCFCFD"
      />
      <path
        d="M6.03335 8.30052C6.03335 7.89551 6.36168 7.56719 6.76669 7.56719H8.23335C8.63836 7.56719 8.96669 7.89551 8.96669 8.30052V10.5005C8.96669 10.9055 8.63836 11.2339 8.23335 11.2339H6.76669C6.36168 11.2339 6.03335 10.9055 6.03335 10.5005V8.30052Z"
        fill="#FCFCFD"
      />
      <path
        d="M12.6334 5.36719C12.2283 5.36719 11.9 5.69551 11.9 6.10052V9.03385C11.9 9.43886 12.2283 9.76719 12.6334 9.76719H14.1C14.505 9.76719 14.8334 9.43886 14.8334 9.03385V6.10052C14.8334 5.69551 14.505 5.36719 14.1 5.36719H12.6334Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
  Stocks: (
    <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.900024 2.89922C0.900024 1.68419 1.885 0.699219 3.10002 0.699219H14.8334C16.0484 0.699219 17.0334 1.68419 17.0334 2.89922V11.6992C17.0334 12.9142 16.0484 13.8992 14.8334 13.8992H3.10002C1.885 13.8992 0.900024 12.9142 0.900024 11.6992V2.89922ZM3.83336 9.49922C3.83336 9.09421 4.16168 8.76589 4.56669 8.76589C4.9717 8.76589 5.30002 9.09421 5.30002 9.49922V10.9659C5.30002 11.3709 4.9717 11.6992 4.56669 11.6992C4.16168 11.6992 3.83336 11.3709 3.83336 10.9659V9.49922ZM7.50002 4.36589C7.09502 4.36589 6.76669 4.69421 6.76669 5.09922V10.9659C6.76669 11.3709 7.09502 11.6992 7.50002 11.6992C7.90503 11.6992 8.23336 11.3709 8.23336 10.9659V5.09922C8.23336 4.69421 7.90503 4.36589 7.50002 4.36589ZM9.70002 6.56589C9.70002 6.16088 10.0283 5.83255 10.4334 5.83255C10.8384 5.83255 11.1667 6.16088 11.1667 6.56589V10.9659C11.1667 11.3709 10.8384 11.6992 10.4334 11.6992C10.0283 11.6992 9.70002 11.3709 9.70002 10.9659V6.56589ZM13.3667 2.89922C12.9617 2.89922 12.6334 3.22754 12.6334 3.63255V10.9659C12.6334 11.3709 12.9617 11.6992 13.3667 11.6992C13.7717 11.6992 14.1 11.3709 14.1 10.9659V3.63255C14.1 3.22754 13.7717 2.89922 13.3667 2.89922Z"
        fill="#FCFCFD"
      />
    </svg>
  ),
};

const SquareWrapper = ({ name, animate, show, index, type }) => {
  // DIFFERENT SQUARE FOR "MUTUAL FUNDS"
  if (name === "Mutual Funds") {
    return (
      <div
        className="h-[88px] w-[87px] lg:h-[112px] lg:w-[111px] rounded-[12px] dse-banner-border dse-banner-border-pink"
        style={{ opacity: 1 }}
      >
        <div className="w-full h-full rounded-[12px] bg-[#06071B] flex flex-col justify-center items-center">
          <div className="space-y-2 xl:space-y-4">
            <div className="w-[18px] mx-auto">{icons[name]}</div>
            <div className="font-inter font-regular text-[10px] lg:text-xs">
              {name}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // REMAINING ANIMATED SQUARES
  return (
    <div className="h-[88px] w-[87px] lg:h-[112px] rounded-[12px] lg:w-[111px] bg-[#06071B] relative">
      {/* MESH BOX ON MESH */}
      <div
        className={`absolute left-0 right-0 top-0 bottom-0 w-full h-full dse-banner-border${
          show ? ` dse-banner-border-${type}` : ""
        }${animate ? " dse-banner-border-show" : ""}`}
      >
        <div className="w-full h-full rounded-[12px] bg-[#06071B]" />
      </div>

      <ShadowType
        className={`rounded-[12px] dse-banner-mesh dse-banner-mesh-${index}${
          animate ? " dse-banner-mesh-show" : ""
        }`}
        type={type}
      >
        <div className="w-full h-full rounded-[12px] bg-[#06071B]">
          <div className="w-full h-full space-y-2 xl:space-y-3 flex flex-col justify-center items-center">
            <div className="w-[18px] mx-auto">{icons[name]}</div>
            <div className="font-inter font-regular text-[10px] lg:text-xs">
              {name}
            </div>
          </div>
        </div>
      </ShadowType>
    </div>
  );
};

SquareWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  animate: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

let animateTimer = null;
const Banner = () => {
  const [animate, setAnimate] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (animateTimer) clearTimeout(animateTimer);

    if (!animate) {
      animateTimer = setTimeout(() => {
        setShow(!animate);
      }, 800);
      return;
    }

    animateTimer = setTimeout(() => {
      setShow(!animate);
    }, 800);
  }, [animate]);

  const onAnimate = ({ target }) => {
    const { checked } = target;
    setAnimate(checked);
  };

  return (
    <div className="px-[20px] md:px-[30px] xl:px-[80px] xl:m-auto xl:max-w-[1480px] md:flex md:justify-between md:items-center relative xl:h-[580px]">
      {/* BANNER ABOUT */}
      <div className="text-white md:max-w-[340px] xl:max-w-[570px] pt-[40px] md:pt-0 lg:max-w-[450px] md:ml-[4%] xl:ml-0 z-50">
        {/* INVEST LANE BUTTON */}
        {/* <ShadowType
          className="p-[1px] rounded-[4px] lg:rounded-[8px] w-fit"
          type="orange"
        >
          <div className="bg-[#06071B] rounded-[4px] lg:rounded-[8px] py-[5px] pr-[16px] flex space-x-[10px] h-[32px] lg:h-[40px]">
            <div className="overflow-hidden max-h-[40px] max-w-[40px] flex justify-center items-center">
              <img
                src={InvestlaneLogo}
                alt="invest-lane-logo"
                className="max-h-[80px] max-w-[80px] min-h-[80px] min-w-[80px]"
              />
            </div>
            <span className="font-montserrat font-medium leading-[21px] text-sm lg:text-xl">
              InvestLane
            </span>
          </div>
        </ShadowType> */}

        {/* BANNER TITLE */}
        <div className="font-montserrat font-bold text-[24px] xl:whitespace-nowrap leading-[36px] mt-[32px] lg:leading-[40px] lg:text-[30px] xl:leading-[54px] xl:text-[36px] z-[59] relative">
          Leave No Gaps in Clients' Portfolio
        </div>

        {/* BANNER DESCRIPTION */}
        <div className="leading-[21px] font-montserrat font-medium text-sm mt-[16px] text-[#B1B5C4] lg:leading-[24px] lg:text-[16px] xl:leading-[30px] xl:text-[20px] z-[59] relative">
          Grow and scale your wealth business by enhancing your capabilities
          with our client acquisition tools.
        </div>
        <button
          className="bg-primaryblue-200 flex items-center py-3 px-6 rounded-[90px] mt-8 z-[59] relative"
          onClick={redirectToWhatsAppGroup}
        >
          <p className="font-montserrat text-[#FCFCFD] font-bold">
            Join our community
          </p>
          <img src={Arrow} alt="err" className="ml-4" />
        </button>
      </div>

      {/* PURPLE BG */}
      <div className="absolute top-[100px] md:top-0 bottom-0 left-0 right-0 w-full h-fit z-20">
        <GradientBG className="h-[350px] w-[400px] lg:h-[450px] lg:w-[600px] flex flex-col justify-center items-center rounded-full m-auto" />
      </div>

      {/* MESH UI */}
      <div className="relative h-[440px] lg:h-[520px] overflow-hidden xl:ml-[74px] z-50">
        {/* BACKGROUND MESH */}
        <div className="flex justify-center">
          <div className="overflow-hidden max-w-screen flex justify-center -mx-[20px]">
            <img
              src={Mesh}
              alt="banner-mesh"
              className="min-w-[575px] min-h-[440px] max-w-[575px] max-h-[440px] ml-[14px] lg:min-w-[748px] lg:min-h-[564px] lg:ml-[20px] lg:mt-[6px]"
            />
          </div>
        </div>

        {/* MESH CARDS */}
        {/* <div className="absolute top-0 w-full text-white flex justify-center items-center h-full">
          <div className="flex flex-col justify-between items-center min-h-[308px] lg:min-h-[393px]"> */}
        {/* MESH TOP ROW */}
        {/* <div className="flex justify-between h-content min-w-[302px] lg:w-[391px]">
              <SquareWrapper
                show={show}
                name="OffShore"
                animate={animate}
                index="one"
                type="blue"
              />
              <SquareWrapper
                show={show}
                name="Startup"
                animate={animate}
                index="two"
                type="blue"
              />
              <SquareWrapper
                show={show}
                name="AIF's"
                animate={animate}
                index="three"
                type="blue"
              />
            </div> */}

        {/* MESH MIDDLE ROW */}
        {/* <div className="flex justify-between h-content min-w-[302px] lg:w-[391px]">
              <SquareWrapper
                show={show}
                name="Bonds"
                animate={animate}
                index="four"
                type="pink"
              />
              <SquareWrapper
                show={show}
                name="Mutual Funds"
                animate={animate}
                index="five"
                type="pink"
              />
              <SquareWrapper
                show={show}
                name="PMS"
                animate={animate}
                index="six"
                type="pink"
              />
            </div> */}

        {/* MESH BOTTOM ROW */}
        {/* <div className="flex justify-between h-content min-w-[302px] lg:w-[391px]">
              <SquareWrapper
                show={show}
                name="Pre-IPO"
                animate={animate}
                index="seven"
                type="orange"
              />
              <SquareWrapper
                show={show}
                name="MLDs"
                animate={animate}
                index="eight"
                type="orange"
              />
              <SquareWrapper
                show={show}
                name="Stocks"
                animate={animate}
                index="nine"
                type="orange"
              />
            </div> 
          </div> */}

        {/* TOGGLE MESH ANIMATION SWITCH */}
        {/* <div className="absolute bottom-0 flex justify-center text-white items-center space-x-[16px]">
            <div className="font-inter font-regular text-[10px] lg:text-base">Fill the gaps</div>
            <span className="lg:hidden">
              <AppSwitch
                checked={animate}
                size="small"
                onChange={onAnimate}
              />
            </span>
            <span className="hidden lg:block">
              <AppSwitch
                checked={animate}
                onChange={onAnimate}
              />
            </span>
          </div> 
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
