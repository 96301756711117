import React, { useEffect } from "react";
import Footer from "../footer";
import HeroSection from "./HeroSection";
import Featured from "../features/Featured";
import MeetTheTeam from "./MeetTheTeam";
import Header from "../header/Header";
import Investment from "./Investment";
export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <HeroSection />
      <Featured />
      <Investment/>
      <MeetTheTeam />
      <Footer />
    </>
  );
}
