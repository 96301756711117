import React from "react";
// import LeaderBanner from "../assets/img/Leader.svg";
import VisibilitySensor from "react-visibility-sensor";
import ActiveStoryteller from "../../assets/image/icons/active_storyteller.svg";
import Medal from "../../assets/image/icons/Medal.svg";
import Goal_Based_Sip from "../../assets/image/illustrations/Goal_Based_Sip.png";

export default function Leader({ setBg, bg = [] }) {
  return (
    <div className="grid grid-cols-1 mt-36 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8"
              style={{
                // background: bg[1],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[1]?.length ? Medal : ActiveStoryteller} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[1]?.length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              SIP Proposals
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Explaining the need for planning for future goals like retirement,
              education as well as wealth creation through a compelling
              presentation and leave your clients impressed with your
              professional approach.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] ml-[16px]"
              style={{
                // background: bg[1],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[1]?.length ? Medal : Medal} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[1]?.length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              SIP Proposals
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Explaining the need for planning for future goals like retirement,
              education as well as wealth creation through a compelling
              presentation and leave your clients impressed with your
              professional approach.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../image/illustrations/red_mesh.svg')] bg-cover mt-20 flex justify-center items-center bg-center">
        <img src={Goal_Based_Sip} alt="err" />
      </div>
    </div>
  );
}
