import { Helmet } from "react-helmet";
import About from "../../components/resources/index";
const ResourcePage = () => (
  <>
    <Helmet />
    <About />
  </>
);

export default ResourcePage;
// Resource.displayName = "Resource Page";
