import React from "react";
import Logo from "../../assets/image/logo/BeyondIRR_ WhiteIcon.png";
import Twitter from "../../assets/image/icons/twitter_icon.svg";
import Linkedin from "../../assets/image/icons/linkedin_icon.svg";
import Facebook from "../../assets/image/icons/facebook_icon.svg";
import Instagram from "../../assets/image/icons/instagram_icon.svg";
import Medium from "../../assets/image/icons/medium_icon.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { styled } from "@mui/material";

const LogoElement = styled("div")(({ theme }) => ({
  flex: "0 0 100%",
  [theme.breakpoints.up(theme.breakpoints.app.values.lg)]: {
    flex: "0 0 30%",
  },
}));

const LinkElement = styled("div")(({ theme }) => ({
  flex: "0 0 45%",
  [theme.breakpoints.up(theme.breakpoints.app.values.sm)]: {
    flex: "0 0 33%",
  },
  [theme.breakpoints.up(theme.breakpoints.app.values.md)]: {
    flex: "0 0 25%",
  },
  [theme.breakpoints.up(theme.breakpoints.app.values.lg)]: {
    flex: "0 0 17.5%",
  },
}));

export default function LinksSection() {
  const footer = [
    {
      title: "Company",
      colspan: 2,
      properties: [
        {
          name: "About us",
          link: "/about#aboutusherosection",
          hashlink: true,
          offset: -300,
        },
        { name: "Careers", link: "/careers" },
        { name: "Blogs", link: "/resources" },
        // {
        //   name: "News",
        //   link: "/home#featuredon",
        //   hashlink: true,
        //   offset: -300,
        // },
        { name: "Media Kit", link: "/mediakit" },
      ],
    },
    // {
    //   title: "Resources",
    //   colspan: 1,
    //   properties: [
    //     {
    //       name: "Blog",
    //       link: "/blog",
    //       hashlink: true,
    //       offset: -120,
    //     },
    //     { name: "Newsletter", link: "/newsletter" },
    //     { name: "Events", link: "/events" },
    //     { name: "Help centre", link: "/helpcentre" },
    //     { name: "Tutorials", link: "/tutorials" },
    //     { name: "Support", link: "/support", hashlink: true, offset: -120 },
    //   ],
    // },
    {
      title: "Legal",
      colspan: 2,
      properties: [
        { name: "Terms", link: "/terms" },
        { name: "Privacy", link: "/privacy" },
        // { name: "Cookies", link: "/cookies" },
        // { name: "Licenses", link: "/licenses" },
        // { name: "Settings", link: "/settings" },
        { name: "Contact", link: "/contactus" },
      ],
    },
  ];

  const impLinks = [
    {
      title: "twitter",
      source: Twitter,
      link: "https://twitter.com/BeyondIRR_HQ",
    },
    {
      title: "linkedin",
      source: Linkedin,
      link: "https://www.linkedin.com/company/beyondirr/mycompany/",
    },
    {
      title: "facebook",
      source: Facebook,
      link: "https://www.facebook.com/beyondirr",
    },
    {
      title: "instagram",
      source: Instagram,
      link: "https://www.instagram.com/beyond_irr/",
    },
    {
      title: "medium",
      source: Medium,
      link: "https://medium.com/@birr_hq",
    },
  ];
  const scrollWithOffset = (el, offset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = offset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="w-full  bg-primarybg-100 text-[#EAECF0] pb-4 px-[16px] md:px-20">
      <div className="py-12 md:py-16 flex flex-wrap border-b border-[#475468]">
        <LogoElement>
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="w-[37%] object-contain mb-4 sm:m-0 sm:mb-4"
            />
          </Link>

          <p className="font-inter font-medium">
            Enabling the Wealth Businesses Grow and Scale
          </p>
        </LogoElement>
        {footer.map(({ title, properties, colspan }, index) => {
          return (
            <LinkElement key={index} className="lg:flex lg:justify-center">
              <div className={`flex flex-col my-8 lg:my-0 space-y-[12px]`}>
                <p className=" font-poppins font-medium font text-sm text-[#FCFCFD]">
                  {title}
                </p>
                {properties.map(({ name, link, hashlink, offset }) =>
                  hashlink ? (
                    <HashLink
                      key={name}
                      to={link}
                      smooth
                      scroll={(el) => scrollWithOffset(el, offset)}
                      className="font-poppins font-medium text-base cursor-pointer text-[#98A2B3] hover:text-[#FCFCF4] active:text-[#FCFCF4] decoration-[#FCFCF4]"
                    >
                      <p
                        className="font-poppins font-medium text-base cursor-pointer"
                        key={name}
                      >
                        {name}
                      </p>
                    </HashLink>
                  ) : name !== "Contact Us On" ? (
                    <Link
                      key={name}
                      to={link}
                      className="font-poppins font-medium text-base cursor-pointer text-[#98A2B3] hover:text-[#FCFCF4] active:text-[#FCFCF4] decoration-[#FCFCF4]"
                    >
                      <p
                        className="font-poppins font-medium text-base cursor-pointer"
                        key={name}
                      >
                        {name}
                      </p>
                    </Link>
                  ) : (
                    <div
                      key={index}
                      className="font-poppins font-medium text-base text-[#98A2B3]"
                    >
                      <p className=" mb-2" key={name}>
                        {name}
                      </p>
                      {link}
                    </div>
                  )
                )}
              </div>
            </LinkElement>
          );
        })}
      </div>
      <div className="flex flex-col-reverse md:flex-row md:justify-between py-8">
        <p className="font-poppins text-[#98A2B3] text-base mt-[26px] md:mt-0 mb-0">
          &copy; 2024 Valiant Fintech Private Limited. All rights reserved. CIN
          - U67100MH2021PTC372297
        </p>
        <div className="flex space-x-5 md:justify-between">
          {impLinks.map((item) => (
            <a
              key={item.link}
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                key={item.title}
                src={item.source}
                alt={item.title}
                className="cursor-pointer"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
