import { Helmet } from "react-helmet";
import InvestGPT from "../../components/investgpt/index";
const InvesGPTPage = () => (
  <>
    <Helmet />
    <InvestGPT />
  </>
);

export default InvesGPTPage;
InvesGPTPage.displayName = "InvestGPT";
