import React, { useEffect, useState, useRef } from "react";

import MarketGPT from "./MarketGPT";
import PortfolioGPT from "./PortfolioGPT";
import ProductGPT from "./ProductGPT";

// import Marketicon from "../../assets/image/icons/marketicon";
// import Productgpticon from "../../assets/image/icons/productgpticon";
import Portfolioicon from "../../assets/image/icons/portfolioicon";

import Investmenticon from "../../assets/image/icons/Investmenticon";
import MarketsEconomy from "../../assets/image/icons/Market_Economy";

// import Investment from "../../assets/image/icons/Investment Products.svg";
// import Markets_Economy from "../../assets/image/icons/Markets & Economy.svg";
// import Client_Portfolio from "../../assets/image/icons/Client Portfolio & Meetings.svg";

export default function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const intervalRef = useRef(null);

  const slides = [
    {
      name: "Markets & Economy",
      component: <MarketGPT />,
      icon: <MarketsEconomy />,
      "active-icon": <MarketsEconomy color={true} />,
    },
    {
      name: "Investment Products",
      component: <ProductGPT />,
      icon: <Investmenticon />,
      "active-icon": <Investmenticon color={true} />,
    },
    {
      name: "Client Portfolio & Meetings",
      component: <PortfolioGPT />,
      icon: <Portfolioicon />,
      "active-icon": <Portfolioicon color={true} />,
    },
  ];
  function handleTabClick(slideNumber) {
    setCurrentSlide(slideNumber);
    startAutoChangeInterval();
  }

  const handleHover = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isDragging) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }
    }, 10000);
  };

  const handleActionStart = (clientX) => {
    setStartX(clientX);
    setIsDragging(true);
  };

  const handleActionMove = (clientX) => {
    if (isDragging) {
      const deltaX = clientX - startX;
      const sensitivity = 50;

      if (deltaX > sensitivity || deltaX < -sensitivity) {
        setCurrentSlide(
          (prevSlide) =>
            (prevSlide - Math.sign(deltaX) + slides.length) % slides.length
        );
        setIsDragging(false);
      }
    }
  };

  const handleActionEnd = () => {
    setIsDragging(false);
  };
  const handleMouseDown = (event) => {
    handleActionStart(event.clientX);
  };
  const handleMouseMove = (event) => {
    handleActionMove(event.clientX);
  };
  const handleTouchStart = (event) => {
    handleHover();
    handleActionStart(event.touches[0].clientX);
  };
  const handleTouchMove = (event) => {
    handleActionMove(event.touches[0].clientX);
  };

  const startAutoChangeInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isDragging) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }
    }, 5000);
  };

  useEffect(() => {
    startAutoChangeInterval();
    return () => {
      clearInterval(intervalRef.current);
    };
    //eslint-disable-next-line
  }, [currentSlide, slides.length]);

  return (
    <>
      <div className="flex w-full text-white gap-4 md:gap-0 lg:px-20 items-center justify-around pt-24">
        {slides.map((slide, index) => {
          return (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`flex py-2 md:w-64 cursor-pointer space-x-1 md:space-x-2 justify-center text-sm md:text-lg items-center ${
                currentSlide === index
                  ? "carousel-tab-active text-white"
                  : "text-[#777E90]"
              }`}
            >
              {currentSlide === index ? slide["active-icon"] : slide["icon"]}
              <span className="text-xs md:text-sm font-montserrat leading-[20px]">
                {slide["name"]}
              </span>
            </div>
          );
        })}
      </div>
      <div className="px-5 md:px-20 mx-auto">
        <div className="hidden md:block bg-[#51515f40] h-[2px]" />
      </div>
      <div
        className="pt-10 md:pt-24"
        onMouseEnter={handleHover}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleActionEnd}
        // onMouseLeave={startAutoChangeInterval}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleActionEnd}
      >
        <div className="flex overflow-hidden relative h-full gap-2">
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-fit flex justify-center items-center shrink-0 relative h-full"
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {slide.component}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
