import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer";
import Copy from "../../assets/image/illustrations/copy_link_button.svg";
import Twitter from "../../assets/image/logo/twitter_blog_logo.svg";
import Linkedin from "../../assets/image/logo/in_blog_logo.svg";
import Facebook from "../../assets/image/logo/fb_blog_logo.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import HelmetMetaData from "./HelmetMetaData";
export default function Blog() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const [data, setData] = useState({ content: "", tag: [] });
  const [date, setDate] = useState([]);
  useEffect(() => {
    var imgTags = document
      .getElementById("blog-content")
      .querySelectorAll("img");
    for (var i = 0; i < imgTags.length; i++)
      imgTags[i].style.backgroundColor = "100%";
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_blogURL, {
        params: {
          id: id,
        },
      })
      .then(function (response) {
        setData(response.data[0]);
        setDate(response.data[0].pub_date.split("-"));
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div>
      <HelmetMetaData
        currentUrl={window.location.href}
        title={data.title}
        description={data.brief_text}
        image={data.image}
      />
      <Header />
      <div className="bg-primarybg-300 sm:px-20 sm:py-24 px-5 py-20">
        <p className="text-[#3B71FE] font-montserrat font-semibold text-sm leading-[24px] text-center">
          {date[2] &&
            `Published on ${date[2]} ${months[parseInt(date[1]) - 1]} ${
              date[0]
            }`}
        </p>
        <h1 className="text-center font-semibold text-4xl text-montserrat text-[#FCFCFD] mt-3">
          {data.title}
        </h1>
        {/* <p className="text-[#9B9CA4] leading-[24px] font-montserrat text-sm font-normal text-center mt-2">
          {data.subtitle}
        </p> */}
        <div className="w-full flex justify-center mt-4">
          {data.tag.map((tag, index) => (
            <p
              key={tag}
              className={
                index
                  ? "rounded-2xl py-1 px-2 bg-primarybg-100 ml-2 text-xs leading-[18px] font-inter font-medium text-[#777E91]"
                  : "rounded-2xl py-1 px-2 bg-primarybg-100 text-xs leading-[18px] font-inter font-medium text-[#777E91]"
              }
            >
              {tag}
            </p>
          ))}
        </div>
        <img
          src={data.image}
          alt="err"
          className="mt-[34px] w-full sm:h-[500px] h-[240px]"
        />
        <div className="w-full mt-16 flex flex-col items-center">
          <div className="sm:w-[60%] w-[100%]">
            <p
              className="font-inter sm:text-xl text-[#B1B5C4] sm:leading-[30px] pb-8 text-sm leading-[21px] text-justify"
              style={{ borderBottom: "1px solid #353945" }}
            >
              {data.brief_text}
            </p>
            <div
              className="font-inter sm:text-xl text-[#B1B5C4] sm:leading-[30px] pb-8 divide-y-reverse divide-[#353945] mt-8 text-sm leading-[21px] text-justify"
              id="blog-content"
            >
              {parse(data.content)}
            </div>
            <div className="pt-8 border-t border-t-[##EAECF0] sm:flex justify-between block">
              <div className="flex items-center">
                <img
                  src={data.author_image}
                  alt="err"
                  className="w-14 h-14 rounded-full"
                />
                <div className="ml-3">
                  <p className="font-montserrat text-[#FCFCFD] text-sm font-medium">
                    {data.author}
                  </p>
                  <p className="font-montserrat text-[#9B9CA4] text-sm font-medium">
                    {data.author_post}
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-8 sm:mt-0">
                <CopyToClipboard text={window.location.href}>
                  <button className="active:opacity-50">
                    <img
                      src={Copy}
                      alt="err"
                      className="h-[35px] cursor-pointer"
                    />
                  </button>
                </CopyToClipboard>
                <TwitterShareButton
                  url={window.location.href}
                  quote={"BeyondIRR - The future of wealth management!"}
                  hashtag="#beyondirr"
                >
                  <img src={Twitter} alt="err" className="h-[35px] ml-3" />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  quote={"BeyondIRR - The future of wealth management!"}
                  hashtag="#beyondirr"
                >
                  <img src={Linkedin} alt="err" className="h-[35px] ml-3" />
                </LinkedinShareButton>
                <FacebookShareButton
                  url={window.location.href}
                  quote={"BeyondIRR - The future of wealth management!"}
                  hashtag="#beyondirr"
                >
                  <img src={Facebook} alt="err" className="h-[35px] ml-3" />
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
