import Router from "./Router";
import { ThemeProvider } from "@mui/material";
import theme from "./assets/css";
import "./assets/css/styles.css";
import "./assets/css/banner.css";

//  ATTENTION:  The below if-condition Will set console log to empty function to
// avoid consoling in other environments except development. So, please remove
// this once every console is removed from the app because this only acts as a
// patch work and should be avoided

const App = () => (
  <ThemeProvider theme={theme}>
    <Router />
  </ThemeProvider>
);

export default App;
