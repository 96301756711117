import { Helmet } from "react-helmet";
import Investlane from "../../components/investlane/index";
const InveslanePage = () => (
  <>
    <Helmet />
    <Investlane />
  </>
);

export default InveslanePage;
InveslanePage.displayName = "Investlane";
