import React from "react";
import PortGPTIcon from '../../assets/image/icons/portfoliogpt.svg'
import PortGPTIllustration from '../../assets/image/illustrations/portfoliogptimg.svg'

export default function PortfolioGPT() {
    return (
        <div className="flex items-center md:items-start flex-col md:flex-row md:px-20 bg-primarybg-300">
            <div className="flex flex-col items-start w-full">
                <img src={PortGPTIcon} alt="err" className="mt-8" />
                <p className="font-montserrat font-medium md:text-xl md:leading-[30px] text-[#B1B5C4] pt-10 z-30 whitespace-break-spaces">
                    Provide actionable insights on client portfolios, integrating market trends and investment strategies, empowering your team to offer informed guidance tailored to individual client needs.
                </p>
            </div>
            <div className="relative w-full h-full bg-[url('../image/illustrations/mobile_mesh.svg')] bg-cover bg-center">
                <img src={PortGPTIllustration} className="object-cover m-auto" alt="err"/>
            </div>
            {/* <img src={PortGPTIllustration} alt="err" className="w-full h-full mt-8 md:mt-0 md:scale-100 scale-[1.1]" /> */}
        </div>
    )
}