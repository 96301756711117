import React from "react";
export function EssentialPlan() {
  const gridData = [
    {
      heading: "Comprehensive MF portfolio Insights",
      content: [
        "Access to CAMS CAS portfolio Refresh for thorough insights into client's Mutual Fund portfolio",
        "Access to 75 MF Central Portfolio Refresh for a holistic view of client investments",
      ],
    },
    {
      heading: "Model Portfolio Management",
      content: [
        "Easily curate and manage model portfolios within the system, simplifying proposal presentations",
      ],
    },
    {
      heading: "Instant Proposal presentations",
      content: [
        "Generic instant lump sum proposal presentations for client, facilitating quick decision-making",
      ],
    },
    {
      heading: "Customized Research Views",
      content: [
        "Tailor research views for different asset types, sectors, and investment styles, enhancing decision-making capabilities",
      ],
    },
    {
      heading: "Goal-Based SIP Proposals",
      content: [
        "Craft goal-based proposal presentations tailored to client's specific financial objectives",
      ],
    },
    {
      heading: "White-label Branding",
      content: [
        "Enjoy a white-labeled platform with branding customized to your business, ensuring a seamless client experience",
      ],
    },
    {
      heading: "Personalised Recommmendations",
      content: [
        "Establish a personalised product Recommmendation's list to enhance client presentations and recommendations",
      ],
    },
    {
      heading: "Custom Domain Access",
      content: [
        "Access custom domains for client logins, reinforcing your brand identity and professionalism",
      ],
    },
  ];
  return (
    <>
      <div className="bg-primarybg-300 px-2 md:px-10">
        <div className="text-white text-center font-bold text-xl md:text-2xl mb-8">
          What's Included in the Essential Plan?
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
          {gridData.map((item, index) => (
            <div key={index} className="p-4">
              <div className="w-full flex justify-between items-center pb-4 space-x-4">
                <p className="w-5 h-5 rounded-full bg-[#FCFCFD] font-montserrat font-semibold text-xs text-center pt-[2px]">
                  {index + 1}
                </p>
                <p className="flex-1 font-montserrat text-white font-semibold text-sm">
                  {item.heading}
                </p>
              </div>
              <ul className="ml-8 space-y-2 marker:text-white list-outside list-disc marker:mr-[8px]">
                {item.content.map((point, idx) => (
                  <li key={idx} className="text-sm ml-4 text-[#B1B5C4]">
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
