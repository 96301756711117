import React, { useState } from "react";

import Arrow from "../../assets/image/icons/right_arrow.svg";
import VisibilitySensor from "react-visibility-sensor";
import RequestModal from "../modal/RequestModal";

export default function HeroSection({ setBackground }) {
  const [open, setOpen] = useState(false);
  return (
    <div
      id="investedge-homescreen-herosection"
      className="bg-primarybg-300 relative pt-18 md:pt-0 flex justify-center min-h-[650px]"
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div className="h-[450px] sm:h-[660px] sm:flex sm:flex-col sm:items-center z-30 pt-[44px] sm:pt-44">
        <VisibilitySensor
          onChange={(isVisible) =>
            isVisible
              ? setBackground("transparent")
              : setBackground("rgba(12, 4, 24, 0.85)")
          }
        ></VisibilitySensor>

        <p className="font-montserrat text-3xl font-semibold sm:text-5xl leading-[54px] md:leading-[60px] sm:text-center text-white">
          Get Talking Points for Clients Meetings
        </p>
        <p className="font-montserrat font-medium text-sm sm:text-xl leading-[21px] sm:leading-[30px] sm:text-center text-[#B1B5C4] mt-6">
          Prepare yourself well for client meeting in matter of minutes; engage
          with confidence, deliver quality insights, impress client and gain
          more wallet share.
        </p>
        <button
          className="bg-primaryblue-200 flex items-center py-4 px-6 mt-10 rounded-[90px] cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <p className="font-dmsans text-[#FCFCFD] font-bold">
            Connect With Us
          </p>
          <img src={Arrow} alt="err" className="ml-4" />
        </button>
      </div>
      <RequestModal open={open} setOpen={setOpen} />
    </div>
  );
}
