import React from "react";
// import Investedge from "../../assets/image/logo/investedge.svg";
import Intelligence from "../../assets/image/icons/intelligence_driven.svg";
import Product from "../../assets/image/icons/product_stories.svg";
import Portfolio from "../../assets/image/icons/portfolio_stories.svg";
// import Arrow from "../../assets/image/icons/right_arrow.svg";
// import VisibilitySensor from "react-visibility-sensor";
export default function HeroSection({ setBackground }) {
  return (
    <div
      id="investedge-homescreen-herosection"
      className="bg-primarybg-300 relative pt-18 md:pt-0 flex justify-center "
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div className="h-[450px] sm:h-[660px] md:h-[550px] sm:flex sm:flex-col sm:items-center px-[20px] sm:px-0 z-30 pt-[44px] sm:pt-44">
        {/* <VisibilitySensor
          onChange={(isVisible) =>
            isVisible
              ? setBackground("transparent")
              : setBackground("rgba(12, 4, 24, 0.85)")
          }
        >
          <img
            src={Investedge}
            alt="err"
            className="mb-[32px] sm:mb-16 h-[30px] sm:h-[auto]"
          />
        </VisibilitySensor> */}

        <p className="font-montserrat font-bold text-3xl sm:text-5xl leading-[36px] sm:leading-[54px] sm:text-center text-white">
          Convert Prospects into Clients - Faster
        </p>
        <div className="hidden md:grid grid-cols-3 gap-8 mt-16">
          <div className="flex flex-col items-center">
            <img src={Intelligence} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Intelligent Insights
            </p>
            <p className="text-[#787E92] font-sm font-montserrat text-base leading-[30px]">
              On Client Portfolio
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Product} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Institutional Grade
            </p>
            <p className="text-[#787E92] font-sm font-montserrat text-base leading-[30px]">
              Portfolio Proposals
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Portfolio} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Hot Lead Generation
            </p>
            <p className="text-[#787E92] font-sm font-montserrat text-center text-base leading-[30px]">
              Through Your Website/Social Media
            </p>
          </div>
        </div>
        {/* <div className="flex justify-center space-x-4 mt-20 ">
          <button className=" flex items-center py-4 px-6 rounded-[90px] cursor-pointer border border-white">
            <p className="font-dmsans text-[#FCFCFD] font-bold">
              Request a Demo
            </p>
          </button>
          <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
            <p className="font-dmsans text-[#FCFCFD] font-bold">
              Become a Member
            </p>
            <img src={Arrow} alt="err" className="ml-4" />
          </button>
        </div> */}

        <div className="md:hidden grid grid-cols-1 gap-8 mt-12">
          <div className="flex items-center">
            <img src={Intelligence} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Intelligent Insights
              </p>
              <p className="text-[#FCFCFD] font-sm font-montserrat text-sm leading-[30px] mb-0">
                On Client Portfolio
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <img src={Product} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Institutional Grade
              </p>
              <p className="text-[#FCFCFD] font-sm font-montserrat text-sm leading-[30px] mb-0">
                Portfolio Proposals
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <img src={Portfolio} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Generate Hot Leads
              </p>
              <p className="text-[#FCFCFD] font-sm font-montserrat text-sm leading-[30px] mb-0">
                Through your Website / Social Media
              </p>
            </div>
          </div>
          {/* <div className="flex justify-center space-x-4 mt-20 ">
            <button className=" flex items-center py-4 px-6 rounded-[90px] cursor-pointer border border-white">
              <p className="font-dmsans text-[#FCFCFD] font-bold">
                Request a Demo
              </p>
            </button>
            <button className="bg-primaryblue-200 flex items-center py-4 px-6 rounded-[90px] cursor-pointer">
              <p className="font-dmsans text-[#FCFCFD] font-bold">
                Become a Member
              </p>
              <img src={Arrow} alt="err" className="ml-4" />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
