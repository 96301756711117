// import React from "react";
// import DesignerBanner from "../../assets/image/illustrations/Designer.mp4";
// import ActiveDesigner from "../../assets/image/icons/active_designer.svg";
// import Navigation from "../../assets/image/icons/Navigation.svg";
// // import Briefcase from "../../assets/image/icons/breifcase.svg";
// import VisibilitySensor from "react-visibility-sensor";
// export default function LumpsumProposalPresentation({
//   setBg,
//   setBackground,
//   bg = [],
// }) {
//   return (
//     <div className="grid grid-cols-1 mt-40 md:grid-cols-2 md:px-20">
//       <div className="md:flex flex-col justify-center hidden">
//         <VisibilitySensor partialVisibility>
//           <>
//             <div
//               className="w-[18rem] flex pb-[16px] mb-8"
//               style={{
//                 background: bg[0],
//                 transitionProperty: "width,background",
//                 transitionDuration: "0.2s,0.2s",
//                 transitionTimingFunction: "linear",
//               }}
//             >
//               <img
//                 src={bg[0]?.length ? ActiveDesigner : Navigation}
//                 alt="err"
//               />
//               <p
//                 className="font-montserrat mb-0 ml-2 text-xl"
//                 style={
//                   bg[0]?.length
//                     ? { color: "#FCFCFD", fontWeight: 600 }
//                     : { color: "7777E91" }
//                 }
//               ></p>
//             </div>
//             <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
//               Lumpsum Proposals Presentations
//             </h2>
//             <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
//               Get over the old practices of sending investment proposals to your
//               clients on WhatsApp or plain email. Deliver institutional grade
//               presentation to your wealth clients and make them feel valued -
//               because they deserve it from you. {" "}
//             </p>
//           </>
//         </VisibilitySensor>
//       </div>
//       <div className="md:hidden flex flex-col justify-center">
//         <VisibilitySensor partialVisibility>
//           <>
//             <div
//               className="w-[15rem] flex pb-[16px] mt-[16px] ml-[16px]"
//               style={{
//                 background: bg[0],
//                 transitionProperty: "width,background",
//                 transitionDuration: "0.2s,0.2s",
//                 transitionTimingFunction: "linear",
//               }}
//             >
//               <img
//                 src={bg[0]?.length ? ActiveDesigner : Navigation}
//                 alt="err"
//               />
//               <p
//                 className="font-montserrat mb-0 ml-2"
//                 style={
//                   bg[0]?.length
//                     ? { color: "#FCFCFD", fontWeight: 600 }
//                     : { color: "7777E91" }
//                 }
//               ></p>
//             </div>
//             <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
//               Lumpsum Proposals Presentations
//             </h2>
//             <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
//               Get over the old practices of sending investment proposals to your
//               clients on WhatsApp or plain email. Deliver institutional grade
//               presentation to your wealth clients and make them feel valued -
//               because they deserve it from you. {" "}
//             </p>
//           </>
//         </VisibilitySensor>
//       </div>
//       <div className="bg-[url('../image/illustrations/red_mesh.svg')] bg-cover mt-10 flex justify-center items-center bg-center">
//         <video width="86%" loop autoPlay muted>
//           <source src={DesignerBanner} type="video/mp4" />
//           Your browser does not support HTML video.
//         </video>
//       </div>
//     </div>
//   );
// }

import React from "react";
import DesignerBanner from "../../assets/image/illustrations/Designer.mp4";
import ActiveDesigner from "../../assets/image/icons/active_designer.svg";
import Navigation from "../../assets/image/icons/Navigation.svg";
import VisibilitySensor from "react-visibility-sensor";
export default function LumpsumProposalPresentation({
  setBg,
  setBackground,
  bg,
}) {
  return (
    <div className="grid grid-cols-1 mt-40 md:grid-cols-2 md:px-20">
      <div className="md:flex flex-col justify-center hidden">
        <VisibilitySensor partialVisibility>
          <>
            <div
              className="w-[18rem] flex pb-[16px] mb-8"
              style={{
                // background: bg[0],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[0].length ? ActiveDesigner : Navigation} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[0].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Lumpsum Proposals Presentations
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Get over the old practices of sending investment proposals to your
              clients on WhatsApp or plain email. Deliver institutional grade
              presentation to your wealth clients and make them feel valued -
              because they deserve it from you. {" "}
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor partialVisibility>
          <>
            <div
              className="w-[15rem] flex pb-[16px] mt-[16px] ml-[16px]"
              style={{
                // background: bg[0],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[0].length ? ActiveDesigner : Navigation} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[0].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              Lumpsum Proposals Presentations
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Get over the old practices of sending investment proposals to your
              clients on WhatsApp or plain email. Deliver institutional grade
              presentation to your wealth clients and make them feel valued -
              because they deserve it from you. {" "}
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../image/illustrations/red_mesh.svg')] bg-cover mt-10 flex justify-center items-center bg-center">
        <video width="86%" loop autoPlay muted>
          <source src={DesignerBanner} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  );
}
