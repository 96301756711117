import React, { useState } from "react";
import HeroSection from "./HeroSection";
import Footer from "../footer";
import Header from "../header/Header";
// import LumpsumProposalPresentation from "./LumpsumProposalPresentation";
// import HotLeadGeneration from "./HotLeadGeneration";
// import ExternalPortfolioAssessment from "./ExternalPortfolioAssessment";
// import Leader from "./Leader";
import Buttons from "./Buttons";
import Features from "./Features";

export default function Home() {
  const [background, setBackground] = useState("transparent");
  // const [bg, setBg] = useState([
  //   "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
  //   "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
  //   "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
  //   "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
  // ]);
  return (
    <>
      <Header background={background} loginBtn={true} />
      <HeroSection setBackground={setBackground} />
      <Buttons />
      {/* <HotLeadGeneration setBg={setBg} bg={bg} />
      <ExternalPortfolioAssessment setBg={setBg} bg={bg} />
      <LumpsumProposalPresentation
        setBg={setBg}
        setBackground={setBackground}
        bg={bg}
      />
      <Leader setBg={setBg} bg={bg} /> */}
      <Features />
      {/* <Features setBackground={setBackground} /> */}
      <Footer />
    </>
  );
}
