import React from "react";

// import BussinessLine from "../../assets/image/logo/white_bussiness_line.png";
// import BussinessStandard from "../../assets/image/logo/white_bussiness_standard.png";
// import Mint from "../../assets/image/logo/white_mint.png";
// import YourStory from "../../assets/image/logo/white_your_story.png";

import Sbm_Private from "../../assets/image/logo/Sbm Private.png";
import Neptune_Wealth from "../../assets/image/logo/W.png";
import Polo from "../../assets/image/logo/Polo.png";
import Nw from "../../assets/image/logo/Nw.png";
import TrufId from "../../assets/image/logo/TrufId.png";
import Deep_Wealth from "../../assets/image/logo/Deep Wealth.png";

// import GreyBussinessLine from "../../assets/image/logo/grey_bussiness_line.png";
// import GreyBussinessStandard from "../../assets/image/logo/grey_bussiness_standard.png";
// import GreyMint from "../../assets/image/logo/grey_mint.png";
// import GreyYourStory from "../../assets/image/logo/grey_your_story.png";

export default function Featured() {
  // const [bussinessline, setbussinessline] = useState(GreyBussinessLine);
  // const [bussinessstandard, setbussinessstandard] = useState(
  //   GreyBussinessStandard
  // );
  // const [mint, setmint] = useState(GreyMint);
  // const [yourstory, setyourstory] = useState(GreyYourStory);

  return (
    <div className="bg-primarybg-300 py-5 px-5 w-full" id="featuredon">
      <p className="font-montserrat text-center font-normal text-[#FCFCFD] relative z-30 md:pt-0 pt-4">
        Trusted By
      </p>

      <div className="pt-6 flex flex-col sm:flex-row items-center justify-evenly w-full flex-wrap relative z-30 sm:space-x-8 ">
        {/* <a
          href="https://www.thehindubusinessline.com/companies/zerodhas-rainmatter-udaan-co-founder-invest-in-beyondirr/article65302979.ece"
          target="_blank"
          rel="noreferrer"
        > */}
        <img
          src={Polo}
          alt="Polo"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setbussinessline(BussinessLine)}
          // onMouseLeave={() => setbussinessline(GreyBussinessLine)}
        />
        {/* </a> */}
        {/* <a
          href="https://www.business-standard.com/article/companies/fintech-startup-beyondirr-raises-seed-funding-from-zerodha-s-rainmatter-122040800694_1.html"
          target="_blank"
          rel="noreferrer"
        > */}
        <img
          src={Deep_Wealth}
          alt="Deep Wealth"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setbussinessstandard(BussinessStandard)}
          // onMouseLeave={() => setbussinessstandard(GreyBussinessStandard)}
        />
        {/* </a> */}

        {/* <a
          href="https://www.livemint.com/companies/start-ups/beyondirr-raises-seed-funding-from-zerodha-s-rainmatter-sujeet-kumar-11649416212208.html"
          target="_blank"
          rel="noreferrer"
        > */}
        <img
          src={TrufId}
          alt="TrufId"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setmint(Mint)}
          // onMouseLeave={() => setmint(GreyMint)}
        />
        {/* </a> */}
        {/* <a
          href="https://yourstory.com/2022/04/funding-roundup-beyondirr-incruiter-valuationary/amp"
          target="_blank"
          rel="noreferrer"
        > */}
        <img
          src={Nw}
          alt="Nw"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setyourstory(YourStory)}
          // onMouseLeave={() => setyourstory(GreyYourStory)}
        />
        {/* </a> */}

        <img
          src={Neptune_Wealth}
          alt="Neptune Wealth"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setyourstory(YourStory)}
          // onMouseLeave={() => setyourstory(GreyYourStory)}
        />

        <img
          src={Sbm_Private}
          alt="Sbm Private"
          className="h-[50px] mt-8 sm:mt-4 object-contain"
          // onMouseEnter={() => setyourstory(YourStory)}
          // onMouseLeave={() => setyourstory(GreyYourStory)}
        />
      </div>

      {/* <div className="pt-6 sm:hidden justify-center w-full flex-wrap flex relative z-30 items-end">
        
        <img src={Polo} alt="Polo" className="grayscale hover:grayscale-0" />
       
        <img
          src={Deep_Wealth}
          alt="Deep Wealth"
          className="ml-6 grayscale hover:grayscale-0"
        />
        

       
        <img
          src={TrufId}
          alt="TrufId"
          className="mt-7 ml-5 grayscale hover:grayscale-0"
        />
       
        <img
          src={Nw}
          alt="Nw"
          className="mt-7 ml-6 grayscale hover:grayscale-0"
        />
       
        <img
          src={Neptune_Wealth}
          alt="Neptune Wealth"
          className="mt-7 ml-8 grayscale hover:grayscale-0"
        />
        <img
          src={Sbm_Private}
          alt="Sbm Private"
          className="mt-7 ml-6 grayscale hover:grayscale-0"
        />
      </div> */}

      {/* <div className="pt-6 sm:hidden justify-center flex flex-wrap relative z-30 items-end">
        <img
          src={Polo}
          alt="Polo"
          className="grayscale hover:grayscale-0 mt-5 flex-shrink-0"
          style={{ width: "50%" }}
        />
        <img
          src={Deep_Wealth}
          alt="Deep Wealth"
          className="ml-6 mt-5 grayscale hover:grayscale-0 flex-shrink-0"
          style={{ width: "50%" }}
        />
        <img
          src={TrufId}
          alt="TrufId"
          className="mt-7 ml-5 grayscale hover:grayscale-0 flex-shrink-0"
          style={{ width: "50%" }}
        />
        <img
          src={Nw}
          alt="Nw"
          className="mt-7 ml-6 grayscale hover:grayscale-0 flex-shrink-0"
          style={{ width: "50%" }}
        />
        <img
          src={Neptune_Wealth}
          alt="Neptune Wealth"
          className="mt-7 ml-8 grayscale hover:grayscale-0 flex-shrink-0"
          style={{ width: "50%" }}
        />
        <img
          src={Sbm_Private}
          alt="Sbm Private"
          className="mt-7 ml-6 grayscale hover:grayscale-0 flex-shrink-0"
          style={{ width: "50%" }}
        />
      </div> */}

      {/* <div className="pt-6 sm:hidden justify-center flex flex-wrap relative z-30 items-end">
        <img src={Polo} alt="Polo" className="h-[50px] mt-4 object-contain" />
        <img
          src={Deep_Wealth}
          alt="Deep Wealth"
          className="h-[50px] mt-4 object-contain"
        />
        <img
          src={TrufId}
          alt="TrufId"
          className="h-[50px] mt-4 object-contain"
        />
        <img src={Nw} alt="Nw" className="h-[50px] mt-4 object-contain" />
        <img
          src={Neptune_Wealth}
          alt="Neptune Wealth"
          className="h-[50px] mt-4 object-contain"
        />
        <img
          src={Sbm_Private}
          alt="Sbm Private"
          className="h-[50px] mt-4 object-contain"
        />
      </div> */}
    </div>
  );
}
