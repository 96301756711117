import { Helmet } from "react-helmet";
import About from "../../components/about/index";
const AboutPage = () => (
  <>
    <Helmet />
    <About />
  </>
);

export default AboutPage;
AboutPage.displayName = "About Page";
