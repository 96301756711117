import React from "react";
import Arrow from "../../assets/image/icons/modal_dropdown_arrow.svg";
import { useState } from "react";
import { usePopper } from "react-popper";
import Cross from "../../assets/image/icons/modal_circle_over_cross.svg";
import Grow from "@mui/material/Grow";
import SuccessModal from "./SuccessModal";
import axios from "axios";
import { styled } from "@mui/material";

const SubscribeInput = styled("input")(({ theme, error }) => ({
  borderRadius: 8,
  outline: "none",
  lineHeight: "28px",
  backgroundColor: "#06071B",
  border: `2px solid ${error ? "#f87171" : "#D9D9D9"}`,
  color: "#B1B5C4",
  fontFamily: "Montserrat",
  fontSize: 14,
  marginTop: 33,
  padding: "10px 12px",
  "&:focus": {
    border: `2px solid ${error ? "#f87171" : "#3B71FE"}`,
  },
  // "&:hover": {
  //   border: `2px solid ${error ? "#f87171" : "#3B71FE"}`,
  // },
  width: "100%",
  "::placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  "::-webkit-input-placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  ":-ms-input-placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
}));
export default function RequestModal({ open, setOpen }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    clientType: "",
  });
  const [isActive, setIsActive] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);
  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        enabled: true,
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["bottom", "bottom"],
        },
      },
    ],
  });
  const options = [
    "IFA / MFD / RIA",
    "RM in Bank / Wealth firm",
    "Multi Family Office",
    "Other-Not Listed Above",
  ];
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    number: "",
    type: { border: "#D9D9D9", text: null },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = errors;
    if (errors.name || formData.name.length === 0)
      temp.name = "This field is required";
    if (
      errors.email ||
      !String(formData.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      temp.email = "Please enter a valid email";
    if (
      errors.number ||
      !String(formData.number)
        .toLowerCase()
        .match(
          /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
        )
    )
      temp.number = "Please enter a valid number";
    if (!formData.clientType)
      temp.type = { border: "#f87171", text: "This field is required" };
    setErrors({ ...temp, type: { ...temp.type } });
    let config = {
      headers: {
        "Content-Type": "application/form-data",
      },
    };
    let formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("email", formData.email);
    formdata.append("phone", formData.number);
    formdata.append(
      "client_type",
      formData.clientType[0] === "R"
        ? "W"
        : formData.clientType[0] === "M"
        ? "F"
        : formData.clientType[0]
    );
    axios
      .post(process.env.REACT_APP_contactURL, formdata, config)
      .then(function () {
        setSuccess(true);
      })
      .catch(function (err) {
        const temp = errors;
        if (errors.name || formData.name.length === 0)
          temp.name = "This field is required";
        if (
          errors.email ||
          !String(formData.email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        )
          temp.email = "Please enter a valid email";
        if (
          errors.number ||
          !String(formData.number)
            .toLowerCase()
            .match(
              /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
            )
        )
          temp.number = "Please enter a valid number";
        if (!formData.clientType)
          temp.type = { border: "#f87171", text: "This field is required" };
        setErrors({ ...temp, type: { ...temp.type } });
      });
  };

  return (
    <Grow in={open}>
      <div className="fixed left-0 right-0 top-0 bottom-0 bg-[#B1B5C4]/20 z-[101] w-[100vw] h-[100vh] flex justify-center items-center">
        <div
          className="w-[90%] sm:w-[75%] md:w-[550px] rounded-xl bg-primarybg-300  pt-7 px-10 sm:px-20 pb-8 relative"
          style={{ boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.05)" }}
        >
          <img
            src={Cross}
            alt="err"
            className="absolute top-6 right-6"
            onClick={() => {
              setFormData({
                name: "",
                email: "",
                number: "",
                clientType: "",
              });
              setErrors({
                name: "",
                email: "",
                number: "",
                type: { border: "#D9D9D9", text: null },
              });
              setOpen(false);
            }}
          />
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="w-full relative">
              <SubscribeInput
                type="text"
                data-eye
                placeholder="Name"
                error={errors.name}
                name="name"
                value={formData.name}
                onChange={(e) => {
                  if (!e.target.value) setErrors(errors);
                  else setErrors({ ...errors, name: null });
                  setFormData({ ...formData, name: e.target.value });
                }}
              />
              <div className="absolute">
                {errors.name && (
                  <div className="px-[6px] pt-[4px] font-inter text-sm text-red-400">
                    {errors.name}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full relative">
              <SubscribeInput
                type="text"
                data-eye
                placeholder="Email ID"
                name="email"
                value={formData.email}
                error={errors.email}
                onChange={(e) => {
                  if (
                    String(e.target.value)
                      .toLowerCase()
                      .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                  )
                    setErrors({ ...errors, email: null });
                  else setErrors(errors);
                  setFormData({ ...formData, email: e.target.value });
                }}
              />
              <div className="absolute">
                {errors.email && (
                  <div className="px-[6px] pt-[4px] font-inter text-sm text-red-400">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full relative">
              <SubscribeInput
                type="text"
                data-eye
                placeholder="Phone Number"
                name="number"
                value={formData.number}
                error={errors.number}
                onChange={(e) => {
                  if (
                    String(e.target.value)
                      .toLowerCase()
                      .match(
                        /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
                      )
                  )
                    setErrors({ ...errors, number: null });
                  else setErrors(errors);
                  setFormData({ ...formData, number: e.target.value });
                }}
              />
              <div className="absolute">
                {errors.number && (
                  <div className="px-[6px] pt-[4px] font-inter text-sm text-red-400">
                    {errors.number}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full select-none relative">
              <div
                className={`w-full h-[48px] px-3 py-[16px] mt-[33px] rounded-md flex justify-between items-center cursor-pointer bg-primarybg-300 border-2 border-[${errors.type.border}] font-montserrat  text-[#B1B5C4]`}
                ref={setReferenceRef}
                onClick={() => setIsActive(!isActive)}
              >
                {formData.clientType ? formData.clientType : "User Type"}
                <img src={Arrow} alt="err" />
              </div>
              {isActive && (
                <div
                  ref={setPopperRef}
                  style={styles.popper}
                  {...attributes.popper}
                  className="absolute top-[100%] z-10 left-0 bg-primarybg-100 w-full"
                >
                  {options.map((option, ind) => (
                    <div
                      key={ind}
                      onClick={() => {
                        setErrors({
                          ...errors,
                          type: { border: "#D9D9D9", text: null },
                        });
                        setFormData({ ...formData, clientType: option });
                        setIsActive(false);
                      }}
                      className="text-[#FCFCF4] divide-y-reverse divide-[#fff] w-full h-[44px] px-3 py-3 bg-primarybg-100 placeholder:text-[#B1B5C4] align-middle cursor-pointer hover:bg-primaryblue-200 hover:text-white"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
              <div className="absolute">
                {errors.type.text && (
                  <div className="px-[6px] pt-[4px] font-inter text-sm text-red-400">
                    {errors.type.text}
                  </div>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="bg-primaryblue-200 flex items-center py-3 px-6 rounded-[90px] mt-10 m-auto"
            >
              <p className="font-dmsans text-[#FCFCFD] font-bold">
                Connect With Us
              </p>
            </button>
          </form>
        </div>
        <SuccessModal
          open={success}
          setOpen={setSuccess}
          onClose={() => {
            setFormData({
              name: "",
              email: "",
              number: "",
              clientType: "",
            });
            setErrors({
              name: "",
              email: "",
              number: "",
              type: { border: "#D9D9D9", text: null },
            });
            setSuccess(false);
            setOpen(false);
          }}
        />
      </div>
    </Grow>
  );
}
