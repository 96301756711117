import React from "react";
import Cup from "../../assets/image/icons/Cup.svg";
import Storyteller from "../../assets/image/illustrations/StoryTeller.svg";
import ActiveStoryteller from "../../assets/image/icons/active_storyteller.svg";
import Navigation from "../../assets/image/icons/Navigation.svg";
import VisibilitySensor from "react-visibility-sensor";
export default function ExternalPortfolioAssessment({ setBg, bg = [] }) {
  return (
    <div className="grid grid-cols-1 mt-40 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[17rem] flex pb-[16px] mb-8 "
              style={{
                // background: bg[2],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              {/* <img src={bg[2].length ? ActiveDesigner : Cup} alt="err" /> */}
              <img
                src={bg[0]?.length ? ActiveStoryteller : Navigation}
                alt="err"
              />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[2]?.length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              External Portfolio Assessment
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Elevate client acquisition by offering actionable insights through
              external portfolio assessments. Convert prospects into clients
              with professional presentations showcasing expertise in mutual
              fund investments. Deepen wallet share by demonstrating value and
              strategies for maximizing returns while mitigating risk in their
              total mutual fund portfolio.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[14rem] flex pb-[16px] ml-[20px]"
              style={{
                // background: bg[2],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[2]?.length ? ActiveStoryteller : Cup} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[2]?.length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              ></p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              External Portfolio Assessment
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Elevate client acquisition by offering actionable insights through
              external portfolio assessments. Convert prospects into clients
              with professional presentations showcasing expertise in mutual
              fund investments. Deepen wallet share by demonstrating value and
              strategies for maximizing returns while mitigating risk in their
              total mutual fund portfolio.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../image/illustrations/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <img src={Storyteller} alt="err" />
      </div>
    </div>
  );
}
