import React from "react";
import Header from "../header/Header";
import Footer from "../footer";

const definitions = [
  "“We”, “Us”, “Our”, “Company” - shall refer to Valiant Fintech Private Limited and its group entities.",
  "“You”, “Yours”, “Yourself”, “User”, “BeyondIRR User” - refers to any individual, non- individual or corporate body.",
  "“Government Authority” shall mean any national, state, local, provincial, municipal, district or other sub-division governmental authority, statutory authority including Securities and Exchange Board of India (SEBI), Reserve Bank of India (RBI), Insurance Regulatory Development Authority of India (IRDAI), CDSL, NSDL, government department, administrative authority including (Indian) stock exchange such as NSE, BSE, commission, board, tribunal or court or other law, rule or regulation making entity or competent authority.",
  "“Third Party Service Provider(s)” means and includes company, vendors, business partners, banks & financial institutes or any such entity whose facilities are used by Company(s) to provide the various Services.",
  "“Terms” or “Terms of Use”- are interchangeably used and shall have the same meaning.]",
];

const termsOfUse = [
  "You are 18 years of age or older and where you are acting as guardian on behalf of a minor, you have necessary authority to register / sign up for the Services on behalf of the minor. You hereby unconditionally and unequivocally agree to promptly furnish and/or execute such additional document(s) (as may be requested from time to time), to establish Yourself as lawful guardian and to continue Your access to the Services.",
  "You are capable of entering into legally binding contract and that You are not barred or otherwise legally prohibited to use the Services.",
  "You agree that you will be allowed to make any transaction through the BeyondIRR Platform and/or the Sites when you complete the know your client (“KYC”) process and provide the complete information including personal information in accordance with the KYC guidelines as issued by SEBI and/or IRDAI and/or any other regulatory body, government authorities, competent authority from time to time. For availing various Services, You hereby grant your consent and permit Company to share your KYC information with its group entities, and/or with Third Party Service Providers offering your various products and services, to enable them to promptly respond to your queries / interests. The collection, processing, storage, and sharing of Your information is further governed by Company’s Privacy Policy.",
  "You shall always ensure that Your use of BeyondIRR Platform and/or the Sites and/or reasons to avail Services shall be strictly for valid, lawful purpose. You shall remain fully responsible for all activities, transactions placed / requested on BeyondIRR Platform and/or the Sites.",
  "You agree that transactions made through BeyondIRR Platform and/or the Sites shall be through your own bank account only and the said transactions do not violate or contravene to any law for the time being in force including Income Tax Act, anti-money laundering laws, anti-corruption laws, prevention of money laundering act etc. and the rules made thereunder.",
  "You shall be responsible to maintain confidentiality of your BeyondIRR Platform and/or the Sites account including your log-in credentials & OTPs. You understand that Company is merely facilitating you to secure your account on good-faith basis and as such Company assumes no responsibility or liability towards any losses, frauds, unauthorized transactions that may happen or you may suffer",
  "You shall not do or attempt to do any of the following acts: (i) impersonate any person or entity, or falsely stating your age or affiliation with any person or entity; (ii) posts any third-party intellectual property rights or other property rights infringing contents; (iii) act in violation or contravention to any law for the time being in force; reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of BeyondIRR Platform and/or the Sites or any part thereof; (iv) engage in, post, or transmit any message which is libellous, defamatory, pornographic, vulgar or offensive in nature or otherwise inconsistent with or contrary to the laws in force",
  "The information You provide when You register on the BeyondIRR Platform and/or the Sites is complete, true, correct, updated, accurate and belongs to You.",
  "You shall remain responsible to notify to the Company of any material change in your personal information and/or profile. Company would be entitled to rely on the most recent information provided by You.",
  "You agree to be contacted by Company (including its employees, representatives, officers, partners etc.), Third Party Service Providers over phone and/or e-mail and/or SMS/and or push notifications or any other form of electronic communication. This consent overrides any registration for DNC/NDNC. You agree and confirm that if your mobile number is registered in the Do Not Disturb (DND) list of TRAI, you may not receive SMS from the Company. You agree to take steps to deregister from the DND list and shall not hold Company liable for non-receipt of SMS.",
  "You hereby unequivocally grant Company the right and authority to use your contact details as furnished by You at the time of registering on BeyondIRR Platform and/or the Sites as your primary, valid and default communication address for any and all Services. You cannot hold Company liable for non-availability of the SMS/email alert/push notifications service in any manner whatsoever.",
  "You understand that You shall be responsible for maintaining the confidentiality of your information and You are fully responsible for all activities that occurs on BeyondIRR Platform and/or the Sites under your login. You also agree to always keep your login credentials safe, secure and confidential. You shall continue to be responsible for the activity, transactions on BeyondIRR Platform and/or the Sites, if you knowingly or negligently (i) grant any other person access to your login, (ii) permit any other person to transact, or (iii) transact on any other person’s behalf or directions in any manner whatsoever. Company cannot and shall not be liable for any losses or damages arising from Your failure to comply with these provision(s).",
  "You acknowledge that the software and hardware underlying the application as well as other Internet related software which are required for accessing the application are the legal property of either Company or its respective Third Party Service Providers. The permission given by Company to access BeyondIRR Platform and/or the Sites will not convey or grant any proprietary or ownership rights in the above software/hardware.",
  "The Company is not responsible for the availability of content or other services on third party sites linked from BeyondIRR Platform and/or the Sites. You are aware that access of hyperlinks to other internet sites are at your own risk and the content, accuracy, opinions expressed, and other links provided by these sites are not verified, monitored or endorsed by Company in any way. The Company urges You to read the terms of use, privacy policy and/or other conditions available on respective third party sites, before accessing or registering with any of such third party sites. Company does not make any warranties and expressly disclaims all warranties express or implied, including without limitation, those of merchantability and fitness for a particular purpose, title or non-infringement with respect to any information or services or products that are available or advertised or sold through these third-party platforms.",
  "You agree that You will not use the BeyondIRR Platform and/or the Sites for any purpose that is unlawful or prohibited by these Terms. You also agree You will not use the BeyondIRR Platform and/or the Sites in any manner that could damage, disable or impair the application or interfere with any other party’s use, legal rights, or enjoyment of the application. You hereby represent and warrant that you shall make use of the BeyondIRR Platform and/or the Sites as a prudent, reasonable and law-abiding citizen and you shall comply with relevant applicable laws.",
  "The Company can Third Party Service Providers for payment gateway services, account aggregator services etc. The engagement of these Third-Party Service Providers is to facilitate users to provide Services seamlessly and securely on BeyondIRR Platform and/or the Sites. However, Company assumes no responsibility or liability in any manner whatsoever, for any delayed or non-processing of transactions. You understand that Company and/or partnered financial institutes shall be well within its rights to not to act and/or suspend the services if the transaction is not successful or delayed credit of transactions takes place. You understand and accept the risk associated with processing of online transactions and agrees that Company shall not in any manner whatsoever be responsible to You for any losses, cost, interest, penalties, levies, charges or such amount that may be recoverable from you due to delay in or non-processing of transaction is levied. It is advised that, You should diligently keep track of all your payments are done in timely and successful manner.",
  "Neither Company nor any of our directors, employees or agents shall be liable for any advice or representation made by it/him/her hereinunder and it will be Your responsibility to make an independent assessment pursuant to the availing/using of the BeyondIRR Platform and/or the Sites/Services or availing any product or services.",
  "You acknowledge and agree that Company do not guarantee that availing of the services from the BeyondIRR Platform and/or the Sites will result in profits or avoid losses or meet the objectives, including Your investment objectives, or that availing / using of the services / BeyondIRR Platform and/or the Sites will not at any time be affected by adverse tax consequences, technical failures, timely regulatory compliance to a new law. Company shall not be liable to You for any error of judgement or loss suffered by You in connection with the services provided to you.",
  "Company does not provide investment advisory services, and anything contained or provided on BeyondIRR Platform and/or the Sites should be considered as investment advice.",
  "You agree and understand that by availing the Services You shall be bound by our Privacy Policy and your information provided on the BeyondIRR Platform and/or the Sites shall be shared with business partners.",
];

const confidentiality = [
  "You shall not use (except as expressly authorized by this Agreement) or disclose Proprietary Information without the prior written consent of Company unless such Proprietary Information becomes generally publicly available without your breach of this Agreement.",
  "You agree to take reasonable measures to maintain the Proprietary Information and Services in confidence.",
];

const limitations = [
  "In no event shall Company or its directors, employees, associates, partners, or suppliers will be liable to you for any loss or damage that may cause or arise from or in relation to these Terms of Use or due to use of this BeyondIRR Platform and/or the Sites or due to investments made using this BeyondIRR Platform and/or the Sites or availing any product or services from any third-party service provider.",
  "You agree to indemnify Company or its directors, employees, associates, partners or suppliers for all the liabilities (including claims, damages, suits or legal expenses in defending itself in relation to the foregoing) arising due to (i) use or misuse of the BeyondIRR Platform and/or the Sites (ii) non-performance and/or non-observance of the duties and obligations under these Terms of Use or due to your acts or omissions (iii) any act, neglect, misconduct or fraud on your part.",
  "You warrant that all the details and information provided by you to Company or its directors, employees, associates, partners, or suppliers while using this BeyondIRR Platform and/or the Sites (including for the purposes of carrying out investments) shall be updated, correct, accurate, complete and genuine.",
  "You shall be solely responsible for any investment decision taken by You on the Services and Company shall not be liable for any loss or damage caused to you or other users of this BeyondIRR Platform and/or the Sites due to such investment decision, or any kind of reliance upon it. You expressly agree that use of the BeyondIRR Platform and/or the Sites is at your sole risk.",
  "To the fullest extent permissible pursuant to applicable law, Company and its directors, employees, associates, third-party partners or suppliers disclaim all warranties or guarantees – whether statutory, express or implied – including, but not limited to, implied warranties of merchantability, fitness and non-infringement of proprietary rights. No advice or information, whether oral or written, obtained by you from Company or through the BeyondIRR Platform and/or the Sites will create any warranty or guarantee other than those expressly stated herein.",
];

export default function Terms() {
  return (
    <>
      <Header />
      <div className="bg-primarybg-300 px-5 py-20 md:px-20 md:py-24">
        <h2 className="font-montserrat text-[32px] leading-[48px] font-medium text-[#FCFCFD]">
          Terms of Use
        </h2>
        <p style={{ color: "gray", fontWeight: "lighter" }}>
          Last updated on 09-04-2024 10:10:36
        </p>
        <div className="font-montserrat font-light text-lg leading-[27px] text-[#B1B5C4] mt-8">
          <p>
            Welcome, and thank you for using BeyondIRR Platform and/or the
            Sites. When you use BeyondIRR Platform and/or the Sites, You are
            agreeing to be governed by the Terms of Use contemplated herein
            below read with our Privacy Policy, the terms and condition (if any)
            specific to the products and/ or services availed by You. We
            sincerely recommend you take a few minutes to read over and
            familiarize Yourself with the Terms of Use described below.
          </p>
          <br />
          <p>
            <strong style={{ color: "white" }}>NOTE:</strong> By registering,
            browsing, accessing, downloading, and/or using the BeyondIRR
            Platform and/or the Sites either for general purpose or for specific
            purpose, You are agreeing to and entering into a legally binding
            agreement with including but not limited to Valiant Fintech Private
            Limited and agreeing to the Terms of Use stated herein. if You do
            not agree to any or all of the following Terms of Use, You should
            not use or access BeyondIRR Platform and/or the Sites in any manner.
          </p>
          <br />
          <p>
            <strong style={{ color: "white" }}>
              PLEASE READ THROUGH THESE TERMS OF USE CAREFULLY BEFORE USING THE
              BEYONDIRR PLATFORM AND/OR THE SITES.
            </strong>
          </p>
          <br />
          <p>
            The website(s) https://www.beyondirr.tech/,
            https://www.beyondirr.com/, https://investedge.tech/,
            https://investlane.tech/, or through another URL of the Company
            and/or mobile applications like ‘BeyondIRR’ , ‘InvestEdge’ ,
            ‘InvestLane’, and ‘BeyondIRR Wealth Partner’ or through another
            mobile application of the Company (collectively referred as the
            BeyondIRR Platform) as well as including its sub-sites, platforms,
            applications, m-web platforms and other platforms (collectively
            referred to as "Sites") are owned, operated, and managed, by Valiant
            Fintech Private Limited (“the Company”), having its registered
            office located HD-085, Wework Enam Sambhav, Plot C-20, G Block,
            Bandra Kurla Complex, Bandra(East), Mumbai, Maharashtra, India –
            400051 for delivery of information, products, offerings and content
            via any mobile or internet connected device or otherwise
            (collectively the "Services").
          </p>
          <br />
          <strong style={{ color: "white" }}>
            THESE TERMS IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC
            CONTRACT FORMED UNDER INFORMATION TECHNOLOGY ACT, 2000 AND RULES
            MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC
            DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
            INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS DOES NOT REQUIRE ANY
            PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
          </strong>

          <div id="termsofuse">
            <ol
              type="1"
              style={{ listStyle: "decimal", listStylePosition: "inside" }}
            >
              <br />
              <strong className="font-semibold text-[#FFF]">DEFINITIONS</strong>
              <ol
                type="1"
                style={{ listStyle: "decimal", listStylePosition: "inside" }}
              >
                {definitions.map((definition, index) => (
                  <li key={index} className="mt-4">
                    {definition}
                  </li>
                ))}
              </ol>
            </ol>

            <br />
            <p>
              You hereby understand that, the specific products / services
              availed by You may have their own additional / supplemental terms
              and conditions and You unequivocally grant Your consent to abide
              and remain compliant with the said additional / supplemental terms
              and conditions. When You use any of the Services, You shall also
              be subjected to comply with applicable law(s), rule(s),
              guideline(s), notification(s), circular(s), or such other
              directive(s) issued by applicable Government Authority. You
              acknowledge and understand that these Terms of Use along with
              additional / supplemental terms and conditions shall be read
              harmoniously. Company reserves right change / amend / modify these
              Terms or the additional / supplemental terms and conditions at any
              time, without notice.
            </p>

            <ol>
              <br />
              <strong className="font-semibold text-[#FFF]">
                TERMS OF USE
              </strong>
              <p>
                <br />
                By registering on BeyondIRR Platform and/or the Sites, you
                agree, declare and represent that:
              </p>
              <ol
                type="1"
                style={{ listStyle: "decimal", listStylePosition: "inside" }}
              >
                {termsOfUse.map((definition, index) => (
                  <li key={index} className="mt-4">
                    {definition}
                  </li>
                ))}
              </ol>
            </ol>

            <ol>
              <br />
              <strong className="font-semibold text-[#FFF]">
                CONFIDENTIALITY
              </strong>
              <p>
                <br />
                You acknowledge that, in the course of Your relationship with
                Company and in using the services, you may obtain information
                relating to the services and/or Company (“Proprietary
                Information”). Such Proprietary Information shall belong solely
                to Company and includes, but is not limited to, the features and
                mode of operation of the Services, trade secrets, know-how,
                inventions (whether or not patentable), techniques, processes,
                programs, ideas, algorithms, schematics, testing procedures,
                software design and architecture, computer code, internal
                documentation, design and function specifications, product
                requirements, problem reports, analysis and performance
                information, benchmarks, software documents, and other
                technical, business, product, plans and data. In regard to this
                Proprietary Information:
              </p>
              <ol
                type="1"
                style={{ listStyle: "decimal", listStylePosition: "inside" }}
              >
                {confidentiality.map((definition, index) => (
                  <li key={index} className="mt-4">
                    {definition}
                  </li>
                ))}
              </ol>
            </ol>

            <br />
            <strong className="font-semibold text-[#FFF]">
              USE AND PROTECTION OF INTELLECTUAL PROPERTY RIGHTS
            </strong>
            <p>
              <br />
              BeyondIRR Platform and/or the Sites is protected by copyright,
              trademarks, patents, trade secret and/or other relevant
              intellectual property laws. No information, content or material
              from BeyondIRR Platform and/or the Sites may be copied,
              reproduced, republished, uploaded, posted, transmitted or
              distributed in any way without Company's express written
              permission. You are hereby given a limited licence to use the
              BeyondIRR Platform and/or the Sites for your personal and
              non-commercial use, subject to your agreement of these Terms. You
              agree not to sell, license, distribute, copy, modify, publicly
              perform or display, transmit, publish, edit, adapt, create
              derivative works from, or otherwise make unauthorized use of the
              BeyondIRR Platform and/or the Sites.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">PRIVACY</strong>
            <p>
              <br />
              You agree that during your use and access of the BeyondIRR
              Platform and/or the Sites and/or availing of the services offered
              by the BeyondIRR Platform and/or the Sites, you will provide Us
              with certain information and other data as mentioned under these
              Terms of Use herein which may or may not be otherwise publicly
              available. Please note that We respect the privacy and
              confidentiality of such data and the provisions pertaining to such
              private information and data as provided by You under these Terms
              of Use, are governed under the Privacy Policy which is available
              at https://www.beyondirr.tech/privacy. By using and visiting the
              BeyondIRR Platform and/or the Sites and availing BeyondIRR
              Platform and/or the Sites Services, you also agree to our Privacy
              Policy.
            </p>

            <ol>
              <br />
              <strong className="font-semibold text-[#FFF]">
                LIMITATION OF LIABILITY, INDEMNITY, AND WARRANTY
              </strong>
              <p>
                <br />
                You acknowledge that, in the course of Your relationship with
                Company and in using the services, you may obtain information
                relating to the services and/or Company (“Proprietary
                Information”). Such Proprietary Information shall belong solely
                to Company and includes, but is not limited to, the features and
                mode of operation of the Services, trade secrets, know-how,
                inventions (whether or not patentable), techniques, processes,
                programs, ideas, algorithms, schematics, testing procedures,
                software design and architecture, computer code, internal
                documentation, design and function specifications, product
                requirements, problem reports, analysis and performance
                information, benchmarks, software documents, and other
                technical, business, product, plans and data. In regard to this
                Proprietary Information:
              </p>
              <ol
                type="1"
                style={{ listStyle: "decimal", listStylePosition: "inside" }}
              >
                {limitations.map((definition, index) => (
                  <li key={index} className="mt-4">
                    {definition}
                  </li>
                ))}
              </ol>
            </ol>

            <br />
            <strong className="font-semibold text-[#FFF]">CONSIDERATION</strong>
            <p>
              <br />
              The Company have the right to charge its Users a fee for availing
              the Services, as applicable for any specific product / services
              been offered on BeyondIRR Platform and/or the Sites. The
              applicable fees will be made available at the time of registration
              and/or on its applicability and/or under the relevant product /
              services section. Company may change fee structure as it
              determines in its sole discretion from time to time. All charges
              and fees shall be exclusive of taxes and all Government taxes
              including but not limited to GST shall be payable by you. Please
              note the charges / fees applicable shall be non-transferable,
              nonrefundable and non-cancellable in nature.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">DISCLAIMERS</strong>
            <p>
              <br />
              The user agrees and understands that the beyondirr platform and/or
              the sites is provided by us on an “as is” and “as available” basis
              and we make no representations or warranties of any kind, express
              or implied, as to the operation of the beyondirr platform and/or
              the sites or the information, content included on the beyondirr
              platform and/or the sites. You expressly agree that your use of
              the beyondirr platform and/or the sites is at your sole risk. To
              the fullest extent permissible by applicable law, we disclaim all
              warranties, express or implied. We do not warrant that the
              beyondirr platform and/or the sites, its servers, or email/ other
              communication sent from the beyondirr platform and/or the sites
              are free of viruses or other harmful components. We will not be
              liable for any damages of any kind arising from the use of the
              beyondirr platform and/or the sites, including, but not limited to
              direct, indirect, incidental, punitive and consequential damages.
              all investments are subject to market risks. Read all scheme
              related things carefully. Past performance is not an indicator of
              future returns. All interaction, communication, dealing, or
              transaction between the users and the third-party provider in
              respect of any products/services offered by the third-party
              provider is a separate and independent transaction between the
              user and such thirdparty provider without any liability accruing
              to or on us for any matters arising out of or in relation to the
              same. The user expressly agrees and acknowledges to hold harmless
              us in respect of any cost, claims, damage, loss or expenses
              accrued, suffered, incurred by us or any third party arising out
              of or in connection with any such communication, interaction,
              dealings and transactions between the user and third-party
              providers. The user acknowledges that we do not have any control
              over such dealings and transactions and plays no determinative
              role in the performance in respect of the same and we shall not be
              liable for the outcomes of such communication, interaction,
              dealings and transactions between the users and the third-party
              providers. We do not warrant, endorse, guarantee, or assume
              responsibility for any product or service advertised or offered by
              a third-party provider in any manner and we will not be a party to
              or in any way be responsible for any transaction between you and
              such party provider. As with the purchase of a product or service
              through any medium through such third-party provider, you should
              use your best judgment and exercise caution where appropriate. The
              logos/ trademarks advertised in the platform are the property of
              respective trademark owners and by displaying it has no right,
              title, interest over it. The user expressly agrees that the
              company is not liable or responsible and does not represent or
              warrant for any damages / any loss of interest and/ or opportunity
              loss and /or any loss arising due to movement of net asset value
              (nav), notional or otherwise due to non-execution of orders or any
              incorrect execution of orders with regard to the mutual funds
              chosen by the user due to, but not being limited to, any
              link/system failure, sufficient/insufficient funds in the user’s
              bank account which may occur at user’s end. Mutual fund
              investments are subject to market risks, read all scheme related
              documents carefully. This is in no way to be considered as an
              advice or recommendation. Mutual funds transaction facilitation
              services are provided by the company in partnership with its
              service providers. Past performance is not indicative of future
              returns. Performance and returns of any investment portfolio can
              neither be predicted nor guaranteed. Mutual fund investments are
              not exchange traded services. The company acts in the capacity of
              facilitator of this product. The company is only providing
              technology services and all disputes with respect to mutual fund
              investments would not have access to investor redressal or
              arbitration mechanism in india. The company accept no liabilities
              for any loss or damage of any kind arising out of any action by
              the user.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">FORCE MAJEURE</strong>
            <p>
              <br />
              If the whole or any part of the performance is prevented, hindered
              or delayed by a Force Majeure event (as defined below), Company
              shall not be liable for any failure to perform any of its
              obligations under these Terms of Use or those applicable
              specifically to its services/facilities, and in such case its
              obligations shall be suspended for so long as the Force Majeure
              event continues. “Force Majeure Event” means any event, due to any
              cause beyond the reasonable control of Company, including without
              limitations, unavailability of any communication systems, breach,
              or virus in the digital processes or payment or delivery
              mechanism, sabotage, fire, flood, explosion, acts of God, civil
              commotion, strikes or industrial action of any kind, riots,
              insurrection, war, acts of government, lockdown, computer hacking,
              unauthorised access to computer data and storage devices, computer
              crashes, malfunctioning in the computer terminal or the systems
              getting affected by any malicious, destructive or corrupting code
              or program, mechanical or technical errors/failures or power shut
              down, faults or failures in telecommunication etc.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">SEVERABILITY</strong>
            <p>
              <br />
              All illegality, invalidity or unenforceability of any provision of
              these Terms of Use under the law of any jurisdiction will not
              affect its legality, validity or enforceability under the law of
              any other jurisdiction nor the legality, validity or
              enforceability of any other provision.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">WAIVER</strong>
            <p>
              <br />
              No failure on the part of any party to exercise, and no delay on
              its part in exercising any right or remedy under this Agreement
              will operate as a waiver thereof, nor will any single or partial
              exercise of any right
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">ASSIGNMENT</strong>
            <p>
              <br />
              You agree that we may transfer, subcontract or otherwise deal with
              our rights and/or obligations under these Terms of Use at any time
              without any further notice. You agree that you cannot assign or
              otherwise transfer, subcontract the terms or any rights granted
              hereunder to any party.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">
              DISPUTE RESOLUTION
            </strong>
            <p>
              <br />
              Any dispute, controversy, claims or disagreement of any kind
              whatsoever between the parties in connection with or arising out
              of this Agreement shall be referred for arbitration, to a sole
              arbitrator appointed by Company, through arbitration to be
              conducted in accordance with the Arbitration and Conciliation Act,
              1996. The seat and venue of such arbitration shall be at Mumbai,
              India. All proceedings of such arbitration, including, without
              limitation, any awards, shall be in the English language. The
              award shall be final and binding on the parties.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">
              GOVERNING LAWS
            </strong>
            <p>
              <br />
              These Terms shall be governed, interpreted, and construed in
              accordance with the laws of India, without regard to the conflict
              of law provisions and for resolution of any dispute arising out of
              your use of the Services or in relation to these Terms.
              Notwithstanding the foregoing, you agree that (i) Company has the
              right to bring any proceedings before any court/forum of competent
              jurisdiction and you irrevocably submit to the jurisdiction of
              such courts or forum; and (ii) any proceeding brought by you shall
              be exclusively before the courts in Mumbai, India.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">
              ENTIRE AGREEMENT
            </strong>
            <p>
              <br />
              These Terms, together with the other laws, guidelines, rules,
              (additional / supplemental) terms & conditions and/or policies of
              the BeyondIRR Platform and/or the Sites, including the Privacy
              Policy and any other arrangement/agreement in relation the
              Services, constitute the entire agreement between the User and the
              Us and supersede all previous agreements, promises, proposals,
              representations, understandings and negotiations, whether written
              or oral, between the User and Us pertaining to the subject matter
              hereof.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">SURVIVAL</strong>
            <p>
              <br />
              Notwithstanding the termination or rescission of this Agreement,
              the provisions of this Agreement shall continue to apply to those
              duties and obligations which are intended to survive any such
              cancellation, termination or rescission, including, without
              limitation clauses related to Limitation of liability, Indemnity,
              Warranty, Intellectual Property, Confidentiality, Dispute
              Resolution, Governing Law and Jurisdiction. Further any provisions
              of this Agreement which by implication are to survive the
              termination of this Agreement shall survive such termination.
              Termination of the Agreement shall not abate the causes of action
              that have accrued to the parties prior to such termination.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">USER SUPPORT</strong>
            <p>
              <br />
              Company shall provide assistance and co -ordinate with the Users
              in case of any issues / queries raised by the User and shall
              resolve the queries on the best effort basis.
            </p>

            <br />
            <strong className="font-semibold text-[#FFF]">
              MODIFICATION OF TERMS
            </strong>
            <p>
              <br />
              Company reserves the right to change, amend, or modify, from time
              to time, any provision related to the BeyondIRR Platform and/or
              the Sites (including BeyondIRR Platform and/or the Sites
              Service(s)) or these Terms of User, which also include, changing
              of the extent and scope of the services and/or include any other
              category, service, facility or feature at the sole discretion of
              the Company. Any such change(s) shall be effective immediately
              upon the posting of revised Terms and may be notified via
              BeyondIRR Platform and/or the Sites. You can determine when these
              Terms of Use were last revised by referring to ‘LAST UPDATED’ at
              the top of these Terms. By using the services provided through
              this BeyondIRR Platform and/or the Sites, you shall be deemed to
              have accepted the Terms herein including the amended Terms
              published on the BeyondIRR Platform and/or the Sites from time to
              time. Your continued use of the BeyondIRR Platform and/or the
              Sites following the posting of changes means that you accept and
              agree to the changes. If you do not agree with any such change,
              your sole and exclusive remedy is to terminate your use of the
              BeyondIRR Platform and/or the Sites. It is, further, clarified
              that the User’s use and access of the BeyondIRR Platform and/or
              the Sites, the Service is subject to the most recent version of
              these Terms made available on the BeyondIRR Platform and/or the
              Sites at the time of such use.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
