import { Helmet } from "react-helmet";
import ContactUs from "../../components/contactus";

const ContactUsPage = () => (
  <>
    <Helmet />
    <ContactUs />
  </>
);

export default ContactUsPage;
ContactUsPage.displayName = "Contact Us";