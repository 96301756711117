import { Helmet } from "react-helmet";
import ClientRetentionComponent from "../../components/clientRetention/index";
// import Header from "../../components/header/Header";
// import HeroSection from "./HeroSection";

const ClientRetention = () => (
  <>
    <Helmet />
    {/* <Header/> */}
    {/* <HeroSection/> */}
    <ClientRetentionComponent />
  </>
);

export default ClientRetention;
// InvesGPTPage.displayName = "InvestGPT";
