import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

// BENEFIT SECTION CUBES
export const benefitCards = [
  [
    {
      _id: uuidv4(),
      type: "blue",
      name: "Long Short Strategies",
      index: 0,
    },
    {
      _id: uuidv4(),
      type: "blue",
      name: "Arbitrage Strategies",
      index: 1,
    },
    {
      _id: uuidv4(),
      type: "blue",
      name: "Life Settlement Funds",
      index: 2,
    },
  ],
  [
    {
      _id: uuidv4(),
      type: "orange",
      name: "IPL Teams, Airports, Stocks Exchanges",
      index: 3,
    },
    {
      _id: uuidv4(),
      type: "orange",
      name: "Corporate FD, Asset Leasing, Invoice Discounting",
      index: 4,
    },
    {
      _id: uuidv4(),
      type: "orange",
      name: "MLDs of High quality issuers",
      index: 5,
    },
  ],
  [
    {
      _id: uuidv4(),
      type: "pink",
      name: "Co-invest along with VC fund managers",
      index: 6,
    },
    {
      _id: uuidv4(),
      type: "pink",
      name: "Fractional Commercial Real Estate",
      index: 7,
    },
    {
      _id: uuidv4(),
      type: "pink",
      name: "Multi Factor Smart Beta Strategies",
      index: 8,
    },
  ],
];

// BENEFIT SECTION LIST
export const benefitList = [
  {
    _id: uuidv4(),
    name: "Investments with downside risk protection",
    benefitCards,
    side: "left",
  },
  {
    _id: uuidv4(),
    name: "Products where returns don't get impacted with Equity and Debt market movements",
    benefitCards,
    side: "right",
  },
  // {
  //   _id: uuidv4(),
  //   name: "International Investment Opportunities which you can't access through MF schemes",
  //   benefitCards,
  //   side: "left",
  // },
  {
    _id: uuidv4(),
    name: "Easy access to Sectors and Stocks not available in listed markets",
    benefitCards,
    side: "left",
  },
  {
    _id: uuidv4(),
    name: "Fixed Income but enhanced returns",
    benefitCards,
    side: "right",
  },
  {
    _id: uuidv4(),
    name: "Tax efficient fixed income investing",
    benefitCards,
    side: "left",
  },
  {
    _id: uuidv4(),
    name: "Increase success probability of your startup investment",
    benefitCards,
    side: "right",
  },
  {
    _id: uuidv4(),
    name: "Grade A office buildings without huge investment",
    benefitCards,
    side: "left",
  },
  {
    _id: uuidv4(),
    name: "Smart beta passive strategies as per your requirements",
    benefitCards,
    side: "right",
  },
];

// ADVANTAGES SECTION LIST
export const advantagesList = [
  {
    _id: uuidv4(),
    name: "Unlisted Pre-IPO Oppurtunities",
    description: [
      {
        _id: uuidv4(),
        point:
          "These opportunities (e.g. NSE, Tata Capital, Swiggy) enable your clients to participate in sectors that are not present in listed markets as well as help buy good companies at valuation cheaper than listed peers.",
      },
      // {
      //   _id: uuidv4(),
      //   point: "Book AUM in your broker code",
      // },
    ],
  },
  {
    _id: uuidv4(),
    name: "Early/Mid Age Startups",
    description: [
      {
        _id: uuidv4(),
        point:
          "We provide curated set of investment options in early to mid-stage startups (e.g. Defense, Consumer brands) that have potential to grow and scale – leading to superior returns on your investments in these companies.",
      },
    ],
  },
];

const JOIN_ERRORS = {
  email: {
    required: "Email is required",
    email: "Email is not valid",
  },
};

// SUBSCRIPTION SCHEMA
export const JOIN_FORM = {
  state: {
    email: "",
    is_subscribed: true,
  },
  schema: yup.object().shape({
    is_subscribed: yup.bool(),
    email: yup
      .string()
      .required(JOIN_ERRORS.email.required)
      .email(JOIN_ERRORS.email.email),
  }),
};

export const featuredBrands = [
  // FIRST
  {
    _id: uuidv4(),
    state: 0,
    width: 185,
    height: 45,
    left: 421,
    top: 333,
    text: "Long Short Strategies",
  },
  {
    _id: uuidv4(),
    state: 0,
    width: 193,
    height: 139,
    left: 243,
    top: 174,
    img: {
      src: "/assets/images/benefit/img-1.png",
      width: "149px",
      height: "105px",
    },
  },
  // SECOND
  {
    _id: uuidv4(),
    state: 1,
    width: 185,
    height: 45,
    left: 650,
    top: 207,
    text: "Arbitrage Strategies",
  },
  {
    _id: uuidv4(),
    state: 1,
    width: 149,
    height: 90,
    left: 480,
    top: 222,
    img: {
      src: "/assets/images/benefit/img-2.png",
      width: "58px",
      height: "53px",
    },
  },
  // THIRD
  {
    _id: uuidv4(),
    state: 2,
    width: 185,
    height: 61,
    left: 650,
    top: 393,
    text: "IPL Teams, Airports, Stocks Exchanges",
  },
  {
    _id: uuidv4(),
    state: 2,
    width: 149,
    height: 99,
    left: 650,
    top: 273,
    img: {
      src: "/assets/images/benefit/img-3-a.png",
      width: "72px",
      height: "59px",
    },
  },
  {
    _id: uuidv4(),
    state: 2,
    width: 149,
    height: 73,
    left: 743,
    top: 474,
    img: {
      src: "/assets/images/benefit/img-3-b.png",
      width: "103px",
      height: "39px",
    },
  },
  {
    _id: uuidv4(),
    state: 2,
    width: 149,
    height: 73,
    left: 480,
    top: 399,
    img: {
      src: "/assets/images/benefit/img-3-c.png",
      width: "91px",
      height: "31px",
    },
  },
  // FOUR
  {
    _id: uuidv4(),
    state: 3,
    width: 222,
    height: 54,
    left: 500,
    top: 494,
    text: "P2P Lending, Corporate FD",
  },
  {
    _id: uuidv4(),
    state: 3,
    width: 202,
    height: 160,
    left: 539,
    top: 571,
    img: {
      src: "/assets/images/benefit/img-4-a.png",
      width: "105px",
      height: "104px",
    },
  },
  {
    _id: uuidv4(),
    state: 3,
    width: 157,
    height: 149,
    left: 308,
    top: 459,
    img: {
      src: "/assets/images/benefit/img-4-b.png",
      width: "136px",
      height: "117px",
    },
  },
  // FIVE
  {
    _id: uuidv4(),
    state: 4,
    width: 222,
    height: 45,
    left: 467,
    top: 771,
    text: "MLDs of High quality issuers",
  },
  {
    _id: uuidv4(),
    state: 4,
    width: 147,
    height: 122,
    left: 371,
    top: 621,
    img: {
      src: "/assets/images/benefit/img-5-a.png",
      width: "90px",
      height: "68px",
    },
  },
  {
    _id: uuidv4(),
    state: 4,
    width: 282,
    height: 82,
    left: 452,
    top: 837,
    img: {
      src: "/assets/images/benefit/img-5-b.png",
      width: "224px",
      height: "46px",
    },
  },
  // SIX
  {
    _id: uuidv4(),
    state: 5,
    width: 185,
    height: 61,
    left: 755,
    top: 568,
    text: "Co-invest along with VC fund managers",
  },
  {
    _id: uuidv4(),
    state: 5,
    width: 208,
    height: 105,
    left: 779,
    top: 651,
    img: {
      src: "/assets/images/benefit/img-6-a.png",
      width: "147px",
      height: "70px",
    },
  },
  {
    _id: uuidv4(),
    state: 5,
    width: 230,
    height: 99,
    left: 961,
    top: 529,
    img: {
      src: "/assets/images/benefit/img-6-b.png",
      width: "136px",
      height: "41px",
    },
  },
  // SEVEN
  {
    _id: uuidv4(),
    state: 6,
    width: 185,
    height: 61,
    left: 913,
    top: 439,
    text: "Fractional Commercial Real Estate",
  },
  {
    _id: uuidv4(),
    state: 6,
    width: 208,
    height: 153,
    left: 856,
    top: 264,
    img: {
      src: "/assets/images/benefit/img-7.png",
      width: "143px",
      height: "110px",
    },
  },
  // EIGHT
  {
    _id: uuidv4(),
    state: 7,
    width: 185,
    height: 61,
    left: 755,
    top: 776,
    text: "Multi product & Smart beta strategies",
  },
  {
    _id: uuidv4(),
    state: 7,
    width: 187,
    height: 142,
    left: 829,
    top: 853,
    img: {
      src: "/assets/images/benefit/img-8-a.png",
      width: "150px",
      height: "121px",
    },
  },
  {
    _id: uuidv4(),
    state: 7,
    width: 163,
    height: 139,
    left: 1011,
    top: 669,
    img: {
      src: "/assets/images/benefit/img-8-b.png",
      width: "112px",
      height: "113px",
    },
  },
  {
    _id: uuidv4(),
    width: 249,
    height: 101,
    left: 91,
    top: 45,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 399,
    top: 0,
  },
  {
    _id: uuidv4(),
    width: 241,
    height: 97,
    left: 594,
    top: 89,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 871,
    top: 147,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 1011,
    top: 0,
  },
  {
    _id: uuidv4(),
    width: 112,
    height: 207,
    left: 1084,
    top: 207,
  },
  {
    _id: uuidv4(),
    width: 94,
    height: 442,
    left: 1217,
    top: 347,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 1370,
    top: 524,
  },
  {
    _id: uuidv4(),
    width: 280,
    height: 101,
    left: 1041,
    top: 851,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 1231,
    top: 1037,
  },
  {
    _id: uuidv4(),
    width: 219,
    height: 97,
    left: 893,
    top: 1021,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 534,
    top: 1069,
  },
  {
    _id: uuidv4(),
    width: 478,
    height: 68,
    left: 313,
    top: 948,
  },
  {
    _id: uuidv4(),
    width: 158,
    height: 96,
    left: 182,
    top: 794,
  },
  {
    _id: uuidv4(),
    width: 292,
    height: 80,
    left: 63,
    top: 645,
  },
  {
    _id: uuidv4(),
    width: 129,
    height: 189,
    left: 94,
    top: 379,
  },
  {
    _id: uuidv4(),
    width: 129,
    height: 105,
    left: 273,
    top: 333,
  },
  {
    width: 158,
    height: 96,
    left: 0,
    top: 201,
  },
];

export const moveTo = [
  {
    transform: "translate(30px, 122px)",
  },
  {
    transform: "translate(-260px, 67px)",
  },
  {
    transform: "translate(-300px, -50px)",
  },
  {
    transform: "translate(-150px, -180px)",
  },
  {
    transform: "translate(-150px, -400px)",
  },
  {
    transform: "translate(-600px, -260px)",
  },
  {
    transform: "translate(-625px, -50px)",
  },
  {
    transform: "translate(-604px, -500px)",
  },
];
