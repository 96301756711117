import { Helmet } from "react-helmet";
import About from "../../components/pricing/index";
const Pricing = () => (
  <>
    <Helmet />
    <About />
  </>
);

export default Pricing;
// AboutPage.displayName = "About Page";
