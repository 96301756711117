import React from 'react';

export default function productgpticon({ color }) {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.16675 6V5C6.16675 3.34315 7.50989 2 9.16675 2H11.1667C12.8236 2 14.1667 3.34315 14.1667 5V6H16.1667C17.2713 6 18.1667 6.89543 18.1667 8V11.5708C15.6764 12.4947 12.9816 12.9999 10.1668 12.9999C7.35189 12.9999 4.65711 12.4947 2.16675 11.5707V8C2.16675 6.89543 3.06218 6 4.16675 6H6.16675ZM8.16675 5C8.16675 4.44772 8.61446 4 9.16675 4H11.1667C11.719 4 12.1667 4.44772 12.1667 5V6H8.16675V5ZM9.16675 10C9.16675 9.44772 9.61446 9 10.1667 9H10.1767C10.729 9 11.1767 9.44772 11.1767 10C11.1767 10.5523 10.729 11 10.1767 11H10.1667C9.61446 11 9.16675 10.5523 9.16675 10Z" fill={color ? "url(#paint0_linear_205_9493)" : "#6A6A76"} />
            <path d="M2.16675 13.6923V16C2.16675 17.1046 3.06218 18 4.16675 18H16.1667C17.2713 18 18.1667 17.1046 18.1667 16V13.6923C15.654 14.5404 12.9632 14.9999 10.1668 14.9999C7.37038 14.9999 4.67954 14.5404 2.16675 13.6923Z" fill={color ? "url(#paint1_linear_205_9493)" : "#6A6A76"} />
            <defs>
                <linearGradient id="paint0_linear_205_9493" x1="18.1667" y1="2" x2="1.43948" y2="19.4545" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#09C3E7" />
                    <stop offset="1" stopColor="#7669F0" />
                </linearGradient>
                <linearGradient id="paint1_linear_205_9493" x1="18.1667" y1="2" x2="1.43948" y2="19.4545" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#09C3E7" />
                    <stop offset="1" stopColor="#7669F0" />
                </linearGradient>
            </defs>
        </svg>

    );
}