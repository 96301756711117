import React from "react";
import Divider from "../../assets/image/icons/divider_blue_rectangle.svg";
export default function HeroSection() {
  return (
    <div
      className="bg-primarybg-300 pt-18 md:pt-0 relative flex justify-center"
      id="aboutusherosection"
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] sm:flex flex-col justify-center items-center rounded-full hidden"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div
        className="absolute top-18 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full sm:hidden"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(50px)",
        }}
      />
      <div className="mt-40 mb-20 flex flex-col justify-center items-center">
        <p className="font-montserrat font-bold text-2xl sm:text-4xl leading-[36px] sm:leading-[54px] text-center text-white z-30 hidden sm:block">
          Unlock 50% Off Now!
          <br /> Subscribe to the Essential Plan Early Bird Offer
        </p>
        <p className="font-montserrat font-bold text-2xl sm:text-4xl leading-[36px] sm:leading-[54px] text-center text-white z-30 sm:hidden">
          Unlock 50% Off Now!
          <br /> Subscribe to the Essential Plan Early Bird Offer
        </p>
        <p className="font-montserrat font-medium text-sm lg:text-base xl:text-xl sm:leading-[36px] pt-4 text-center text-[#B1B5C4] z-30 hidden sm:block">
          These offerings collectively empower IFAs with essential tools and
          insights to effectively manage client
          <br />
          portfolios, streamline presentations, and elevate the overall client
          experience.
        </p>
        <p className="font-montserrat mt-4 font-medium text-sm leading-[36px] pt-2 text-center text-[#B1B5C4] z-30 sm:hidden px-4">
          These offerings collectively empower IFAs with essential tools and
          insights to effectively manage client
          <br />
          portfolios, streamline presentations, and elevate the overall client
          experience.
        </p>
        {/* <p className="font-montserrat font-medium text-xl leading-[30px] text-center text-[#B1B5C4] mt-6 z-30 hidden sm:block">
          We’re a team of thinkers from wealth and asset management industry
          <br />
          We have insider view of the challenges of financial advisors
          <br /> We want to make their life simple using technology
          <br /> Our aim is to empower financial advisors and enable product
          teams deliver their best
        </p> */}
        <img src={Divider} alt="err" className="mt-8 sm:hidden" />
        {/* <p className="font-montserrat font-medium leading-[21px] text-center text-[#B1B5C4] mt-6 z-30 sm:hidden px-5">
          We’re a team of thinkers from wealth and asset management industry.
          <br /> We have insider view of the challenges of financial advisors.
          <br /> We want to make their life simple using technology.
          <br /> Our aim is to empower financial advisors and enable product
          teams deliver their best.
        </p> */}
      </div>
    </div>
  );
}
