import React from "react";
// import InvestGPT from "../../assets/image/logo/investgpt_logo.svg";
import Holistic from "../../assets/image/icons/holistic.svg";
// import Arrow from "../../assets/image/icons/right_arrow.svg";
import Swift from "../../assets/image/icons/swift.svg";
import Personalized from "../../assets/image/icons/personalized.svg";
// import VisibilitySensor from "react-visibility-sensor";
export default function HeroSection2({ setBackground }) {
  return (
    <>
      <div className="hidden md:grid grid-cols-3 gap-10 mt-5 w-full">
        <div className="flex flex-col items-start border-[0.5px] border-[#09C3E7] bg-[#293150]/[0.2] rounded-[16px] px-12 pt-6 pb-10">
          <img src={Swift} alt="err" />
          <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
            {/* Swift */}
            Respond to Client Whatsapp Queries Immediately
          </p>
          {/* <p className="text-[#787E92] font-medium font-montserrat text-md pt-3 leading-[30px]">
            Query Resolution
          </p> */}
        </div>
        <div className="flex flex-col items-start bg-[#293150]/[0.2] border-[0.5px] border-[#09C3E7] rounded-[16px] px-12 pt-6 pb-10">
          <img src={Holistic} alt="err" />
          <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
            {/* Holistic */}
            Knowledge about Markets and Products on your fingertips
          </p>
          {/* <p className="text-[#787E92] font-medium font-montserrat text-md pt-3 leading-[30px]">
            Knowledge Integration
          </p> */}
        </div>
        <div className="flex flex-col items-start bg-[#293150]/[0.2] border-[0.5px] border-[#09C3E7] rounded-[16px] px-12 pt-6 pb-10">
          <img src={Personalized} alt="err" />
          <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
            Access insights on Client Portfolio in matter of seconds
          </p>
          {/* <p className="text-[#787E92] font-medium font-montserrat text-md pt-3 leading-[30px]">
            Advisory
          </p> */}
        </div>
      </div>

      <div className="md:hidden grid grid-cols-1 gap-8 mt-12 mb-20">
        <div className="flex items-center border-[0.5px] border-[#09C3E7] bg-[#293150]/[0.2] rounded-[16px] py-5 pl-7">
          <img src={Swift} alt="err" className="w-8 h-8 mr-4" />
          <div>
            <p className="font-semibold font-montserrat text-sm text-[#FCFCFD]">
              Respond to Client Whatsapp Queries Immediately
            </p>
            {/* <p className="text-[#FCFCFD] font-medium font-montserrat text-sm">
              Query Resolution
            </p> */}
          </div>
        </div>
        <div className="flex items-center border-[0.5px] border-[#09C3E7] bg-[#293150]/[0.2] rounded-[16px] py-5 pl-7">
          <img src={Holistic} alt="err" className="w-8 h-8 mr-4" />
          <div>
            <p className="font-semibold font-montserrat text-sm text-[#FCFCFD]">
              {/* Holistic */}
              Knowledge about Markets and Products on your fingertips
            </p>
            {/* <p className="text-[#FCFCFD] font-medium font-montserrat text-sm">
              Knowledge Distribution
            </p> */}
          </div>
        </div>
        <div className="flex items-center border-[0.5px] border-[#09C3E7] bg-[#293150]/[0.2] rounded-[16px] py-5 pl-7">
          <img src={Personalized} alt="err" className="w-8 h-8 mr-4" />
          <div>
            <p className="font-semibold font-montserrat text-sm text-[#FCFCFD]">
              Access insights on Client Portfolio in matter of seconds
            </p>
            {/* <p className="text-[#FCFCFD] font-medium font-montserrat text-sm">
              Responses
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
