import { styled } from "@mui/material";

// HELPER DATA
import { advantagesList } from "./data";

const AdvantageWrapper = styled("div")(({ theme }) => ({
  flex: "0 0 100%",
  [theme.breakpoints.up(theme.breakpoints.app.values.md)]: {
    flex: "0 0 50%",
  },
  [theme.breakpoints.up(theme.breakpoints.app.values.lg)]: {
    flex: "0 0 33%",
  },
}));

const Advantages = () => (
  <div
    className="px-[20px] md:px-[30px] xl:px-[80px] pt-12 pb-28 md:pb-32 text-white xl:max-w-[1480px] mx-auto"
    id="sticky-advantages-container"
  >
    {/* TITLE SECTION */}
    <h2 className="text-[24px] md:text-3xl font-montserrat font-semibold">
      Active Investment Oppurtunities
    </h2>

    {/* CONTENT SECTION */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 font-inter font-regular">
      {advantagesList.map(({ _id, name, description }, index) => (
        <AdvantageWrapper
          className="flex items-start space-x-4 mt-[30px]"
          key={["ADVANTAGES-CONTAINER", _id].join("-")}
        >
          <div className="text-xs font-montserrat font-semibold flex justify-center items-center rounded-full bg-[#383949] min-h-[20px] min-w-[20px] xl:min-h-[25px] xl:min-w-[25px]">
            {index + 1}
          </div>
          <div className="flex flex-col space-y-[15px] md:max-w-[80%] text-sm">
            <span className="md:h-10">{name}</span>
            {description.map(({ _id: pointId, point }) => (
              <span
                key={["ADVANTAGES-CONTAINER", pointId].join("-")}
                className="text-[#98A2B3] text-lg font-montserrat"
              >
                {point}
              </span>
            ))}
          </div>
        </AdvantageWrapper>
      ))}
    </div>
  </div>
);

export default Advantages;
