import React from "react";

export default function MarketsEconomy({ color }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5335 20.3999C13.5335 20.8158 13.7488 21.202 14.1026 21.4207C14.4564 21.6393 14.8981 21.6592 15.2701 21.4732L20.0701 19.0732C20.4767 18.8699 20.7335 18.4544 20.7335 17.9999V11.0832C20.7335 10.6673 20.5181 10.2811 20.1643 10.0624C19.8106 9.84376 19.3688 9.82388 18.9968 10.0099L14.1968 12.4099C13.7903 12.6131 13.5335 13.0287 13.5335 13.4832V20.3999Z"
        fill={color ? "url(#paint0_linear_205_8661)" : "#6A6A76"}
      />
      <path
        d="M18.5868 7.53157C18.9934 7.3283 19.2502 6.91279 19.2502 6.45826C19.2502 6.00374 18.9934 5.58822 18.5868 5.38495L12.8701 2.52659C12.5323 2.35767 12.1346 2.35767 11.7968 2.52659L6.0801 5.38495C5.67356 5.58822 5.41675 6.00374 5.41675 6.45826C5.41675 6.91279 5.67356 7.3283 6.0801 7.53157L11.7968 10.3899C12.1346 10.5589 12.5323 10.5589 12.8701 10.3899L18.5868 7.53157Z"
        fill={color ? "url(#paint1_linear_205_8661)" : "#6A6A76"}
      />
      <path
        d="M5.67013 10.0099C5.29814 9.82388 4.85637 9.84376 4.50259 10.0624C4.14881 10.2811 3.93347 10.6673 3.93347 11.0832V17.9999C3.93347 18.4544 4.19027 18.8699 4.59682 19.0732L9.39681 21.4732C9.7688 21.6592 10.2106 21.6393 10.5643 21.4207C10.9181 21.202 11.1335 20.8158 11.1335 20.3999V13.4832C11.1335 13.0287 10.8767 12.6131 10.4701 12.4099L5.67013 10.0099Z"
        fill={color ? "url(#paint2_linear_205_8661)" : "#6A6A76"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_205_8661"
          x1="20.7335"
          y1="2.3999"
          x2="0.725408"
          y2="20.6681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09C3E7" />
          <stop offset="1" stopColor="#7669F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_205_8661"
          x1="20.7335"
          y1="2.3999"
          x2="0.725408"
          y2="20.6681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09C3E7" />
          <stop offset="1" stopColor="#7669F0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_205_8661"
          x1="20.7335"
          y1="2.3999"
          x2="0.725408"
          y2="20.6681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09C3E7" />
          <stop offset="1" stopColor="#7669F0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
