import React from "react";
// import Twitter from "../../assets/image/icons/member_twitter.svg";
// import Linkedin from "../../assets/image/icons/member_linkedin.svg";
export default function Card({ member, ind }) {
  var gradient = "";
  switch (ind % 3) {
    case 0:
      gradient =
        "radial-gradient(39.19% 47.78% at 50% 0%, rgba(255, 166, 112, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)";
      break;
    case 1:
      gradient =
        "radial-gradient(39.19% 47.78% at 50% 0%, rgba(217, 79, 118, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)";
      break;
    case 2:
      gradient =
        "radial-gradient(39.19% 47.78% at 50% 0%, rgba(23, 184, 232, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)";
      break;
    default:
      break;
  }
  return (
    <a href={member.social_media_two} target="_blank" rel="noreferrer">
      <div
        className="w-full pt-[82px] pb-6 mt-[98px] relative min-h-[106px]"
        style={{ background: gradient }}
      >
        <div className="absolute top-[-58px] left-0 right-0 flex justify-center">
          <img
            src={member.image}
            alt="err"
            className="object-contain h-[120px] w-[120px] rounded-full border-[2px] border-[#FCFCFD]"
          />
        </div>

        <p className="font-montserrat font-semibold text-[#FCFCFD] text-center leading-[24px]">
          {member.name}
        </p>

        <p className="font-montserrat font-normal text-xs text-[#B1B5C4] text-center leading-[18px]">
          {member.position}
        </p>
        {/* <div className="w-full flex justify-center mt-6">
        <img src={Linkedin} alt="err" />
        <img src={Twitter} alt="err" className="ml-4" />
      </div> */}
      </div>
    </a>
  );
}
