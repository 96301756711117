import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import HomePage from "./pages/home/index";
import ContactUsPage from "./pages/contactus/index";
import InvestGPT from "./pages/investgpt/index";
import InvestEdge from "./pages/investedge/index";
import InvestLane from "./pages/investlane/index";
import TermsofPolicy from "./pages/terms/index";
import PrivacyPage from "./pages/privacy";
import BlogPage from "./pages/blogs";
import ScrollToTop from "./ScrollToTop";
import MediaKitPdf from "./assets/files/mediakit.pdf";
import AboutPage from "./pages/about/index";
import Pricing from "./pages/pricing/index";
import ResourcePage from "./pages/resources";
import ClientRetention from "./pages/clientRetention/index";

// const Investedge = () => {
//   window.location.replace(process.env.REACT_APP_investedge);
//   return null;
// };
const InvestedgeLogin = () => {
  window.location.replace(process.env.REACT_APP_investedge + "login");
  return null;
};
// const Investlane = () => {
//   window.location.replace(process.env.REACT_APP_investlane);
//   return null;
// };
const InvestlaneLogin = () => {
  window.location.replace(process.env.REACT_APP_investlane + "auth/new-login");
  return null;
};
const Careers = () => {
  window.open(
    "https://peppermint-prose-e38.notion.site/BeyondIRR-is-Hiring-63983dd1646045949aeff63bee684422",
    "_blank"
  );
  window.history.back();
  return null;
};
const MediaKit = () => {
  window.open(MediaKitPdf, "_blank");
  window.history.back();
  return null;
};
const Router = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route exact path="/home" element={<HomePage />} />
      <Route exact path="/about" element={<AboutPage />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/client-engagement" element={<InvestGPT />} />

      <Route exact path="/client-aquisition" element={<InvestEdge />} />
      <Route exact path="/client-retention" element={<ClientRetention />} />

      <Route exact path="/investment-opportunities" element={<InvestLane />} />

      <Route exact path="/resources" element={<ResourcePage />} />

      <Route exact path="/investlane" element={<InvestLane />} />
      <Route exact path="/terms" element={<TermsofPolicy />} />
      <Route exact path="/privacy" element={<PrivacyPage />} />
      <Route exact path="/careers" element={<Careers />} />
      <Route path="/mediakit" element={<MediaKit />} />
      <Route path="/investlane/login" element={<InvestlaneLogin />} />
      <Route path="/investedge/login" element={<InvestedgeLogin />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/contactus" element={<ContactUsPage />} />
      <Route exact path="/" element={<Navigate to="/home" />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
Router.displayName = "Router Page";
