import React from "react";
import LinksSection from "./LinksSection";
// import Subscribe from "./Subscribe";
export default function Footer() {
  return (
    <>
      {/* <Subscribe /> */}
      <LinksSection />
    </>
  );
}
