import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Arrow from "../../assets/image/icons/right_arrow.svg";
import RequestModal from "../modal/RequestModal";
import Balance from "../../assets/image/logo/Balance.png";
import Monitor from "../../assets/image/icons/client-retention-monitor.svg";
import Review from "../../assets/image/icons/client-retention-review.svg";

export default function HeroSection({ setBackground }) {
  const [open, setOpen] = useState(false);
  const features_data = [
    {
      src: Monitor,
      title: "Monitor",
    },
    {
      src: Review,
      title: "Review",
    },
    {
      src: Balance,
      title: "Rebalance",
    },
  ];
  return (
    <div
      id="investedge-homescreen-herosection"
      className="bg-primarybg-300 relative p-4 pt-18 md:pt-0 flex justify-center min-h-[650px]"
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div className="h-[450px] sm:h-[660px] sm:flex sm:flex-col sm:items-center z-30 pt-[44px] sm:pt-44">
        <VisibilitySensor
          onChange={(isVisible) =>
            isVisible
              ? setBackground("transparent")
              : setBackground("rgba(12, 4, 24, 0.85)")
          }
        ></VisibilitySensor>

        <p className="font-montserrat text-3xl font-semibold sm:text-5xl leading-[54px] sm:leading-[60px] sm:text-center text-white">
          {/* Monitor, review and rebalance client portfolio to ensure that the
          portfolio is designed to meet
          <br /> client expectations. */}
          Never Miss a Chance to do Portfolio Reviews.
        </p>

        <div className="my-14 grid grid-cols-1 md:grid-cols-3 gap-6 w-full md:w-[90%] mx-auto">
          {features_data.map((feature, index) => (
            <div
              className="w-full flex items-center justify-center space-x-4 border-[0.5px] border-[#09C3E7] bg-[#293150]/[0.2] rounded-[16px] p-6 z-[59]"
              key={index}
            >
              <img src={feature?.src} alt="err" />
              <p className="font-semibold font-montserrat text-base leading-[30px] text-[#FCFCFD]">
                {feature.title}
              </p>
            </div>
          ))}
        </div>

        <button
          className="border border-white flex items-center py-4 px-6 mt-10 rounded-[90px] cursor-pointer mx-auto md:mx-0"
          onClick={() => setOpen(true)}
        >
          <p className="font-dmsans text-[#FCFCFD] font-bold">
            Connect With Us
          </p>
          <img src={Arrow} alt="err" className="ml-4" />
        </button>
        <RequestModal open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}
