import React from "react";

// import Arrow from "../../assets/image/icons/right_arrow.svg";
// import VisibilitySensor from "react-visibility-sensor";
export default function HeroSection({ setBackground }) {
  return (
    <div
      id="investedge-homescreen-herosection"
      className="bg-primarybg-300 relative pt-18 md:pt-0 flex justify-center min-h-[400px]"
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div className="h-[450px] sm:min-h-[400px] sm:flex sm:flex-col sm:items-center z-30 pt-[44px] sm:pt-44 p-4">
        {/* <VisibilitySensor
          onChange={(isVisible) =>
            isVisible
              ? setBackground("transparent")
              : setBackground("rgba(12, 4, 24, 0.85)")
          }
        ></VisibilitySensor> */}

        <p className="font-montserrat text-3xl font-semibold sm:text-5xl leading-[54px] md:leading-[60px] sm:text-center text-white">
          Insights Hub: Empowering Wealth Businesses <br /> for Growth
        </p>
        <p className="font-montserrat font-medium text-sm sm:text-xl leading-[21px] sm:leading-[30px] sm:text-center text-[#B1B5C4] mt-6">
          Explore market insights, wealth business tips, and expert advice
          curated by our seasoned team with over 30 years of expertise.
          <br /> Join our community for collaborative growth.
        </p>
      </div>
    </div>
  );
}
