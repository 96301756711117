import React, { useEffect, useState } from "react";
import Arrow from "../../assets/image/icons/load_down_arrow.svg";
import InsightsCard from "./InsightsCard";
import axios from "axios";
export default function Insights() {
  const [blogs, setBlogs] = useState([]);
  const [blogsNumber, setblogsNumber] = useState(1);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_blogURL)
      .then(function (response) {
        setBlogs(response.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  // const data = [
  //   {
  //     blogimage: Blog1,
  //     domain: "Economy",
  //     subDomain: "The Concept of GDP: An Introduction ",
  //     description:
  //       "The term GDP is a well-known term, still a bit confusing for many. Read here to understand it better. ",
  //     author: Avatar1,
  //     authorName: "Vijay Vyas",
  //     publishedDate: "22 Jul 2022",
  //   },
  //   {
  //     blogimage: Blog2,
  //     domain: "Investment",
  //     subDomain:
  //       "Convinced about Dynamic Asset Allocation Funds? But wait…. There’s More",
  //     description:
  //       "Dynamic Asset Allocation is one of the most popular categories in mutual fund space and surely there are reasons for that. Check this out for better understanding! ",
  //     author: Avatar2,
  //     authorName: "Vijay Vyas",
  //     publishedDate: "22 Jul 2022",
  //   },
  //   {
  //     blogimage: Blog3,
  //     domain: "Investment",
  //     subDomain: "Investment expectations must be set right!",
  //     description:
  //       "While asset allocation is a very broad topic, here the focus is on setting the right expectations from equity investment in different market caps. Check it out! ",
  //     author: Avatar3,
  //     authorName: "Lana Steiner",
  //     publishedDate: "18 Jan 2022",
  //   },
  // ];
  return (
    <div
      className="bg-primarybg-300 w-full py-5 md:py-16 px-6 md:px-28 z-[59]"
      id="insights"
    >
      <div className="hidden sm:grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        {blogs.map((item, index) => (
          <InsightsCard item={item} key={index} />
        ))}
      </div>
      <div className="sm:hidden grid grid-cols-1 gap-8">
        {blogs.slice(0, blogsNumber).map((item, index) => (
          <InsightsCard item={item} key={index} />
        ))}
      </div>
      <div
        className={
          blogsNumber === blogs.length
            ? "hidden"
            : "flex sm:hidden bg-primaryblue-200 py-3 justify-center mt-12 rounded-lg"
        }
        onClick={() => setblogsNumber(blogs.length)}
      >
        <img src={Arrow} alt="err" />
        <p className="font-inter font-medium text-[#FCFCFD] ml-2">Load more</p>
      </div>
    </div>
  );
}
